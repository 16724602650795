/* Read More component (default)
********************************/

.readmore {
    position: relative;
    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);
}

.readmore-image {
    @extend %image-gradient-overlay;
    position: absolute;
    inset: 0;
    overflow: hidden;

    &::after {
        background-color: rgba($fssa-textcolor--blue, 0.8);

        @media screen and (min-width: $viewport-wide) {
            background-color: transparent;
            background-image: linear-gradient(90deg, $fssa-textcolor--blue 48%, rgba($fssa-textcolor--blue, 0) 90%);
        }
    }

    img {
        @extend %image-objectfit;
        object-position: top right;
    }
}

.readmore-text {
    position: relative;
    padding: 1.5em;

    @media screen and (min-width: $viewport-narrow) {
        padding: 2em;
    }

    @media screen and (min-width: $viewport-wide) {
        max-width: 50%;
    }

    h3 {
        @extend %heading--tertiary;
    }

    .readmore-cta {
        margin-bottom: 0;
    }
}

.readmore-preview {
    position: relative;

    .js &.fadeout {
        &::after {
            @media screen and (min-width: $viewport-wide) {
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                height: 6.25em;
                background-image: linear-gradient(rgba($fssa-textcolor--blue, 0), $fssa-textcolor--blue);
                content: "";
            }
        }
    }
}
