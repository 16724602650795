/* Insight Teaser component (default)
*************************************/
// @note: Insight Teaser gets most of its styling from the shared
// teaser style class `.promo`, `.promo-ITEM_NAME` rules

// .insight {}
// .insight-type {}

.insight-image {
    img {
        transition: transform 0.4s ease-in-out;

        .insight:hover &,
        .insight:focus-within & {
            transform: scale(1.08);
        }
    }
}

.insight-text {
    padding: 1.5em;

    @media screen and (min-width: $viewport-wide) {
        padding-left: 2em;
        padding-right: 2em;
    }
}

// .insight-heading {}
// .insight-link {}
// .insight-description {}

.insight-details {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 0 -0.5em;
        padding: 0;
        list-style: none;
        font-size: 0.8rem;
    }

    li {
        display: flex;
        align-items: center;
        margin: 0.25em 0.5em;
    }

    svg {
        position: relative;
        top: -0.0625rem;
        width: 1.1rem;
        margin-right: 0.25em;
    }
}
