/* Text component (Columns variation)
*************************************/

.text.column-item {
    *:first-child {
        margin-top: 0;
    }

    *:last-child {
        margin-bottom: 0;
    }
}

.text.column-item + .text.column-item {
    margin-top: -0.5em;
    // ^^ Counter the grid gap value for mobile/tablet viewports where items
    //    are vertically stacked

    @media screen and (min-width: $viewport-wide) {
        margin-top: inherit;
    }
}
