/* Cookie Notification component (default)
******************************************/

.cookies {
    width: 100%;
    grid-column: -1 / 1;
}

.cookies-inset {
    display: flex;
    flex-direction: column;

    margin-bottom: 1em;
    padding: 1em 1.5em;
    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);

    @media screen and (min-width: $navigation-switch) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
    }
}

.cookie-disclaimer {
    .text {
        font-size: 0.9rem;
    }
}

.cookie-actions {
    flex-shrink: 0;
}
