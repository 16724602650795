/* Footer/copyright component (default)
***************************************/

.footer-copyright {
    display: flex;
    flex-flow: column wrap;
    margin-top: em(12px, 16px);

    font-size: 0.8rem;
    font-weight: 500;

    @media screen and (min-width: $viewport-wide) {
        flex-direction: row;
        padding-top: 1em;
        border-top: solid em(1px, 16px) rgba($fssa-textcolor--footer, 0.2);
    }

    p,
    li {
        margin: 0.5em 0;
    }

    .copyright {
        width: 100%;

        @media screen and (min-width: $viewport-wide) {
            width: auto;
            margin-right: 2em;
        }
    }
}

.footer-copyright-list.list {
    display: flex;
    flex-flow: column wrap;
    margin: 0;
    font-size: inherit;

    @media screen and (min-width: $viewport-medium) {
        flex-direction: row;
    }

    li + li {
        @media screen and (min-width: $viewport-medium) {
            margin-left: 1em;
            padding-left: 1em;
            border-left: solid em(1px, 16px) rgba($fssa-textcolor--footer, 0.2);
        }
    }
}
