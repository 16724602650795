/* Strategy Page P&P component (default)
***************************************/

.custom-select {
    height: 45px !important;
    color: #59667c !important;
    font-weight: 500 !important;
}

.performance-documents {
    &__overview {
        background-color: #f2f5f7 !important;
    }
}

.dynamic-table {
    table {
        border-right: 1px solid #ddd !important;
    }
    th,
    td {
        padding: 6px;
        vertical-align: top;
    }
    &__wrapper-inner {
        @media screen and (max-width: '1410px') {
            overflow-x: scroll !important;
            word-wrap: nowrap !important;
        }
    }
}

.bse-text {
    &__single-fund {
        -webkit-appearance: none;
        -moz-appearance: none;
        animation: none;
        background-color: #fff;
        color: #525051;
        text-transform: uppercase;
        font-size: .8rem;
        padding: 10px 5px 10px 10px;
        width: 73%;
        max-width: 80%;
        cursor: initial;
        text-align: center;
        border-style: groove;
        word-wrap: break-word;
        white-space: nowrap;
    }
}

.bse-tab {
    &__button[aria-pressed="true"]  {
        background: #f2f5f7 !important;
    }

    &__body {
        background: #f2f5f7 !important;
    }
}
