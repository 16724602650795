/* Card component (Icon/Text variation)
****************************************/

.card--icontext {
    // Horizontal layout (default)
    .card-inset {
        flex-direction: column;

        @media screen and (min-width: $viewport-narrow) {
            flex-direction: row;
        }
    }

    // Vertical layout overrides
    .card-inset.card--vertical {
        @media screen and (min-width: $viewport-narrow) {
            flex-direction: column;
        }
    }

    .card--fadein {
        animation: fadeIn 1s ease forwards;

        // Only implement animation delay when on desktop/horizontal layout
        @media screen and (min-width: $viewport-wide) {
            animation-delay: var(--animation_delay, 0);
        }
    }

    .card-image {
        position: relative;
        display: flex;

        width: 3.75em;
        height: 3.75em;

        background-color: $white;
        border-radius: 50%;
        box-shadow: 5px 5px 20px 0 rgba(71, 89, 102, 0.15);

        @media screen and (min-width: $viewport-narrow) {
            width: 4.375em;
            height: 4.375em;
        }

        > img {
            width: 1.5em;
            height: 1.5em;
            margin: auto;
        }
    }

    // Horizontal layout (default) padding
    .card-text {
        padding: 1em 0 0;

        @media screen and (min-width: $viewport-narrow) {
            padding: 0 0 0 1.5em;
        }
    }

    // Vertical layout overrides
    .card--vertical .card-text {
        @media screen and (min-width: $viewport-narrow) {
            padding: 1em 0 0;
        }
    }

    .heading--cardicon {
        @extend %heading--tertiary;

        .tmpl-home & {
            @extend %heading--secondary;
        }
    }

    .card-description {
        > *:last-child {
            margin-bottom: 0;
        }
    }
}
