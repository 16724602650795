/* Hero component (Home Page Template)
**************************************/

.hero--home {
    .hero-inset {
        @media screen and (min-width: $viewport-medium) {
            min-height: 43.73em;
        }

        @media screen and (min-width: $viewport-wide) {
            min-height: 80vh; // @important: Turn this rule off in author `editmode.css`
        }
    }

    .hero-title {
        opacity: 0;
        // ** Starting opacity 0;
        // * if `prefers-reduced-motion` true, animation skips directly
        // * to animation end & opacity becomes 1
        animation: fadeIn 1s ease-out forwards, slideInDown 1.15s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
        animation-delay: 1.5s;
    }

    .hero-description {
        opacity: 0; // ** (cf. .hero-title comments ^^)
        animation: fadeIn 0.75s ease-out forwards;
        animation-delay: 2.5s;
    }

    .hero-cta {
        opacity: 0; // ** (cf. .hero-title comments ^^)
        animation: fadeIn 1s ease-out forwards, slideInLeft 0.5s ease-out forwards;
        animation-delay: 2.65s;
    }
}
