/* Insight Teaser component (hub variation)
*******************************************/

.insight-type.hub {
    .insight-description {
        @media screen and (min-width: $viewport-wide) {
            display: block;
        }
    }

    .insight-details {
        padding-top: 1.5em;
        color: $fssa-bgcolor;
    }
}
