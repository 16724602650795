/* Table component (Data variant)
*********************************/

.table--data {
    font-size: 0.9rem;

    .row-heading {
        padding: 0.75rem 0;
        border: 0;
        border-bottom: solid 0.125rem $fssa-red;
    }

    .row-group-data > * {
        border-bottom: solid 0.0625rem $fssa-textcolor--footer;
    }

    td,
    th[scope="col"]:not(:first-child) {
        text-align: center;
    }

    td,
    th {
        border: 0;
        border-bottom: solid 0.0625rem $fssa-textcolor--footer;
    }

    th[scope="col"] {
        font-size: 0.75rem;
        font-weight: 600;
        border-bottom-color: $fssa-red;
        border-width: 0.125rem;
        vertical-align: bottom;
    }

    th,
    td,
    .row-group-data > * {
        padding: 0.75rem 0.375rem;
    }

    th[scope="row"],
    th.group-name,
    thead th:first-child,
    .row-group-data dt {
        padding-left: 0;
    }

    th.group-name {
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    td:last-child,
    thead th:last-child,
    .row-group-data dd {
        padding-right: 0;
    }
}

// Additional styling for components:
// 1). Dividends
// 2.) Documents & Factsheets
// 3). Price & Performance
.data-form {
    caption {
        .heading {
            font-size: 1rem;
            text-transform: uppercase;
        }
    }

    .table--data + .table--data {
        margin-top: 1.5em;
    }
}
