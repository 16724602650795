/* Card component (Text/Image variation)
****************************************/

.card--textimage {
    .card-image {
        @extend %card-stacked-image-heights;
    }

    .card--horizontal {
        .card-image.image--after {
            order: 1;
        }

        .card-text {
            margin: auto;
        }

        @media screen and (min-width: $viewport-wide) {
            .card-image {
                width: 30%; // Doubles-up as equivalent min-height too!
                height: auto;

                &.image--fiftypc {
                    width: 50%;
                }
            }
        }
    }
}
