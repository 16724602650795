/* Modal Mask component (default)
*********************************/

.modal-mask:not([hidden]) {
    position: fixed;
    inset: 0;

    display: flex;
    flex-direction: column;

    background-color: rgba($fssa-blue, 0.8);
    opacity: 0;
    z-index: 2999999998;

    [data-modal-open] & {
        opacity: 1;
    }
}

// Blur the page content behind the modal mask
// Combine with setting body to `overflow: hidden` to prevent scrolling of page content
.page-content-container[aria-hidden] {
    position: fixed; // Prevents iOS from continuing to allow scroll (in `background` page)
    inset: 0;
    filter: blur(0.2em);
    opacity: 0.6;
    overflow: hidden;
}

body[data-modal-open] {
    height: 100vh;
}
