/* Title component (sub-navigation variation)
*********************************************/

.subnav {
    .title {
        .heading--primary {
            font-size: 1.6rem;

            @media screen and (min-width: $viewport-narrow) {
                font-size: 1.9rem;
            }
        }

        .heading--secondary {
            @extend %heading--tertiary;
            color: rgba($fssa-textcolor, 0.7);
        }
    }

    .title + .title {
        @media screen and (min-width: $navigation-switch) {
            margin-top: -0.25em;
        }
    }

    .group.column-item .title:first-child {
        h3.heading--secondary {
            @media screen and (min-width: $navigation-switch) {
                margin-top: 0.25em;
            }
        }
    }
}
