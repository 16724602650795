/* Modal Overlay (default)
**************************/

.modal {
    position: relative;
    margin: auto;

    max-width: $viewport-wider;
    width: 98%;
    height: 98%;

    color: inherit;
    background-color: $white;
    border: solid em(1px, 16px) $fssa-blue;

    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.1s ease, transform 0.2s ease;

    @media screen and (min-width: $viewport-medium) {
        width: 90%;
        height: 90%;
    }

    @media screen and (min-width: $viewport-wide) {
        width: 80%;
        height: 80%;
    }

    .modal-close {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 1;

        display: flex;
        align-items: center;

        @media screen and (min-width: $viewport-medium) {
            top: 2em;
            right: 2em;
        }

        // 'hidden' attribute/property does not work with flex-box (display: flex;)
        &[hidden] {
            display: none;
        }
    }

    .modal-content {
        display: grid;
        grid-template-rows: auto 1fr auto;

        /* prettier-ignore */
        grid-template-areas:
            "modal-header"
            "modal-body"
            "modal-footer";

        height: 100%;
    }

    .modal-header {
        grid-area: modal-header;
        margin: 1em;
        border-bottom: solid 0.0625em $fssa-form-bordercolor;

        @media screen and (min-width: $viewport-medium) {
            margin: 1.5em 2em 0;
        }

        > * {
            @media screen and (min-width: $viewport-wide) {
                padding-top: 0.5em;
            }
        }
    }

    .modal-body {
        position: relative; // Required for iOS scrolling
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        grid-area: modal-body;
    }

    .modal-footer {
        grid-area: modal-footer;
        margin: 1em;
        border-top: solid 0.0625em $fssa-form-bordercolor;

        @media screen and (min-width: $viewport-medium) {
            margin: 0 2em 1em;
        }
    }

    .loading-animation {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;

        > .loader {
            width: 6rem;
            height: 6rem;
            margin: auto;
            border: solid 0.6rem $fssa-bgcolor;
            border-top-color: $fssa-airforce;
            border-radius: 50%;
            animation: spinner 2.5s linear infinite;
        }
    }

    iframe {
        width: 100%;
        max-width: none;
        min-height: 100%;
        opacity: 0;
        overflow-y: hidden;
        transition: opacity 0.1s ease;

        &.loaded {
            opacity: 1;
        }
    }
}

[data-modal-open] {
    .modal {
        opacity: 1;
        transform: scale(1);
    }
}
