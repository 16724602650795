/* List Icon component (default)
********************************/

.list-icon {
    padding: 0;
    list-style: none;

    li.icon-item {
        display: flex;
        margin: 0.5em 0;
    }

    .icon {
        margin-right: 0.5em;
        fill: $fssa-red;

        &.icon--small,
        &.icon--regular {
            margin-top: 0.1875em;
        }
    }
}
