/* Panel component (fade variation)
***********************************/

.fade {
    > .panel-container .panel {
        box-shadow: none;

        @media screen and (min-width: $viewport-wide) {
            padding: 3em;
        }
    }
}
