/* Accordion API (default)
**************************/

// GLOBAL accordion functionality styles
// Available to all accordion API uses (e.g. mobile navigation),
// not just the accordion component

button[data-accordion-trigger] {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    svg {
        width: 1em;
        height: 1em;
        order: 1;

        &.icon {
            width: 1.75rem;
            height: 1.75rem;
            transform-box: fill-box;
            transform-origin: center;
            transition: transform 0.4s ease;
        }
    }

    &[aria-expanded="false"] .icon--chevron {
        transform: rotate(90deg);
    }

    &[aria-expanded="true"] .icon--chevron {
        transform: rotate(270deg);
    }
}

[data-accordion-target] {
    @extend %slidingPanel;
    transition: all 0.3s ease-in-out;
}
