/* Modal Overlay (Case Study variation)
***************************************/

.modal.modal--casestudy {
    max-width: none;
    width: 100%;
    height: 100%;

    .modal-content,
    .modal-body {
        height: 100%;
    }

    .modal-content {
        background-color: $fssa-blue;
    }
}
