/* Form multi-select partial styles (default)
*********************************************/

.multi-selector, .multi-selector-date-filter {
    display: grid;
    grid-template-rows: auto;
    gap: 0.75em;

    @media screen and (min-width: $viewport-medium) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5em;
    }

    legend {
        width: 100%;
        grid-column: -1 / 1;
    }

    p {
        margin: 0;
    }

    .styled-select {
        display: block;
    }
}

.panel.panel--multiselector {
    @media screen and (min-width: $viewport-narrow) {
        padding: 2em;
    }

    &.panel--marginbottom {
        margin-bottom: 2em;

        @media screen and (min-width: $viewport-wide) {
            margin-bottom: 3em;
        }
    }
}

.date-filters {
    display: grid;
    margin-bottom: 1em;

    .multi-selector, .multi-selector-date-filter {
        gap: 0.75em;
        margin: 0.75em 0;

        .legend {
            grid-column: auto;
            align-self: center;
            margin-bottom: 0;
            float: left;
        }
    }

    p.cta {
        @media screen and (min-width: $viewport-wide) {
            align-self: end;
            margin-left: 0;
            margin-bottom: 0.375em;
        }
    }
}
