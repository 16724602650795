/* Country selector component (default)
***************************************/

.country-selector {
    position: relative;
    padding: 2em;

    li {
        margin: 0.3125em 0;
    }

    span.country-name {
        font-weight: 400;
    }

    .flag {
        display: inline-block;
        vertical-align: baseline;
        height: 1em;
    }

    .continents {
        display: grid;
        gap: 1.5em;
        grid-template-columns: 1fr;
        /* prettier-ignore-start */
        grid-template-areas:
            "oceania"
            "asia"
            "europe"
            "northamerica";
        /* prettier-ignore-end */

        @media screen and (min-width: $viewport-narrow) {
            grid-template-columns: repeat(2, minmax(10.25em, 1fr));
            /* prettier-ignore-start */
            grid-template-areas:
                "oceania asia"
                "europe europe"
                "northamerica ...";
            /* prettier-ignore-end */
        }

        @media screen and (min-width: $viewport-wide) {
            grid-template-columns: repeat(2, 1fr) minmax(max-content, 50%);
            /* prettier-ignore-start */
            grid-template-areas:
                "oceania        asia europe"
                "northamerica   asia europe";
            /* prettier-ignore-end */
        }
    }

    .continent-as {
        grid-area: asia;
    }

    .continent--eu {
        grid-area: europe;

        .countries {
            columns: 10em;
            column-gap: 1.5em;

            @media screen and (min-width: $viewport-wide) {
                column-gap: 0;
            }

            & > li:first-child {
                margin-top: 0;
            }
        }
    }

    .continent-na {
        grid-area: northamerica;
    }

    .continent-oc {
        grid-area: oceania;
    }

    .country {
        .flag {
            margin-right: 0.375em;
        }
    }

    .investor-types {
        padding-left: 3em;
    }
}

.country-selector--header {
    margin-bottom: 1.5em;
    padding-bottom: 1em;
    border-bottom: solid 0.0625em $fssa-form-bordercolor;

    h2.heading--primary {
        position: relative;
    }

    button {
        flex-shrink: 0;
    }

    .select-location {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: $viewport-medium) {
            margin-top: -0.5em;
            justify-content: flex-start;
        }
    }

    .current-location {
        margin: 0 0.25em;
    }

    .heading--sub,
    .current-location {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .flag {
        margin-right: 0.25em;
    }
}
