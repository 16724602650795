/* Text component (default)
***************************/

.text {
    a {
        @extend %link-plain--red;
    }

    // New tab icon
    // Add 'new tab' icon to external links
    a[href^="http://"],
    a[href^="https://"],
    a[target="_blank"] {
        &::after {
            @extend %icon--new-tab;
            content: "";
        }
    }

    a.download {
        display: flex;
        align-items: center;

        &::before {
            @extend %icon--download;
            margin-right: 0.25rem;
            flex-shrink: 0;
            content: "";
        }
    }

    h1,
    h2 {
        @extend %heading;
        @extend %heading--primary;
    }

    h3 {
        @extend %heading;
        @extend %heading--secondary;
    }

    h4 {
        @extend %heading;
        @extend %heading--tertiary;
    }

    blockquote {
        max-width: 75ch;
        margin-left: 0;
        margin-right: 0;
        padding: 1em 0 1.5em;
        color: inherit;
        font-size: 1.3rem; // 20.8px
        text-align: center;

        border-top: solid em(1px, 20.8px) $fssa-bgcolor;
        border-bottom: solid em(1px, 20.8px) $fssa-bgcolor;

        @media screen and (min-width: $viewport-medium) {
            padding-left: 2.3em;
            padding-right: 2.3em;
        }

        &::before {
            @extend %icon--quote;
            margin-bottom: 0.5em;
            content: "";
        }
    }

    hr {
        margin: 2.5em 0;
        color: $fssa-bgcolor;
    }

    // Improve alignment when p-tag is the first item inside a text component
    p:first-child {
        margin-top: 0;
    }

    sub,
    sup {
        font-style: italic;
    }

    // Generic list indentation
    ul,
    ol {
        padding-left: 1em;
    }

    // Specific direct list indentation
    > ol,
    > ul {
        @media screen and (min-width: $viewport-wide) {
            padding-left: 2.5em;
        }
    }

    li {
        margin: 1em 0;
    }

    // RTE style
    .paragraph--large {
        @extend %text--lead;
    }

    .paragraph--small {
        font-size: 0.8rem;
    }
}
