/* Infogram component (Columns variation)
*****************************************/
// For when two Infogram charts sit next to each other in a column component,
// help them align their heights/baselines.
// E.g. as per one case study in the supplied designs.

.infogram-container.column-item {
    display: flex;

    .infogram {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
}
