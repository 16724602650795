/* Card component (Tags variation)
**********************************/

.card--tags {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin: 0;
        padding: 0;
        list-style: none;
    }

    .tag {
        display: block;
        margin: 0.25em;
        padding: 0.5em 1em;

        font-size: 0.8rem;
        background-color: $white;
        border: solid 0.0625rem $fssa-form-bordercolor;
    }

    .card-text {
        justify-content: center;
        text-align: center;
    }
}
