/* Group component (Columns variation)
**************************************/

.columns {
    // Replicate horizontal (left/right) Panel padding rules on Group component when
    // Columns set to negate Panel padding.
    &.padding--counter-horizontal {
        .group {
            padding-left: 1.5em;
            padding-right: 1.5em;

            @media screen and (min-width: $viewport-narrow) {
                padding-left: 2em;
                padding-right: 2em;
            }

            @media screen and (min-width: $viewport-wide) {
                padding-left: 3em;
                padding-right: 3em;
            }
        }
    }

    // Replicate vertical (top/bottom) Panel padding rules on Group component when
    // Columns set to negate Panel padding.
    &.padding--counter-vertical {
        .group {
            padding-top: 2em;
            // @note: Omitting padding-bottom at this level; allowing
            // column gap to control vertical flow.

            @media screen and (min-width: $viewport-wide) {
                padding-top: 3em;
                padding-bottom: 3em;
            }
        }
    }
}
