/* Sitemap component (default)
******************************/

.sitemap-group {
    margin: 0;
    padding: 0 1.5em;

    // Top-level
    &:first-child {
        display: grid;
        gap: 1.5em;
        margin: 0;

        @media screen and (min-width: $viewport-medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (min-width: $viewport-wider) {
            grid-template-columns: repeat(3, 1fr);
        }

        .sitemap-level--0 {
            font-size: 1.3rem;

            @media screen and (min-width: $viewport-narrow) {
                font-size: 1.5rem;
            }
        }

        .sitemap-level--1 {
            font-size: initial;
        }
    }
}
