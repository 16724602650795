/* Card component (default)
***************************/
// @note:
// Styling is common to all Card variations, e.g.
// -- `.card--contact`
// -- `.card--icontext`
// -- `.card--profile`
// -- `.card-textimage`

.card {
    display: flex;
    flex-direction: column;

    a {
        @extend %link-plain--red;
    }
}

.card-inset {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);

    // Horizontal layout @ desktop viewports
    &.card--horizontal {
        @media screen and (min-width: $viewport-wide) {
            flex-direction: row;
        }
    }

    // Vertical layout @ desktop viewports
    // &.card--vertical {}
}

.card-image {
    flex-shrink: 0;
    overflow: hidden;

    img {
        @extend %image-objectfit;
    }
}

.card-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5em;

    @media screen and (min-width: $viewport-narrow) {
        padding: 2em;
    }

    .panel.panel--zero & {
        @media screen and (min-width: $viewport-wide) {
            padding: 3em;
        }
    }
}

.card-cta {
    display: flex;
    margin-top: auto;
    margin-bottom: 0;
}
