/* Title component (Case Study Template)
****************************************/
// Webqem created a number of title styles unique to case study.
// Not sure if this is deliberate or a mistake?

.tmpl-casestudy {
    // First title, sibling to logo
    .header-logo + .title {
        .heading--primary {
            @media screen and (min-width: $viewport-medium) {
                font-size: 3rem;
            }

            @media screen and (min-width: $viewport-wide) {
                font-size: 3.4rem;
            }
        }
    }

    // Headings inside border components
    .border-highlight {
        .heading--primary,
        .text h1,
        .text h2 {
            font-size: 1.9rem; // Keep consistent with secondary (@ largest size)

            @media screen and (min-width: $viewport-narrow) {
                font-size: 2rem;
            }
        }
    }
}
