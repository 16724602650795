/* CSS animation styles
***********************/

%slidingPanel,
.sliding-panel {
    max-height: var(--expand_max, none);
    transition: max-height 0.3s ease;
    overflow: hidden;

    &.closed {
        max-height: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes unfoldHorizontal {
    0% {
        opacity: 0;
        transform: rotateY(90deg);
    }
    100% {
        opacity: 1;
        transform: rotateY(0);
    }
}

@keyframes slideInDown {
    0% {
        transform: translateY(-3.125em);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-3.125em);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(3.125em);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
