/* Social Share component (default)
***********************************/

.socialshare {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li + li {
        border-top: dashed em(1px, 16px) $fssa-form-bordercolor;
    }

    a {
        @extend %link-plain;
        @extend %link-plain--red;
        display: flex;
        align-items: center;
        padding: 0.5em 0;
    }

    .icon {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;

        &.icon--linkedin {
            padding: 0.125em;
            fill: $white;
            background-color: currentColor;
            border-radius: 0.125em;
        }
    }
}
