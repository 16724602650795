/* Panel component (default)
****************************/

.panel {
    &:not(.panel--zero) {
        @extend %panel-padding;
    }

    position: relative;
    // Including default color (second parameter) in case no `.scheme--COLOR` is set
    color: var(--color_scheme_text, $fssa-textcolor);
    background-color: var(--color_scheme_background, $white);
    box-shadow: $fssa-box-shadow;

    > *:first-child,
    > .panel-container:first-child .panel {
        margin-top: 0;
    }

    > *:last-child,
    > .panel-container:last-child .panel {
        margin-bottom: 0;
    }

    // Panel component nesting
    .panel {
        height: 100%;
        border: solid em(1px, 16px) $fssa-bgcolor;
        box-shadow: none;
    }

    // Direct panel within panel
    > .panel-container {
        .panel {
            margin: 2em 0;
        }
    }
}

.panel.panel--watermark {
    &::before {
        display: block;
        position: absolute;
        inset: 0;
        background-color: rgba($white, 0.05);
        mask-image: url("../img/svg/fssa-bamboo.svg");
        mask-position: var(--mask_position, left top);
        mask-repeat: no-repeat;
        content: "";
        z-index: -1;
    }

    &.scheme--light-gray,
    &.scheme--white {
        &::before {
            background-color: rgba($black, 0.05);
        }
    }
    &.scheme--blue,
    &.scheme--light-gray,
    &.scheme--white {
        z-index: 0;
    }

}

// Add 1px division between panel component (direct) siblings
*:not(.columns) > .panel-container + .panel-container {
    .panel {
        border-top: solid em(1px, 16px) $fssa-bgcolor;
    }
}

// First sibling directly after hero component
.hero-container + .panel-container {
    > .panel {
        @extend %post-hero-margin;
    }
}
