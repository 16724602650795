/* Teaser component (default)
*****************************/
// @note 1). Teaser gets much of its styling from the shared
// teaser style class `.promo`, `.promo-ITEM_NAME` rules

// @note 2).
// Breaking slightly with design prototype.
// Teaser description & CTA arrow will NOT overlap with each other,
// which means a little bit less width available to the description paragraphs at desktop viewports.
//
// Why was this done?
//
// 1. Avoid text becoming hard to read
// 2. The overlap styling looking cramped (esp. on narrow desktop viewports) and
//    therefore looking like a mistake

.teaser {
    color: $white;
    background-color: $fssa-bgcolor--teaser;
    filter: contrast(100%) brightness(95%);

    &:hover,
    &:focus-within {
        filter: contrast(110%) brightness(105%);
    }
}

.teaser-type {
    min-height: em(260px, 16px);
    flex-grow: 1;
    justify-content: flex-end;

    @media screen and (min-width: $viewport-narrow) {
        min-height: em(420px, 16px);
    }
}

.teaser-image {
    @extend %image-gradient-overlay;
    position: absolute;
    width: 100%;
    height: em(260px, 16px);
    flex-grow: 1;

    @media screen and (min-width: $viewport-narrow) {
        position: relative;
        height: em(320px, 16px);
    }
}

.teaser-text {
    // @warn:
    // Do NOT set `.teaser-text` to `position: relative`;
    // this will reduce the link target area so it no longer covers
    // the whole image area
    position: static;
    display: grid;
    grid-template-columns: 1fr #{em(42px, 16px)};
    grid-template-rows: 1fr;
    /* prettier-ignore */
    grid-template-areas:
        "title       icon"
        "description icon";
    align-items: end;
    gap: 0 0.5em;

    flex-grow: 0; // Required for iOS Safari narrow screens

    @media screen and (min-width: $viewport-wide) {
        margin-right: -1em;
        word-wrap: anywhere;
    }
}

.teaser-title {
    grid-area: title;
}

.teaser-description {
    grid-area: description;

    @media screen and (min-width: $viewport-narrow) {
        display: block;
    }
}

.teaser-cta {
    position: relative;
    margin: 0;

    grid-area: icon;
    justify-self: right;

    background-color: transparent;

    // The teaser hover/focus & focus-within states
    .teaser:hover &,
    .teaser:focus-within & {
        @media screen and (min-width: $viewport-wide) {
            transform: translateX(0.5em);
        }
    }
}
