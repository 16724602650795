/* Table component (Regular variant)
************************************/

.table--regular {
    tbody {
        background-color: $fssa-light-gray;
    }

    th[scope="col"],
    .row-heading {
        font-size: 1.2rem;
    }
}

.table--fssa {
    margin-bottom: 15px;
    border-bottom: solid 3px #e22d2c;

    th, td {
      padding: 5px 7px;
      border-bottom: solid 0.5px #000;
      border-left: none;
      border-right: none;
      text-align: right;
      font-size: 0.9rem;
      font-weight: 400;
    }

    th {
      background-color: #0f2d52;
      color: white;
    }

    @media(min-width: 700px) and (max-width:1000px) {
          table {
          width: 100%;
        }
    }

    @media(min-width: 0) and (max-width:700px) {
        table {
          display: block;
          overflow-x: auto;
        }
    }
}
