/* Important Note (default)
**************************/


.fsi-important-note {
    &__wrapper {
        background-color: #ecedef;
    	padding: 0 2.5em 0em 2.5em;
    }
    &__accordion-block {
        position: relative;
        padding-top: 6px;
        padding-bottom: 6px;
        cursor: pointer;
    }
    &__vertical-icon {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        transform-style: preserve-3d;
    }
    &__title-1 {
        margin-left: 26px;
        color: #59667c;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.3px;
    }
    &__title-2 {
        margin-left: 26px;
        color: #59667c;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.3px;
    }
    &__subtitle {
        font-weight: 300;
    }
    &__button-expand {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        border-radius: 4px;
        background-color: transparent;
        &.fsi-important-note__blue {
            position: absolute;
            left: 0%;
            top: 0%;
            right: auto;
            bottom: auto;
            width: 20px;
            height: 20px;
            margin-top: 7px;
            background-color: #fff;
            opacity: 0.36;
            border-color: #022856;
        }
    }
    &__accordion-wrapper {
        overflow: hidden;
        max-height:250px;
        transition: max-height 500ms ease-in-out;
    }
    &__margin {
        height: 1px;
        margin-bottom: 20px;
        background-color: #d6d9df;
    }
    &__white-fade {
        position: absolute;
        left: 0%;
        top: auto;
        right: 0%;
        bottom: 0%;
        z-index: 1;
        height: 48px;
        margin-right: 24px;
        margin-bottom: 1px;
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #ecedef 85%);
    }
    &__scrolling-text {
        position: relative;
        overflow: auto;
        height: 192px;
        margin-bottom: 24px;
        padding-right: 24px;
        border-bottom: 1px solid #d6d9df;
    }
    &__disclaimer {
        p {
            font-size: 15px;
            line-height: 1.3em;
        }
        li{
			color: #59667c;
    		font-family: Suisseintl,sans-serif;
    		font-weight: 400;
    		font-size: .9375rem;
		}
    }
}

.important-note-vertical-icon {
    background-color: #022856;
    position: absolute;
    height: 100%;
    width: 2px;
    transition: all .5s ease;
}

.accordion__price-performance-vertical-icon-clicked {
	transform: rotate(90deg);
}

.important-note-horizontal-icon  {
    background-color: #022856;
    width: 100%;
    height: 2px;
}

.important-note-hide {
	display: none !important;
}

.important-note-show {
	display: block !important;
}

.important-note-collapsed {
	max-height: 0px !important;
	display: block !important;
}

.important-note-expanded {
	max-height: 600px !important;
}

.important-note__content {
	overflow: hidden;
    transition: max-height 500ms ease-in-out;
}

.important-note--icon-block {
    position: relative;
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    &.important-note-block {
    	height: 12px;
    	width: 12px;
    }
}
