/* Title component (default)
****************************/

.heading--linked {
    a {
        display: block;
        font-weight: inherit;
    }

    svg {
        display: inline-block;
        top: rem(-1px, 16px);
        width: 1.8rem;
        height: 1.8rem;
    }
}

.title-lead {
    @extend %text--lead;

    p:first-child {
        margin-top: 0;
    }
}
