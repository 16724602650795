/* Site Disclaimer component (default)
**************************************/

// .site-disclaimer {}

.disclaimer-form {
    padding: 1em;

    @media screen and (min-width: $viewport-medium) {
        padding-left: 2em;
        padding-right: 2em;
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
    }

    .disclaimer-terms {
        position: relative;
        margin-bottom: 1.5em;
        padding-right: 1em;
    }

    .investor-types {
        display: flex;
        flex-direction: column;
        margin: 0 -0.25em;

        @media screen and (min-width: $viewport-wide) {
            flex-direction: row;
        }
    }

    .investor-type {
        position: relative;
        margin: 0.25em;

        input[type="radio"] {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transform: scale(0.4);
        }

        label {
            --btn_color_background: #{$white};
            --btn_color_border: #{$fssa-form-bordercolor};
            --btn_color_text: #222;

            display: block;
            padding: 0.75em 1.5em;

            color: var(--btn_color_text);
            font-weight: 500;
            text-align: center;

            background-color: var(--btn_color_background);
            border: solid 0.0625em var(--btn_color_border);
            border-radius: 0.25em;
            cursor: pointer;

            transition: all 0.2s ease;
        }

        label:hover,
        input[type="radio"]:focus + label {
            --btn_color_background: #{$fssa-airforce};
            --btn_color_border: #{$fssa-airforce};
            --btn_color_text: #{$white};
        }

        input[type="radio"]:checked + label {
            --btn_color_background: #{$fssa-blue};
            --btn_color_border: #{$fssa-blue};
            --btn_color_text: #{$white};
        }
    }
}

.disclaimer--header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;

    margin-bottom: 1.5em;
    padding-bottom: 1em;
    border-bottom: solid 0.0625em $fssa-form-bordercolor;

    .choose-investor-type {
        width: 100%;
        align-self: center;
        justify-self: right;

        @media screen and (min-width: $viewport-wide) {
            width: auto;
        }
    }
}

.disclaimer--footer {
    .cta {
        margin-top: 1em;
        margin-bottom: 0;
    }

    button[type="submit"] {
        order: 1;
    }
}
