/* Columns component (Footer variant)
*************************************/

.footer {
    // Footer three-column component containing:
    // 1. & 2. Two container components (for List components)
    // 3. Footer Logo component
    .columns.columns--3 {
        gap: 0.5em 2em;

        // Move to two-columns; Footer Logos drop below
        @media screen and (min-width: $viewport-medium) {
            gap: 2em;
            grid-template-columns: 1fr 1fr;
        }

        // Move to three-columns/one row; Footer Logos sits in-line (width according to content)
        // Remaining row width divided between the two container components
        @media screen and (min-width: $viewport-wide) {
            gap: var(--columns_gap);
            grid-template-columns: repeat(2, 1fr) auto;
        }
    }
}
