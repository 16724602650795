/* Timeline Component (default)
*******************************/

.timeline-container {
    @media screen and (min-width: $viewport-medium) {
        margin-left: -2em;
        margin-right: -2em;
    }
}

.timeline {
    padding: 3em 2em;

    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);

    @media screen and (min-width: $viewport-medium) {
        padding-top: 6em;
        padding-bottom: 6em;
    }

    @media screen and (min-width: $viewport-wide) {
        padding-top: 10em;
        padding-bottom: 10em;
    }

    .restrict-width {
        max-width: 71.25em;
        margin: 0 auto;
    }
}

.timeline-introduction {
    margin-bottom: 3em;
    text-align: center;

    @media screen and (min-width: $viewport-medium) {
        margin-top: 1.875em;
        margin-bottom: 5.625em;
    }

    p {
        @extend %text--lead;
    }

    &.animation {
        animation: fadeIn 1s ease-out forwards, slideInUp 1s cubic-bezier(0.39, 0.58, 0.57, 1) forwards;
    }
}

.timeline-slides {
    position: relative;
    max-width: 56.25em;
    margin: 0 auto;
    padding: 0;
    list-style: none;

    &::before {
        position: absolute;
        top: 0;
        left: 2.5rem;

        display: block;
        width: 0.125rem;
        height: 100%;
        background: rgba($white, 0.22);
        content: "";

        @media screen and (min-width: $viewport-medium) {
            left: 50%;
        }
    }
}

.timeline-slide {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "year slide_description";

    @media screen and (min-width: $viewport-medium) {
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: "slide_image year slide_description";
        align-items: center;
    }

    // Outline for accessibility
    // Help keyboard/switch navigators know what is active in the page
    &[tabindex]:focus {
        outline: dotted 0.125em $fssa-airforce;
        outline-offset: 0.5em;
    }

    &.animation {
        transform-style: preserve-3d;
        animation: unfoldHorizontal 0.75s cubic-bezier(0.39, 0.58, 0.57, 1) forwards;
        animation-delay: 0.5s;
    }

    .slide-image {
        display: none;
        grid-area: slide_image;

        @media screen and (min-width: $viewport-medium) {
            display: block;
            padding: 1em;
        }

        @media screen and (min-width: $viewport-wide) {
            padding: 1.875em;
        }

        &::before {
            display: block;
            width: 14.5em;
            height: 14.5em;

            // Background-image only displays on tablet/desktop viewports
            // Image loading done via CSS custom property to ensure image not downloaded
            // unless at desktop viewport width
            background: center center no-repeat;
            background-image: var(--slide_background_image);
            background-size: cover;
            border-radius: 50%;

            content: "";

            @media screen and (min-width: $viewport-wide) {
                width: 18.75em;
                height: 18.75em;
            }
        }
    }

    .year-roundal {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        grid-area: year;
        align-self: start;
        // ^^ Grid-style rule: Keep vertically top (esp. for tablet/desktop view)

        width: 5rem;
        height: 5rem;

        margin: 0;
        padding: 0.625rem;

        font-size: 1.3rem;
        font-weight: 600;

        background: $fssa-red;
        border-radius: 50%;
    }

    .slide-description {
        margin: 0 0 2.25em;
        padding: 0 1em;
        grid-area: slide_description;

        @media screen and (min-width: $viewport-medium) {
            margin-bottom: 0;
        }

        p {
            @extend %text--lead;
        }
    }
}

.timeline-slide:nth-child(odd) {
    @media screen and (min-width: $viewport-medium) {
        grid-template-areas: "slide_description year slide_image";
    }
}

// Negative margin-top in relation to preceding/following panel component
.panel-container + .timeline-container > .timeline,
.timeline-container + .panel-container > .panel {
    @media screen and (min-width: $viewport-medium) {
        margin-top: -4.5em;
    }

    @media screen and (min-width: $viewport-wide) {
        margin-top: -6em;
    }
}
