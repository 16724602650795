/* Group component (default)
****************************/

section.group {
    overflow: hidden;
}

.group {
    .title + .vertical-flow--medium {
        @media screen and (min-width: $viewport-wide) {
            margin-top: 1em;
        }
    }
}
