/* Footer component (default)
*****************************/

.footer {
    svg,
    a svg {
        fill: $white;
    }
}

.footer-inset {
    padding: 2em;
    color: $fssa-textcolor--footer;
    background-color: $fssa-blue;
}

.footer-layout {
    .list-container.vertical-flow {
        margin-top: 0;
        margin-bottom: 0;
    }
}
