/* Insight Teaser component (simple variation)
**********************************************/

.insight-type.simple {
    background-color: $fssa-bgcolor;

    .insight-image {
        height: em(196px, 16px);
    }

    .insight-text {
        justify-content: space-between;
    }

    .insight-heading {
        font-size: 1.2rem;
    }

    .insight-link {
        color: var(--color_link);
    }

    .insight-details {
        padding-top: 0.75em;

        svg {
            color: #abafb2;
        }
    }
}
