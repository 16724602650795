/* Hero component (default)
***************************/

.hero-container {
    position: relative;
    background-color: $white;

    @media screen and (min-width: $viewport-medium) {
        // Counter padding
        margin-left: -2em;
        margin-right: -2em;
    }

    // Adjust left/right margins to fill screen width;
    // breaking-out of main width restriction
    @media screen and (min-width: $viewport-widest) {
        margin-left: calc(-50vw + ((#{$site-maxwidth} / 2) + 2em));
        margin-right: calc(-50vw + ((#{$site-maxwidth} / 2) + 2em));
    }
}

.hero {
    @media screen and (min-width: $viewport-medium) {
        // Show spacer between hero & rest of page content
        padding-bottom: 1.5em;
    }

    .insight-details {
        ul {
            margin-bottom: 1.5em;
            justify-content: flex-start;
        }
    }

    // When no description is available...
    .hero-title + .insight-details {
        margin-top: 1.5em;
    }
}

.hero-inset {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4em 1.5em;

    color: $white;
    background-color: $fssa-bgcolor--teaser;

    /* prettier-ignore */
    background-image:
        linear-gradient(90deg, $fssa-textcolor--blue 10%, rgba($fssa-textcolor--blue, 0) 64%), // Top-most gradient
        linear-gradient(180deg, #76889a, $fssa-bgcolor--teaser);

    @media screen and (min-width: $viewport-narrow) {
        padding: 6em 2em;
    }

    @media screen and (min-width: $viewport-medium) {
        padding-bottom: 10em;
    }

    @media screen and (min-width: $viewport-wide) {
        min-height: 30em;
        padding-bottom: 10.5em;
    }
}

.hero-image {
    @extend %image-gradient-overlay;
    position: absolute;
    inset: 0;
    overflow: hidden;

    &::after {
        // @note: The image-overlaid gradient runs for longer that designs.
        // Changed to ensure better visibility of text over images.
        background-image: linear-gradient(90deg, rgba($fssa-textcolor--blue, 0.7) 56%, rgba($fssa-textcolor--blue, 0));
    }

    img {
        @extend %image-objectfit;

        @media screen and (min-width: $viewport-wide) {
            // Ensure that hero image is larger than hero space for parallax effect
            // Without this (or some increase) there is a risk the edge of the image
            // becomes visible in the hero area, esp. on vertical screens
            width: 120%;
            height: 120%;
            transform: translateY(0);
            transform-style: preserve-3d;
        }
    }

    .one {
        background-image: none !important;

        &::after {
            // @note: The image-overlaid gradient runs for longer that designs.
            // Changed to ensure better visibility of text over images.
            background-image: none !important;
        }
    }
}

.hero-image-no-gradient {
    background-image: none !important;
    position: absolute;
    inset: 0;
    overflow: hidden;

    &::after {
        // @note: The image-overlaid gradient runs for longer that designs.
        // Changed to ensure better visibility of text over images.
        background-image: none !important;
    }

    img {
        @extend %image-objectfit;

        @media screen and (min-width: $viewport-wide) {
            // Ensure that hero image is larger than hero space for parallax effect
            // Without this (or some increase) there is a risk the edge of the image
            // becomes visible in the hero area, esp. on vertical screens
            width: 120%;
            height: 120%;
            transform: translateY(0);
            transform-style: preserve-3d;
        }
    }
}

.hero-text {
    position: relative;

    .restrict-width {
        @media screen and (min-width: $viewport-wide) {
            max-width: 70%;
        }
    }

    html[lang^="zh"] & .restrict-width,
    html[lang^="ja"] & .restrict-width {
        // Target slightly wider screens than small desktop
        @media screen and (min-width: $viewport-wider) {
            max-width: 58%;
        }
    }
}

.hero-breadcrumb {
    ol {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        margin-bottom: 0.75em;
    }

    li:not(.no-link) {
        &::after {
            margin: 0 0.25em;
            content: "/";
        }
    }

    a {
        color: inherit;
        font-weight: 300;
    }
}

.hero-title {
    h1 {
        margin: 0;
        font-size: 2.2rem;
        font-weight: 300;
        letter-spacing: -0.03rem;
        line-height: 1.2;

        @media screen and (min-width: $viewport-medium) {
            font-size: 3rem;
        }

        @media screen and (min-width: $viewport-wide) {
            font-size: 3.4rem;
        }
    }
}

.hero-description {
    font-size: 1.2rem;

    @media screen and (min-width: $viewport-narrow) {
        font-size: 1.3rem;
    }
}
