/* Card component (Contact details variation)
*********************************************/

.card--contact {
    &.card {
        a {
            @extend %link-plain--blue;
            display: flex;
            align-items: center;
        }
    }

    address {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-image {
        @extend %card-stacked-image-heights;

        @media screen and (min-width: $viewport-wide) {
            height: 9em; // 144px
        }
    }

    .p-name {
        font-weight: 500;
    }

    .icon {
        width: 1em;
        height: 1em;
        margin-right: 0.25em;
    }

    .postal-address {
        p {
            margin: 0;
        }
    }

    .contact-details {
        margin-top: 0.75em;

        p {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0.1875em 0;
        }

        .description {
            margin-left: 0.5em;
            font-size: 0.8rem;
        }
    }
}
