/* Card component (Profile variation)
*************************************/
%cardprofile-text {
    width: 100%;
    margin-top: 0.75em;
    padding: 0;
    text-align: center;
}

.card--profile {
    .card-inset {
        align-items: center;
        padding: 1.5em;
        color: var(--color_heading);

        @media screen and (min-width: $viewport-wide) {
            padding: 2em;
        }

        // When card briefly switches to horizontal layout, before going back
        // returning to original layout **eye-roll**
        @media screen and (min-width: $viewport-narrow) and (max-width: $viewport-wide-max) {
            flex-direction: row;
        }
    }

    .card-image {
        position: relative;
        flex-shrink: 1;
        overflow: visible; // Ensures video button:focus-visible displays without clipping

        img {
            max-width: 6.25em;
            border-radius: 50%;
        }

        button {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        svg {
            position: relative;
            width: 1.25em;
            height: 1.25em;
            left: 0.0625em;
        }
    }

    .card-text {
        @extend %cardprofile-text;

        @media screen and (min-width: $viewport-narrow) {
            padding-left: 0.75em;
        }

        @media screen and (min-width: $viewport-medium) {
            padding-left: 1.5em;
        }

        @media screen and (min-width: $viewport-narrow) and (max-width: $viewport-wide-max) {
            margin-top: 0;
            text-align: initial;
        }

        @media screen and (min-width: $viewport-wide) {
            padding-left: initial;
        }

        h2 + p {
            margin: -0.75rem 0 0;
            font-weight: 500;
        }
    }

    .heading--cardprofile {
        @extend %heading--secondary;

        @media screen and (min-width: $viewport-narrow) {
            font-size: 1.6rem;
            font-weight: 300;
        }
    }
}

// When in an aside component, Card Profile remains centrally aligned &
// vertically stacked at all viewports e.g. Article Page Template
aside.aside {
    .card--profile {
        .card-inset {
            flex-direction: column;
            padding: 2em;
        }

        .card-text {
            @extend %cardprofile-text;
        }
    }
}
