/* Border component (default)
*****************************/

.border-highlight {
    margin: 1em 0;
    padding-left: 2em;

    border: 0; // Safari needs this!
    border-left: 0.25em solid;
    border-image: linear-gradient($fssa-red, $fssa-blue) 10;

    %heading--primary,
    .heading--primary {
        @media screen and (min-width: $viewport-wide) {
            font-size: 2.7rem;
        }
    }
}

.panel > .border-highlight:first-child {
    margin-top: 0;
}
