/* CSS/Sass variables
*********************/
// Common/fallback styling settings

// COLORS
$white: #fff;
$black: #000;

$fssa-textcolor: #475966;
$fssa-textcolor--blue: #002a4b;
$fssa-textcolor--link: $fssa-textcolor--blue;
$fssa-textcolor--link-hover: #cc181f;
$fssa-textcolor--footer: #c3c8cc;

$fssa-bgcolor: #e6ebf0;
$fssa-bgcolor--teaser: #144266;
$fssa-light-gray: #f2f5f7;
$fssa-form-bordercolor: #c3c8cc;

// Official brand colors/color names:
$fssa-blue: #0f2d52;
$fssa-blue--hsl: hsl(213, 69%, 19%);
$fssa-blue--rgb: rgb(15, 45, 82);

$fssa-red: #e22e2c;
$fssa-red--hsl: hsl(1, 76%, 53%);
$fssa-red--rgb: rgb(226, 46, 44);

$fssa-gray: #77787b;
$fssa-gray--hsl: hsl(225, 2%, 47%);
$fssa-gray--rgb: rgb(119, 120, 123);

$fssa-mandarin: #e77a25;
$fssa-mandarin--hsl: hsl(26, 80%, 53%);
$fssa-mandarin--rgb: rgb(231, 122, 37);

$fssa-sand: #f6c240;
$fssa-sand--hsl: hsl(43, 91%, 61%);
$fssa-sand--rgb: rgb(246, 194, 64);

$fssa-grass: #a3c359;
$fssa-grass--hsl: hsl(78, 47%, 56%);
$fssa-grass--rgb: rgb(163, 195, 89);

$fssa-airforce: #3699c9;
$fssa-airforce--hsl: hsl(200, 58%, 50%);
$fssa-airforce--rgb: rgb(54, 153, 201);

// BORDERS & SHADOWS
$fssa-box-shadow: 0 10px 12px 0 rgba(71, 89, 102, 0.2);

// VIEWPORT BREAKPOINTS
$viewport-widest: em(1570px, 16px);
$viewport-wider: em(1280px, 16px);
$viewport-wide: em(992px, 16px);
$viewport-medium: em(768px, 16px);
$viewport-narrow: em(480px, 16px);
// $viewport-narrowest: em(368px, 16px);

// (Very occasional use)
$viewport-narrow-max: em(479px, 16px);
$viewport-medium-max: em(767px, 16px);
$viewport-wide-max: em(991px, 16px);

$site-maxwidth: em(1440px, 16px);
$navigation-switch: $viewport-wide;

/* CSS custom properties
************************/
// (aka CSS variables)
// https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
:root {
    --color_heading: #{$fssa-textcolor--blue};
    --color_link: #{$fssa-textcolor--link};
}
