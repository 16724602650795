/* Insight Teaser component (sub-navigation variation)
******************************************************/

.subnav {
    .insight-type.hub {
        min-height: 0;

        .insight-description {
            display: none;
        }
    }

    .insight-type.simple {
        margin: 0.5em 0;

        .insight-text {
            padding: 0;
        }

        .insight-details {
            padding-top: 0.5em;
        }
    }
}
