/* Columns component (Sub-navigation variant)
*********************************************/

.subnav,
.footer-layout {
    > .columns-container.vertical-flow {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.aside,
.group {
    &.column-item > .vertical-flow:first-child {
        margin-top: initial;
    }

    &.column-item > .vertical-flow:last-child {
        margin-bottom: initial;
    }
}
