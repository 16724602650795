/* Skip-to-main component (default)
***********************************/

.skip-to-main {
    position: absolute;
}

a.skip-link {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    color: $white;
    background-color: $fssa-blue;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    transform: translateY(-10em);
    transition: transform 0.2s ease-in-out;
    z-index: 20;

    &:focus,
    &:focus-visible {
        transform: translateY(0);
        outline-offset: em(-1px, 16px);
    }
}
