/* Navigation Extra Links sub-component
***************************************/

.navigation-extra {
    grid-area: navigation-extra;

    &.mobile {
        @extend %slidingPanel;
    }
}

.navxtra-item {
    padding: 0 1em;
    background-color: $white;
    border-bottom: solid em(1px, 16px) $fssa-bgcolor;

    @media screen and (min-width: $viewport-narrow) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: $navigation-switch) {
        padding: 0;
        border-bottom: 0;
        font-size: 0.9rem;
    }

    > a,
    > button {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 400;
    }

    .icon {
        width: 1em;
        height: 1em;
        margin-right: 0.25em;
        color: $fssa-red;

        &.icon--fsi {
            height: 1.25em;
            color: #60beb3;
        }
    }

    &.search-trigger {
        .js & {
            display: none;

            @media screen and (min-width: $navigation-switch) {
                display: block;
            }
        }

        .icon {
            width: 1.25em;
            height: 1.25em;
            margin: auto;
        }
    }

    .btn-login-link {
        color: $white;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
    }

    .btn-login {
        background-color: $fssa-blue;
        border: solid $white;
        color: $white;
        text-decoration: none;
        padding-top: 0.25em;
        padding-right: 1.5em;
        padding-bottom: 0.25em;
        padding-left: 1em;

        &:hover {
            background-color: $fssa-airforce;
            color: $white;
            border: solid $white;
            text-decoration: none;
        }

        > :last-child:not(span) {
            margin-left: 0.25em;
            margin-right: 0em;
            margin-top: 0.25em;
            color: $white;
            height: 24px;
            width: 24px;
        }
    }
}
