/* Article component (default)
******************************/

.main {
    > .article.landmark {
        position: relative;
        padding: 0 0 2em;
        background-color: $fssa-bgcolor;

        @media screen and (min-width: $viewport-medium) {
            padding-left: 2em;
            padding-right: 2em;
            padding-bottom: 4em;
        }
    }
}
