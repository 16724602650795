/* Header component (default)
*****************************/

.header {
    position: relative;
}

.header-layout {
    position: relative; // Sets boundaries of sub-navigation

    // @note:
    // Mobile header is deeper than webqem's designs because they forgot
    // to cater for the two-line (English/Chinese) FSSA logo
    min-height: em(90px, 16px);
    padding: 1em;

    @media screen and (min-width: $viewport-narrow) {
        min-height: em(118px, 16px);
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: $viewport-medium) {
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (min-width: $navigation-switch) {
        display: grid;
        gap: 0 1.5em;
        grid-template-columns: 9.75em 1fr;
        grid-template-rows: repeat(2, auto); // Cookie row; Logo/Navigation row

        padding-top: 0;
        padding-bottom: 0;
    }
}
