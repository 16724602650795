/* Strategy Filter component (default)
**************************************/

// .strategy-filter {}

.no-article-found-message {
    color: #e22e2c;
    border: 0.1em solid #e22e2c;
    padding: 1em 1em;
    text-align: center;
    font-size: 1.125rem;
}

.strategy-form {
    .filter-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em 2em;
        align-items: center;

        > * {
            grid-column: -1 / 1;

            @media screen and (min-width: $viewport-wide) {
                grid-column: initial;
            }
        }

        p {
            display: flex;
            align-items: center;
            margin: 0;
        }

        .heading {
            margin: 0;
        }
    }

    label.heading,
    button.btn-submit {
        flex-shrink: 0;
    }

    button.btn-submit,
    .styled-select {
        margin-left: 0.5em;
        flex-grow: 1;
    }

    .results-message {
        text-align: center;
        font-weight: 400;
    }
}
