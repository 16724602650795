/* Modal Overlay (Region/Country Selector variation)
****************************************************/
// Modal launched via the site navigation "Region" link
// Modal content: Country Selector/Site Disclaimer Terms elements pulled out of the page
// and stored on the FSSA namespace.

.modal.modal--region {
    .modal-body {
        overflow-y: scroll;
        // ^^ Keep scroll bar always in-place.
        // Deliberate deviation from design:
        // 1.) Avoids content jump on investorType buttons
        // 2.) Ensures bigger target area to scroll on shallow screens
        // 3). Indicates to visitors that something is expected in here

        // Subtle fade feature
        &::after {
            display: block;
            position: sticky;
            height: 1.875em;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(180deg, rgba($white, 0), $white 85%);
            content: "";
        }
    }
}
