/* Global styles
****************/

html,
body {
    color: $fssa-textcolor;
    background-color: $white;
    font-family: $font-family;
    font-size: 1rem;
    // ^^ Equivalent to 16px for most browsers,
    //    unless browser settings have been altered by user.
    //    DO NOT set font-sizes/line-heights in pixels; these overrule
    //    user-set browser settings (Accessibility consideration)
    line-height: 1.6;
    font-weight: 300;
    letter-spacing: 0.02rem;
}

body {
    text-size-adjust: none;
    // Prevent phone & tablet devices from adjusting default browser text-size
    // when switching between portrait/landscape orientations
    // (Vendor prefixed versions provided by Autoprefixer)

    overflow-x: hidden;
    // @note: added to guard against horizontal scrollbar in Chrome
    // due to page hero requiring negative margins. ONLY apply to body
    // - applying to the `html, body` rule above prevents the back-to-top anchor
    // from working.
}

::placeholder {
    color: $fssa-textcolor;
    opacity: 0.5;
}

a {
    @extend %link-plain;
    @extend %link-plain--blue;
}

// ELEMENT FOCUS RULES
// For Accessibility reasons make sure there are styles for
// `:focus-visible` on any keyboard focusable element.
// -- `:focus-visible` will be supported by Safari from version 15.3
// -- Use something big & bold, so those with poor vision or in bright environments
//    can see it
// -- It's a feature, not a bug!
a,
button,
input,
select,
textarea {
    &:not(:disabled):focus-visible {
        outline: solid em(2px, 16px) currentColor;
    }
}
// The exception provided here is for elements focussed ONLY via JavaScript
// (`tabindex="-1"`), as these elements fall outside the regular page tab order
[tabindex="-1"] {
    /* stylelint-disable-next-line declaration-no-important */
    outline: none !important;
}

article,
section {
    clear: both;
}

address {
    font-style: normal;
}

b,
strong {
    font-weight: 500;
}

blockquote,
figure,
pre {
    break-inside: avoid;
}

figure {
    margin: 0;
}

figcaption {
    position: relative;
    padding: 0.5em;
    font-size: 0.9rem;
    text-align: center;

    color: var(--color_scheme_text);
    background-color: var(--color_scheme_background);

    p {
        margin-bottom: 0;
    }

    p:first-child {
        margin-top: 0;
    }
}

iframe {
    border: 0;
}

input:not([type="button"]),
select,
textarea {
    width: 100%;
    padding: 0.75em 1em;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: solid em(1px, 16px) $fssa-form-bordercolor;
    border-radius: 0;
}

select[disabled],
textarea[disabled] {
    opacity: 0.4;
}

legend,
fieldset {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

select {
    padding: 0.75em 3em 0.75em 1em;
    background-color: $white;
}

// Allow SVGs to override their inline fill property
svg path {
    fill: inherit;
}

.landmark {
    @media screen and (min-width: $viewport-medium) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
}

.narrow-indent {
    @extend %narrow-indent;
}

.site-maxwidth {
    @extend %site-maxwidth;
}

.vertical-flow {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    .fade &,
    .columns &.column-item,
    .subnav .group & {
        margin-top: initial;
        margin-bottom: initial;
    }
}

.vertical-flow--medium {
    @media screen and (min-width: $viewport-wide) {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

.vertical-flow--large {
    @media screen and (min-width: $viewport-medium) {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    @media screen and (min-width: $viewport-wide) {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

// Screen reader text
// Hides text visually, but exposes it to assistive technology
.visually-hidden:not(:focus):not(:active),
.move-offstage {
    @extend %visually-hidden;
}

.visually-hidden--tablet {
    // Can't call SCSS extend with media query :(
    // Repetition necessary
    @media screen and (min-width: $viewport-medium) {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: 0;
        padding: 0;
        border: 0;
        clip: rect(0, 0, 0, 0);
        // ^^ Clip is deprecated CSS property that older browsers support
        clip-path: inset(100%);
        white-space: nowrap;
        overflow: hidden;
    }
}

.visually-hidden--desktop {
    // Can't call SCSS extend with media query :(
    // Repetition necessary
    @media screen and (min-width: $viewport-wide) {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: 0;
        padding: 0;
        border: 0;
        clip: rect(0, 0, 0, 0);
        // ^^ Clip is deprecated CSS property that older browsers support
        clip-path: inset(100%);
        white-space: nowrap;
        overflow: hidden;
    }
}

.visually-hidden:not(:focus):not(:active) {
    &.desktop-override {
        @media screen and (min-width: $navigation-switch) {
            position: static;
            width: auto;
            height: auto;
            clip: unset;
            clip-path: none;
        }
    }
}

.nonjavascript-hidden,
.nonjavascript-hidden--grid,
.js .javascript-hidden {
    display: none !important;
}

.js .nonjavascript-hidden {
    display: inherit !important;
}

.js .nonjavascript-hidden--grid {
    display: grid !important;
}

.xf-content-height{
margin: 0;
}