/* SCSS MANIFEST
*****************/
/* Sass functions
*****************/
/* Sass mixins
**************/
/* CSS/Sass variables
*********************/
/* CSS custom properties
************************/
:root {
  --color_heading: #002a4b;
  --color_link: #002a4b;
}

@font-face {
  font-family: "suisse-intl";
  /* prettier-ignore */
  src: url(../fonts/SuisseIntl/SuisseIntl-Light-WebS.woff2) format("woff2"), url(../fonts/SuisseIntl/SuisseIntl-Light-WebS.woff) format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "suisse-intl";
  /* prettier-ignore */
  src: url(../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2) format("woff2"), url(../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff) format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "suisse-intl";
  /* prettier-ignore */
  src: url(../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff2) format("woff2"), url(../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff) format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
/* CSS animation styles
***********************/
.subnav, [data-accordion-target], .navigation-search, .navigation-extra.mobile, .navigation.mobile,
.sliding-panel {
  max-height: var(--expand_max, none);
  transition: max-height 0.3s ease;
  overflow: hidden;
}
.closed.subnav, .closed[data-accordion-target], .closed.navigation-search, .closed.navigation-extra.mobile, .closed.navigation.mobile,
.sliding-panel.closed {
  max-height: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes unfoldHorizontal {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}
@keyframes slideInDown {
  0% {
    transform: translateY(-3.125em);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-3.125em);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInUp {
  0% {
    transform: translateY(3.125em);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* SCSS placeholder templates
*****************************/
button,
input[type=button],
input[type=reset],
input[type=submit] {
  margin: 0;
  padding: 0;
  appearance: none;
  color: inherit;
  background-color: transparent;
  border: none;
  font: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  cursor: pointer;
}

.footer-social .social-link:focus-visible, .navigation-search.desktop .btn-search:focus-visible, .promo:focus-within, .btn:not(:disabled):focus-visible {
  outline: solid 0.125em #3699c9;
  outline-offset: 0.125em;
}

.card--textimage .card-image, .card--contact .card-image {
  height: 10.265em;
}
@media screen and (min-width: 30em) {
  .card--textimage .card-image, .card--contact .card-image {
    height: 12.5em;
  }
}

.text h4, .text h3, .text h1,
.text h2,
.heading {
  margin: 0 0 1rem;
  color: var(--color_heading);
  line-height: 1.2;
}

.heading--none {
  margin: 0 0 1em;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.02rem;
}

.text h1,
.text h2,
.heading--primary {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: -0.05rem;
}
@media screen and (min-width: 48em) {
  .text h1,
.text h2,
.heading--primary {
    font-size: 2.4rem;
  }
}

.card--profile .heading--cardprofile, .tmpl-home .card--icontext .heading--cardicon, .text h3,
.heading--secondary {
  margin-bottom: 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
}
@media screen and (min-width: 30em) {
  .card--profile .heading--cardprofile, .tmpl-home .card--icontext .heading--cardicon, .text h3,
.heading--secondary {
    font-size: 1.9rem;
    font-weight: 300;
  }
}

.readmore-text h3, .card--icontext .heading--cardicon, .subnav .title .heading--secondary, .text h4,
.heading--tertiary {
  margin-bottom: 0.75rem;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: -0.02em;
}
@media screen and (min-width: 30em) {
  .readmore-text h3, .card--icontext .heading--cardicon, .subnav .title .heading--secondary, .text h4,
.heading--tertiary {
    font-size: 1.5rem;
  }
}

.heading--quaternary {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.heading--regular {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.6;
}

.heading--small {
  margin-bottom: 0.5em;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.6;
}
@media screen and (min-width: 48em) {
  .heading--small {
    font-size: 1rem;
  }
}

.teaser-image::after, .readmore-image::after, .hero-image::after, .promo-type.hub .promo-image::after {
  position: absolute;
  inset: 0;
  display: block;
  background-image: linear-gradient(rgba(20, 66, 102, 0), #144266);
  content: "";
}

.readmore-image img, .card-image img, .hero-image-no-gradient img, .hero-image img, .promo-image img {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}

.promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.promo-description a {
  position: relative;
  z-index: 1;
}

tbody a,
.row-heading a, .socialshare a, .list a, .nav-item > a,
.nav-item > button,
.navxtra-item > a,
.navxtra-item > button, .promo-description a, a {
  color: var(--color_link);
  font-weight: 500;
  text-decoration: none;
  overflow-wrap: anywhere;
}
.nav-item > button:hover,
.navxtra-item > button:hover, a:hover,
.nav-item > button:focus,
.navxtra-item > button:focus, a:focus {
  text-decoration: underline;
}

.card--contact.card a, .subnav .text a, .list--large.list a, .nav-item > a,
.nav-item > button,
.navxtra-item > a,
.navxtra-item > button, a,
.link--blue {
  --color_link: #002a4b;
}
.card--contact.card a:hover, .subnav .text a:hover, .list--large.list a:hover,
.nav-item > button:hover,
.navxtra-item > button:hover, a:hover, .card--contact.card a:focus, .subnav .text a:focus, .list--large.list a:focus,
.nav-item > button:focus,
.navxtra-item > button:focus, a:focus,
.link--blue:hover,
.link--blue:focus {
  --color_link: #cc181f;
}

.tmpl-casestudy .text a,
.scheme--blue .text a,
.scheme--red .text a,
.scheme--translucent .text a, .promo-description a {
  --color_link: initial;
  text-decoration: underline;
}
.tmpl-casestudy .text a:hover,
.scheme--blue .text a:hover,
.scheme--red .text a:hover,
.scheme--translucent .text a:hover, .promo-description a:hover, .tmpl-casestudy .text a:focus,
.scheme--blue .text a:focus,
.scheme--red .text a:focus,
.scheme--translucent .text a:focus, .promo-description a:focus {
  --color_link: initial;
  text-decoration: none;
}

tbody a,
.row-heading a, .socialshare a, .card a, .text a, .list a,
.link--red {
  --color_link: #cc181f;
}
tbody a:hover,
.row-heading a:hover, .socialshare a:hover, .card a:hover, .text a:hover, .list a:hover, tbody a:focus,
.row-heading a:focus, .socialshare a:focus, .card a:focus, .text a:focus, .list a:focus,
.link--red:hover,
.link--red:focus {
  --color_link: #002a4b;
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.narrow-indent {
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (min-width: 30em) {
  .narrow-indent {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (min-width: 48em) {
  .narrow-indent {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 48em) {
  .hero-container + .jumplinks-container > .jumplinks, .hero-container + .panel-container > .panel {
    margin-top: -6em;
  }
}

.panel:not(.panel--zero),
.panel-padding {
  padding: 2em 1.5em;
}
@media screen and (min-width: 30em) {
  .panel:not(.panel--zero),
.panel-padding {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media screen and (min-width: 62em) {
  .panel:not(.panel--zero),
.panel-padding {
    padding: 3em;
  }
}

.site-maxwidth {
  width: 100%;
  max-width: 90em;
  margin-left: auto;
  margin-right: auto;
}

.timeline-slide .slide-description p, .timeline-introduction p, .title-lead, .text .paragraph--large {
  font-size: 1.2rem;
  line-height: 1.3;
}
@media screen and (min-width: 30em) {
  .timeline-slide .slide-description p, .timeline-introduction p, .title-lead, .text .paragraph--large {
    font-size: 1.3rem;
  }
}

.visually-hidden:not(:focus):not(:active),
.move-offstage {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100%);
  white-space: nowrap;
  overflow: hidden;
}

/**
 * CSS reset provided via Mozilla/JenSimmons
 * -- https://github.com/jensimmons/cssremedy
 * 
 * This reset contains file contents from:
 * - remedy.css
 * - reminders.css
 */
/* @docs
label: Core Remedies
version: 0.1.0-beta.2

note: |
  These remedies are recommended
  as a starter for any project.

category: file
*/
/* @docs
label: Box Sizing

note: |
  Use border-box by default, globally.

category: global
*/
*,
::before,
::after {
  box-sizing: border-box;
}

/* @docs
label: Line Sizing

note: |
  Consistent line-spacing,
  even when inline elements have different line-heights.

links:
  - https://drafts.csswg.org/css-inline-3/#line-sizing-property

category: global
*/
html {
  line-sizing: normal;
}

/* @docs
label: Body Margins

note: |
  Remove the tiny space around the edge of the page.

category: global
*/
body {
  margin: 0;
}

/* @docs
label: Hidden Attribute

note: |
  Maintain `hidden` behaviour when overriding `display` values.

  category: global
*/
[hidden] {
  display: none !important;
}

/* @docs
label: Heading Sizes

note: |
  Switch to rem units for headings

category: typography
*/
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

/* @docs
label: H1 Margins

note: |
  Keep h1 margins consistent, even when nested.

category: typography
*/
h1 {
  margin: 0.67em 0;
}

/* @docs
label: Pre Wrapping

note: |
  Overflow by default is bad...

category: typography
*/
pre {
  white-space: pre-wrap;
}

/* @docs
label: Horizontal Rule

note: |
  1. Solid, thin horizontal rules
  2. Remove Firefox `color: gray`
  3. Remove default `1px` height, and common `overflow: hidden`

category: typography
*/
hr {
  border-style: solid;
  border-width: 1px 0 0;
  color: inherit;
  height: 0;
  overflow: visible;
}

/* @docs
label: Responsive Embeds

note: |
  1. Block display is usually what we want
  2. The `vertical-align` removes strange space-below in case authors overwrite the display value
  3. Responsive by default
  4. Audio without `[controls]` remains hidden by default

category: embedded elements
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
}

/* @docs
label: Responsive Images

note: |
  These new elements display inline by default,
  but that's not the expected behavior for either one.
  This can interfere with proper layout and aspect-ratio handling.

  1. Remove the unnecessary wrapping `picture`, while maintaining contents
  2. Source elements have nothing to display, so we hide them entirely

category: embedded elements
*/
picture {
  display: contents;
}

source {
  display: none;
}

/* @docs
label: Aspect Ratios

note: |
  Maintain intrinsic aspect ratios when `max-width` is applied.
  `iframe`, `embed`, and `object` are also embedded,
  but have no intrinsic ratio,
  so their `height` needs to be set explicitly.

category: embedded elements
*/
img,
svg,
video,
canvas {
  height: auto;
}

/* @docs
label: Audio Width

note: |
  There is no good reason elements default to 300px,
  and audio files are unlikely to come with a width attribute.

category: embedded elements
*/
audio {
  width: 100%;
}

/* @docs
label: Image Borders

note: |
  Remove the border on images inside links in IE 10 and earlier.

category: legacy browsers
*/
img {
  border-style: none;
}

/* @docs
label: SVG Overflow

note: |
  Hide the overflow in IE 10 and earlier.

category: legacy browsers
*/
svg {
  overflow: hidden;
}

/* @docs
label: HTML5 Elements

note: |
  Default block display on HTML5 elements.
  For oldIE to apply this styling one needs to add some JS as well (i.e. `document.createElement("main")`)

links:
  - https://www.sitepoint.com/html5-older-browsers-and-the-shiv/

category: legacy browsers
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

/* @docs
label: Checkbox & Radio Inputs

note: |
  1. Add the correct box sizing in IE 10
  2. Remove the padding in IE 10

category: legacy browsers
*/
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/* @docs
label: Reminders
version: 0.1.0-beta.2

note: |
  All the remedies in this file are commented out by default,
  because they could cause harm as general defaults.
  These should be used as reminders
  to handle common styling issues
  in a way that will work for your project and users.
  Read, explore, uncomment, and edit as needed.

category: file
*/
/* @docs
label: List Style

note: |
  List styling is not usually desired in navigation,
  but this also removes list-semantics for screen-readers

links:
  - https://github.com/mozdevs/cssremedy/issues/15

category: navigation
*/
/* nav ul {
  list-style: none;
} */
/* @docs
label: List Voiceover

note: |
  1. Add zero-width-space to prevent VoiceOver disable
  2. Absolute position ensures no extra space

links:
  - https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/

category: navigation
*/
/* nav li:before {
  content: "\200B";
  position: absolute;
} */
/* @docs
label: Reduced Motion

note: |
  1. Immediately jump any animation to the end point
  2. Remove transitions & fixed background attachment

links:
  - https://github.com/mozdevs/cssremedy/issues/11

category: accessibility
*/
@media (prefers-reduced-motion: reduce) {
  *,
::before,
::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
  }
}
/* @docs
label: Line Heights

note: |
  The default `normal` line-height is tightly spaced,
  but takes font-metrics into account,
  which is important for many fonts.
  Looser spacing may improve readability in latin type,
  but may cause problems in some scripts --
  from cusrive/fantasy fonts to
  [Javanese](https://jsbin.com/bezasax/1/edit?html,css,output),
  [Persian](https://jsbin.com/qurecom/edit?html,css,output),
  and CJK languages.

links:
  - https://github.com/mozdevs/cssremedy/issues/7
  - https://jsbin.com/bezasax/1/edit?html,css,output
  - https://jsbin.com/qurecom/edit?html,css,output

todo: |
  - Use `:lang(language-code)` selectors?
  - Add typography remedies for other scripts & languages...

category: typography
*/
/* html { line-height: 1.5; }
h1, h2, h3, h4, h5, h6 { line-height: 1.25; }
caption, figcaption, label, legend { line-height: 1.375; } */
/* Global styles
****************/
html,
body {
  color: #475966;
  background-color: #fff;
  font-family: "suisse-intl", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: 0.02rem;
}

body {
  text-size-adjust: none;
  overflow-x: hidden;
}

::placeholder {
  color: #475966;
  opacity: 0.5;
}

a:not(:disabled):focus-visible,
button:not(:disabled):focus-visible,
input:not(:disabled):focus-visible,
select:not(:disabled):focus-visible,
textarea:not(:disabled):focus-visible {
  outline: solid 0.125em currentColor;
}

[tabindex="-1"] {
  /* stylelint-disable-next-line declaration-no-important */
  outline: none !important;
}

article,
section {
  clear: both;
}

address {
  font-style: normal;
}

b,
strong {
  font-weight: 500;
}

blockquote,
figure,
pre {
  break-inside: avoid;
}

figure {
  margin: 0;
}

figcaption {
  position: relative;
  padding: 0.5em;
  font-size: 0.9rem;
  text-align: center;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}
figcaption p {
  margin-bottom: 0;
}
figcaption p:first-child {
  margin-top: 0;
}

iframe {
  border: 0;
}

input:not([type=button]),
select,
textarea {
  width: 100%;
  padding: 0.75em 1em;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: solid 0.0625em #c3c8cc;
  border-radius: 0;
}

select[disabled],
textarea[disabled] {
  opacity: 0.4;
}

legend,
fieldset {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

select {
  padding: 0.75em 3em 0.75em 1em;
  background-color: #fff;
}

svg path {
  fill: inherit;
}

@media screen and (min-width: 48em) {
  .landmark {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.vertical-flow {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.fade .vertical-flow, .columns .vertical-flow.column-item, .subnav .group .vertical-flow {
  margin-top: initial;
  margin-bottom: initial;
}

@media screen and (min-width: 62em) {
  .vertical-flow--medium {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

@media screen and (min-width: 48em) {
  .vertical-flow--large {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
@media screen and (min-width: 62em) {
  .vertical-flow--large {
    margin-top: 3em;
    margin-bottom: 3em;
  }
}

@media screen and (min-width: 48em) {
  .visually-hidden--tablet {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(100%);
    white-space: nowrap;
    overflow: hidden;
  }
}

@media screen and (min-width: 62em) {
  .visually-hidden--desktop {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(100%);
    white-space: nowrap;
    overflow: hidden;
  }
}

@media screen and (min-width: 62em) {
  .visually-hidden:not(:focus):not(:active).desktop-override {
    position: static;
    width: auto;
    height: auto;
    clip: unset;
    clip-path: none;
  }
}

.nonjavascript-hidden,
.nonjavascript-hidden--grid,
.js .javascript-hidden {
  display: none !important;
}

.js .nonjavascript-hidden {
  display: inherit !important;
}

.js .nonjavascript-hidden--grid {
  display: grid !important;
}

.xf-content-height {
  margin: 0;
}

/* Icon styles (default)
************************/
.icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  flex-shrink: 0;
  pointer-events: none;
}
.icon.icon--small {
  width: 1.25rem;
  height: 1.25rem;
}
.icon.icon--large {
  width: 1.75rem;
  height: 1.75rem;
}
.icon.icon--xlarge {
  width: 2rem;
  height: 2rem;
}

.icon--chevron {
  position: relative;
  top: 0.125rem;
}

.icon--clock {
  fill: none;
  stroke-linecap: round;
  stroke-width: 2;
  stroke: currentColor;
}

.icon--plus .vertical {
  transform-box: fill-box;
  transform-origin: center;
  transition: transform 0.4s ease;
}
[aria-expanded=true] .icon--plus .vertical {
  transform: rotate(90deg);
}

.subnav .text a:only-child::after {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  background-color: currentColor;
  mask-image: url("../img/svg/chevron.svg");
}

.text a.download::before {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  mask-image: url("../img/svg/download.svg");
}

.text a[href^="http://"]::after,
.text a[href^="https://"]::after,
.text a[target=_blank]::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  mask-image: url("../img/svg/arrow.svg");
  transform: rotate(-45deg);
}

.text blockquote::before {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  background-color: currentColor;
  mask-image: url("../img/svg/quote.svg");
}

/* Button styles (default)
**************************/
button:disabled,
input[type=button]:disabled,
input[type=reset]:disabled,
input[type=submit]:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.btn {
  --btn_color_background: #fff;
  --btn_color_border: #002a4b;
  --btn_color_text: #002a4b;
  display: flex;
  padding: 0.5em 2em;
  color: var(--btn_color_text);
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  background-color: var(--btn_color_background);
  border: solid 0.0625em var(--btn_color_border);
  border-radius: 2.5em;
  transition: all 0.2s ease;
  overflow-wrap: anywhere;
  cursor: pointer;
}
.btn.btn--small {
  padding: 0.5em 1.5em;
  font-size: 0.9rem;
}
@media screen and (min-width: 30em) {
  .btn {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    font-size: 1rem;
  }
}
.btn:hover, .btn:focus {
  --btn_color_background: #3699c9;
  --btn_color_border: #3699c9;
  --btn_color_text: #fff;
}
.btn > :first-child:not(span) {
  margin-left: -0.5em;
  margin-right: 0.25em;
}
.btn > :last-child:not(span) {
  margin-left: 0.25em;
  margin-right: -0.5em;
}
@media screen and (max-width: 29.9375em) {
  .btn svg.icon {
    width: 1rem;
  }
}

.btn-hero {
  --btn_color_background: rgba(0, 29, 51, 0.5);
  --btn_color_border: rgba(255, 255, 255, 0.5);
  --btn_color_text: inherit;
}

.btn-page {
  --btn_color_background: transparent;
  --btn_color_border: transparent;
  --btn_color_text: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.125em;
  padding: 0.25em 0.75em;
  font-size: 1rem;
}
.btn-page:hover, .btn-page:focus {
  --btn_color_background: #f2f5f7;
  --btn_color_border: #e6ebf0;
  --btn_color_text: #3699c9;
}
.btn-page.active {
  --btn_color_background: #3699c9;
  --btn_color_border: #e6ebf0;
  --btn_color_text: #fff;
}
.btn-page[aria-current] {
  --btn_color_background: #3699c9;
  --btn_color_border: #3699c9;
  --btn_color_text: #fff;
}

.btn-play {
  --btn_color_border: #c3c8cc;
  --btn_color_text: #c3c8cc;
  padding: 0.25em;
}
.btn-play svg.icon {
  margin: auto;
}

.btn-reset {
  --btn_color_background: transparent;
  --btn_color_border: #c3c8cc;
  padding: 0.5em 1.5em;
  font-weight: 400;
}
.btn-reset svg.icon {
  width: 1em;
}

.btn.btn-top {
  width: 2.5em;
  height: 2.5em;
  padding: 0.25em;
  font-size: 1rem;
}
.btn.btn-top svg.icon {
  width: 1.5rem;
  margin: auto;
  transform: rotate(-90deg);
}

.link {
  display: flex;
}

/* Promo Panel styles (default)
*******************************/
.promo {
  display: flex;
  break-inside: avoid;
  overflow: hidden;
  transition: all 0.2s ease;
}
.subnav .promo:focus-within {
  outline-color: #e22e2c;
}

.promo-type {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.promo-image {
  overflow: hidden;
}
.promo-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5em;
  z-index: 1;
}
@media screen and (min-width: 30em) {
  .promo-text {
    padding: 2em;
  }
}
@media screen and (min-width: 62em) {
  .promo-text {
    padding: 3em;
  }
}

.promo-heading {
  margin-bottom: 0;
  color: inherit;
}

.promo-link {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  word-wrap: break-word;
}
.promo:focus-within .promo-link:focus {
  text-decoration: underline;
  outline: none;
}

.promo-description {
  display: none;
  overflow: hidden;
}
.promo-description > p {
  max-width: 70ch;
}
.promo-description > p:last-child {
  margin-bottom: 0;
}

.promo-cta {
  display: flex;
  width: 2.625em;
  height: 2.625em;
  padding: 0.5em;
  background-color: #0f2d52;
  border: solid 0.0625em rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  transition: all 0.2s ease;
}
.promo:hover .promo-cta, .promo:focus-within .promo-cta {
  background-color: #3699c9;
}
.promo-cta .icon {
  margin: auto;
}

.promo-type.hub {
  min-height: 18.4375em;
  color: #fff;
  background-color: #144266;
}
@media screen and (min-width: 30em) {
  .promo-type.hub {
    min-height: 20.25em;
  }
}
@media screen and (min-width: 62em) {
  .promo-type.hub {
    min-height: 25.75em;
  }
}
.promo-type.hub .promo-image {
  position: absolute;
  width: 100%;
  height: 100%;
}
.promo-type.hub .promo-image::after {
  background-image: linear-gradient(rgba(0, 42, 75, 0), rgba(0, 42, 75, 0.8) 90%);
}
.promo-type.hub .promo-text {
  position: relative;
  justify-content: flex-end;
}
.promo-type.hub .promo-title {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  margin: 0 0 auto;
}

/* Global Color Schemes
***********************/
.scheme--blue {
  --color_scheme_background: #0f2d52;
  --color_scheme_text: #fff;
}

.scheme--light-gray {
  --color_scheme_background: #f2f5f7;
  --color_scheme_text: #475966;
}

.scheme--red {
  --color_scheme_background: #e22e2c;
  --color_scheme_text: #fff;
}
.scheme--red .btn {
  --btn_color_background: #fff;
  --btn_color_border: #e22e2c;
  --btn_color_text: #e22e2c;
}
.scheme--red .btn:hover, .scheme--red .btn:focus {
  --btn_color_background: #3699c9;
  --btn_color_border: #3699c9;
  --btn_color_text: #fff;
}

.scheme--translucent {
  --color_scheme_background: rgba(1, 19, 35, 0.68);
  --color_scheme_text: #fff;
}
.scheme--translucent figcaption {
  --color_scheme_background: transparent;
}

.scheme--white {
  --color_scheme_background: #fff;
  --color_scheme_text: #475966;
}

.scheme--light-gray,
.scheme--white {
  --color_heading: #002a4b;
}
.scheme--light-gray figcaption,
.scheme--white figcaption {
  --color_scheme_background: #f2f5f7;
  --color_scheme_text: #002a4b;
}

.tmpl-casestudy,
.scheme--blue,
.scheme--red,
.scheme--translucent {
  --color_heading: #fff;
}
.tmpl-casestudy .list-icon .icon,
.scheme--blue .list-icon .icon,
.scheme--red .list-icon .icon,
.scheme--translucent .list-icon .icon {
  fill: currentColor;
}

/* SCSS MANIFEST (PARTIAL)
**************************/
/* Skip-to-main component manifest file
***************************************/
/* Skip-to-main component (default)
***********************************/
.skip-to-main {
  position: absolute;
}

a.skip-link {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  color: #fff;
  background-color: #0f2d52;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  transform: translateY(-10em);
  transition: transform 0.2s ease-in-out;
  z-index: 20;
}
a.skip-link:focus, a.skip-link:focus-visible {
  transform: translateY(0);
  outline-offset: -0.0625em;
}

/* Page component manifest file
*******************************/
/* Page component (default)
***************************/
body.tmpl-casestudy {
  color: #fff;
  background-color: #0f2d52;
}

/* Header component manifest file
*********************************/
/* Header component (default)
*****************************/
.header {
  position: relative;
}

.header-layout {
  position: relative;
  min-height: 5.625em;
  padding: 1em;
}
@media screen and (min-width: 30em) {
  .header-layout {
    min-height: 7.375em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .header-layout {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 62em) {
  .header-layout {
    display: grid;
    gap: 0 1.5em;
    grid-template-columns: 9.75em 1fr;
    grid-template-rows: repeat(2, auto);
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Footer/logos component manifest file
****************************************/
/* Header/logos component (default)
***********************************/
.header-logo {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 3.75em;
  flex-shrink: 0;
  fill: #0f2d52;
}
@media screen and (min-width: 62em) {
  .header-logo {
    position: static;
    align-self: center;
  }
}
.header-logo .logo-lines {
  display: block;
  max-height: 2.375em;
  margin: 0;
  font-size: inherit;
  overflow: hidden;
}
@media screen and (min-width: 30em) {
  .header-logo .logo-lines {
    max-height: 3.5em;
  }
}
.header-logo .logo-lines.logo-lines--two {
  max-height: none;
}
.header-logo a {
  display: block;
}
.header-logo .symbol-highlight {
  fill: #e22e2c;
}

.header-logo,
.header-logo svg,
.match-logo-height {
  width: 6.25em;
  height: 3.75em;
}
@media screen and (min-width: 30em) {
  .header-logo,
.header-logo svg,
.match-logo-height {
    width: 8.75em;
    height: 5.375em;
  }
}
@media screen and (min-width: 48em) {
  .header-logo,
.header-logo svg,
.match-logo-height {
    width: 9.75em;
    height: 5.625em;
  }
}

/* Header/logos component (Case Study Template)
***********************************************/
.tmpl-casestudy .header-logo {
  position: static;
  margin-bottom: 1em;
}
.tmpl-casestudy .header-logo a {
  --color_link: #fff;
}
.tmpl-casestudy .header-logo,
.tmpl-casestudy .header-logo .symbol-highlight {
  fill: currentColor;
}

/* Navigation component manifest file
*************************************/
/* Navigation component (default)
*********************************/
.navigation-container {
  display: grid;
  width: 100%;
  grid-template-columns: auto;
  grid-template-rows: auto;
  /* prettier-ignore */
  grid-template-areas: "navigation-menu" "navigation-main" "navigation-extra" "search";
}
@media screen and (min-width: 62em) {
  .navigation-container {
    width: auto;
    grid-template-columns: minmax(auto, max-content);
    /* prettier-ignore */
    grid-template-areas: "navigation-menu" "navigation-extra" "navigation-main" "search";
    justify-self: end;
  }
}
.navigation-container .match-logo-height {
  display: flex;
  width: auto;
  justify-self: right;
  grid-area: navigation-menu;
}
.navigation-container .btn-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125em;
  height: 3.125em;
  margin: auto;
  color: #fff;
  background-color: #e22e2c;
}
.navigation-container .btn-menu[aria-expanded=true] {
  background-color: #0f2d52;
}
@media screen and (min-width: 30em) {
  .navigation-container .btn-menu {
    width: 3.75em;
    height: 3.75em;
  }
}
@media screen and (min-width: 62em) {
  .navigation-container .match-logo-height,
.navigation-container .btn-menu {
    display: none;
  }
}

.navigation-row {
  margin: 0 -1em;
}
@media screen and (min-width: 30em) {
  .navigation-row {
    margin-left: -1.5em;
    margin-right: -1.5em;
  }
}
@media screen and (min-width: 62em) {
  .navigation-row {
    margin: 0;
  }
}
.navigation-row > ul {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 62em) {
  .navigation-row > ul {
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
  }
}

.navigation {
  grid-area: navigation-main;
  padding-top: 1em;
}
@media screen and (min-width: 62em) {
  .navigation {
    min-height: 3.5em;
    margin-right: -1em;
    padding-top: 0;
  }
}
@media screen and (min-width: 62em) {
  .navigation::before {
    position: relative;
    right: -1em;
    display: block;
    width: calc(100% - 2em);
    height: 0.0625em;
    background-color: #e6ebf0;
    content: "";
  }
}
.navigation > ul > li {
  flex-grow: 0;
}

.nav-item > a,
.nav-item > button,
.navxtra-item > a,
.navxtra-item > button {
  display: block;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media screen and (min-width: 62em) {
  .nav-item > a,
.nav-item > button,
.navxtra-item > a,
.navxtra-item > button {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.nav-item > a:focus-visible,
.nav-item > button:focus-visible,
.navxtra-item > a:focus-visible,
.navxtra-item > button:focus-visible {
  outline-offset: -0.1875rem;
}

.nav-item {
  padding: 0 1em;
  background-color: rgba(230, 235, 240, 0.5);
  border-bottom: solid 0.0625em #e6ebf0;
}
@media screen and (min-width: 30em) {
  .nav-item {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 62em) {
  .nav-item {
    padding: 0;
    background-color: inherit;
    border: 0;
  }
}
.nav-item:first-child {
  border-top: solid 0.0625em #e6ebf0;
}
@media screen and (min-width: 62em) {
  .nav-item:first-child {
    border: 0;
  }
}
.nav-item .subnav[data-accordion-target] {
  margin: 0 -1em;
}
@media screen and (min-width: 30em) {
  .nav-item .subnav[data-accordion-target] {
    margin-left: -1.5em;
    margin-right: -1.5em;
  }
}

/* Navigation Extra Links sub-component
***************************************/
.navigation-extra {
  grid-area: navigation-extra;
}
.navxtra-item {
  padding: 0 1em;
  background-color: #fff;
  border-bottom: solid 0.0625em #e6ebf0;
}
@media screen and (min-width: 30em) {
  .navxtra-item {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 62em) {
  .navxtra-item {
    padding: 0;
    border-bottom: 0;
    font-size: 0.9rem;
  }
}
.navxtra-item > a,
.navxtra-item > button {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.navxtra-item .icon {
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  color: #e22e2c;
}
.navxtra-item .icon.icon--fsi {
  height: 1.25em;
  color: #60beb3;
}
.js .navxtra-item.search-trigger {
  display: none;
}
@media screen and (min-width: 62em) {
  .js .navxtra-item.search-trigger {
    display: block;
  }
}
.navxtra-item.search-trigger .icon {
  width: 1.25em;
  height: 1.25em;
  margin: auto;
}
.navxtra-item .btn-login-link {
  color: #fff;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.navxtra-item .btn-login {
  background-color: #0f2d52;
  border: solid #fff;
  color: #fff;
  text-decoration: none;
  padding-top: 0.25em;
  padding-right: 1.5em;
  padding-bottom: 0.25em;
  padding-left: 1em;
}
.navxtra-item .btn-login:hover {
  background-color: #3699c9;
  color: #fff;
  border: solid #fff;
  text-decoration: none;
}
.navxtra-item .btn-login > :last-child:not(span) {
  margin-left: 0.25em;
  margin-right: 0em;
  margin-top: 0.25em;
  color: #fff;
  height: 24px;
  width: 24px;
}

/* Navigation Search sub-component (mobile view)
************************************************/
.navigation-search {
  grid-area: search;
  margin-bottom: -1em;
  padding: 1em 0;
}
.navigation-search input[type=search] {
  padding-right: 3em;
  font-size: 0.8rem;
  background-color: #f2f5f7;
  border-radius: 0.1875rem;
}
.navigation-search .btn-search {
  position: absolute;
  right: 1em;
  font-weight: 500;
}
.navigation-search .icon {
  width: 1em;
  height: 1em;
  color: #e22e2c;
}
.navigation-search .quicksearch-layout {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
}

/* Navigation Search sub-component (desktop view)
*************************************************/
.navigation-search.desktop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  padding: 2em 1em;
  background-color: #fff;
  background-image: linear-gradient(180deg, #e6ebf0, #fff 17%);
  box-shadow: 0 10px 12px 0 rgba(71, 89, 102, 0.2);
  z-index: 1;
}
.navigation-search.desktop input[type=search] {
  padding-right: 6em;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 1.375rem;
}
.navigation-search.desktop input[type=search]:focus-visible {
  outline: none;
}
.navigation-search.desktop .btn-search {
  height: 100%;
  right: 0;
  padding: 0.4em 2em 0.4em 1.5em;
  color: #fff;
  background-color: #3699c9;
  border-top-right-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
  transition: all 0.2s ease;
}
.navigation-search.desktop .btn-search:hover, .navigation-search.desktop .btn-search:focus {
  background-color: #0f2d52;
  text-decoration: underline;
}
.navigation-search.desktop .icon {
  display: none;
}

/* Cookie Notification component manifest file
**********************************************/
/* Cookie Notification component (default)
******************************************/
.cookies {
  width: 100%;
  grid-column: -1/1;
}

.cookies-inset {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding: 1em 1.5em;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}
@media screen and (min-width: 62em) {
  .cookies-inset {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.cookie-disclaimer .text {
  font-size: 0.9rem;
}

.cookie-actions {
  flex-shrink: 0;
}

/* Main component manifest file
*******************************/
/* Main component (default)
***************************/
.main {
  position: relative;
}
.main[tabindex] {
  outline: 0;
}

.tmpl-casestudy .main.landmark {
  min-height: 100vh;
  padding: 0;
}

/* Article component manifest file
**********************************/
/* Article component (default)
******************************/
.main > .article.landmark {
  position: relative;
  padding: 0 0 2em;
  background-color: #e6ebf0;
}
@media screen and (min-width: 48em) {
  .main > .article.landmark {
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 4em;
  }
}

/* Article component (Case Study Template)
******************************************/
.tmpl-casestudy .main > .article.landmark {
  padding: 1.5em;
  background-color: initial;
}
@media screen and (min-width: 30em) {
  .tmpl-casestudy .main > .article.landmark {
    padding: 1em 2em;
  }
}

/* Hero component manifest file
*******************************/
/* Hero component (default)
***************************/
.hero-container {
  position: relative;
  background-color: #fff;
}
@media screen and (min-width: 48em) {
  .hero-container {
    margin-left: -2em;
    margin-right: -2em;
  }
}
@media screen and (min-width: 98.125em) {
  .hero-container {
    margin-left: calc(-50vw + (90em / 2) + 2em);
    margin-right: calc(-50vw + (90em / 2) + 2em);
  }
}

@media screen and (min-width: 48em) {
  .hero {
    padding-bottom: 1.5em;
  }
}
.hero .insight-details ul {
  margin-bottom: 1.5em;
  justify-content: flex-start;
}
.hero .hero-title + .insight-details {
  margin-top: 1.5em;
}

.hero-inset {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4em 1.5em;
  color: #fff;
  background-color: #144266;
  /* prettier-ignore */
  background-image: linear-gradient(90deg, #002a4b 10%, rgba(0, 42, 75, 0) 64%), linear-gradient(180deg, #76889a, #144266);
}
@media screen and (min-width: 30em) {
  .hero-inset {
    padding: 6em 2em;
  }
}
@media screen and (min-width: 48em) {
  .hero-inset {
    padding-bottom: 10em;
  }
}
@media screen and (min-width: 62em) {
  .hero-inset {
    min-height: 30em;
    padding-bottom: 10.5em;
  }
}

.hero-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
}
.hero-image::after {
  background-image: linear-gradient(90deg, rgba(0, 42, 75, 0.7) 56%, rgba(0, 42, 75, 0));
}
@media screen and (min-width: 62em) {
  .hero-image img {
    width: 120%;
    height: 120%;
    transform: translateY(0);
    transform-style: preserve-3d;
  }
}
.hero-image .one {
  background-image: none !important;
}
.hero-image .one::after {
  background-image: none !important;
}

.hero-image-no-gradient {
  background-image: none !important;
  position: absolute;
  inset: 0;
  overflow: hidden;
}
.hero-image-no-gradient::after {
  background-image: none !important;
}
@media screen and (min-width: 62em) {
  .hero-image-no-gradient img {
    width: 120%;
    height: 120%;
    transform: translateY(0);
    transform-style: preserve-3d;
  }
}

.hero-text {
  position: relative;
}
@media screen and (min-width: 62em) {
  .hero-text .restrict-width {
    max-width: 70%;
  }
}
@media screen and (min-width: 80em) {
  html[lang^=zh] .hero-text .restrict-width, html[lang^=ja] .hero-text .restrict-width {
    max-width: 58%;
  }
}

.hero-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.hero-breadcrumb li {
  margin-bottom: 0.75em;
}
.hero-breadcrumb li:not(.no-link)::after {
  margin: 0 0.25em;
  content: "/";
}
.hero-breadcrumb a {
  color: inherit;
  font-weight: 300;
}

.hero-title h1 {
  margin: 0;
  font-size: 2.2rem;
  font-weight: 300;
  letter-spacing: -0.03rem;
  line-height: 1.2;
}
@media screen and (min-width: 48em) {
  .hero-title h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 62em) {
  .hero-title h1 {
    font-size: 3.4rem;
  }
}

.hero-description {
  font-size: 1.2rem;
}
@media screen and (min-width: 30em) {
  .hero-description {
    font-size: 1.3rem;
  }
}

/* Hero component (Feature insight variation)
*********************************************/
@media screen and (min-width: 62em) {
  .hero.hero--feature .hero-text .restrict-width {
    max-width: 50%;
  }
}
@media screen and (min-width: 48em) {
  .hero.hero--feature .hero-title h1 {
    font-size: 2.7rem;
  }
}

/* Hero component (Home Page Template)
**************************************/
@media screen and (min-width: 48em) {
  .hero--home .hero-inset {
    min-height: 43.73em;
  }
}
@media screen and (min-width: 62em) {
  .hero--home .hero-inset {
    min-height: 80vh;
  }
}
.hero--home .hero-title {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards, slideInDown 1.15s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
  animation-delay: 1.5s;
}
.hero--home .hero-description {
  opacity: 0;
  animation: fadeIn 0.75s ease-out forwards;
  animation-delay: 2.5s;
}
.hero--home .hero-cta {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards, slideInLeft 0.5s ease-out forwards;
  animation-delay: 2.65s;
}

/* Panel component manifest file
********************************/
/* Panel component (default)
****************************/
.panel {
  position: relative;
  color: var(--color_scheme_text, #475966);
  background-color: var(--color_scheme_background, #fff);
  box-shadow: 0 10px 12px 0 rgba(71, 89, 102, 0.2);
}
.panel > *:first-child,
.panel > .panel-container:first-child .panel {
  margin-top: 0;
}
.panel > *:last-child,
.panel > .panel-container:last-child .panel {
  margin-bottom: 0;
}
.panel .panel {
  height: 100%;
  border: solid 0.0625em #e6ebf0;
  box-shadow: none;
}
.panel > .panel-container .panel {
  margin: 2em 0;
}

.panel.panel--watermark::before {
  display: block;
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.05);
  mask-image: url("../img/svg/fssa-bamboo.svg");
  mask-position: var(--mask_position, left top);
  mask-repeat: no-repeat;
  content: "";
  z-index: -1;
}
.panel.panel--watermark.scheme--light-gray::before, .panel.panel--watermark.scheme--white::before {
  background-color: rgba(0, 0, 0, 0.05);
}
.panel.panel--watermark.scheme--blue, .panel.panel--watermark.scheme--light-gray, .panel.panel--watermark.scheme--white {
  z-index: 0;
}

*:not(.columns) > .panel-container + .panel-container .panel {
  border-top: solid 0.0625em #e6ebf0;
}

/* Panel component (fade variation)
***********************************/
.fade > .panel-container .panel {
  box-shadow: none;
}
@media screen and (min-width: 62em) {
  .fade > .panel-container .panel {
    padding: 3em;
  }
}

/* Columns component manifest file
**********************************/
/* Columns component (default)
******************************/
.columns {
  --columns_number: 2;
  --columns_gap: 1.5em;
  display: grid;
  gap: 1.5em;
}
@media screen and (min-width: 62em) {
  .columns {
    gap: var(--columns_gap);
    grid-template-columns: repeat(var(--columns_number), 1fr);
  }
}
.columns.dividers {
  gap: 0;
  outline: solid 0.0625em #e6ebf0;
  outline-offset: -0.0625em;
  overflow: hidden;
}
.columns.dividers > *.column-item {
  margin: 0.03125em;
  padding: 1.5em;
  outline: solid 0.03125em #e6ebf0;
}
@media screen and (min-width: 30em) {
  .columns.dividers > *.column-item {
    padding: 2em;
  }
}
@media screen and (min-width: 62em) {
  .columns.dividers > *.column-item {
    padding: var(--columns_gap);
  }
}

@media screen and (min-width: 62em) {
  .columns--2.ratio--three-one {
    grid-template-columns: 3fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .columns--2.ratio--one-three {
    grid-template-columns: 1fr 3fr;
  }
}

.panel:not(.panel--zero) .columns.padding--counter-horizontal {
  margin-left: -1.5em;
  margin-right: -1.5em;
}
@media screen and (min-width: 30em) {
  .panel:not(.panel--zero) .columns.padding--counter-horizontal {
    margin-left: -2em;
    margin-right: -2em;
  }
}
@media screen and (min-width: 62em) {
  .panel:not(.panel--zero) .columns.padding--counter-horizontal {
    margin-left: -3em;
    margin-right: -3em;
  }
}
.panel:not(.panel--zero) .columns.padding--counter-vertical {
  margin-top: -2em;
  margin-bottom: -2em;
}
@media screen and (min-width: 62em) {
  .panel:not(.panel--zero) .columns.padding--counter-vertical {
    margin-top: -3em;
    margin-bottom: -3em;
  }
}

/* Columns component (Sub-navigation variant)
*********************************************/
.subnav > .columns-container.vertical-flow,
.footer-layout > .columns-container.vertical-flow {
  margin-top: 0;
  margin-bottom: 0;
}

.aside.column-item > .vertical-flow:first-child,
.group.column-item > .vertical-flow:first-child {
  margin-top: initial;
}
.aside.column-item > .vertical-flow:last-child,
.group.column-item > .vertical-flow:last-child {
  margin-bottom: initial;
}

/* Columns component (Footer variant)
*************************************/
.footer .columns.columns--3 {
  gap: 0.5em 2em;
}
@media screen and (min-width: 48em) {
  .footer .columns.columns--3 {
    gap: 2em;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62em) {
  .footer .columns.columns--3 {
    gap: var(--columns_gap);
    grid-template-columns: repeat(2, 1fr) auto;
  }
}

/* Country selector component manifest file
*******************************************/
/* Country selector component (default)
***************************************/
.country-selector {
  position: relative;
  padding: 2em;
}
.country-selector li {
  margin: 0.3125em 0;
}
.country-selector span.country-name {
  font-weight: 400;
}
.country-selector .flag {
  display: inline-block;
  vertical-align: baseline;
  height: 1em;
}
.country-selector .continents {
  display: grid;
  gap: 1.5em;
  grid-template-columns: 1fr;
  /* prettier-ignore-start */
  grid-template-areas: "oceania" "asia" "europe" "northamerica";
  /* prettier-ignore-end */
}
@media screen and (min-width: 30em) {
  .country-selector .continents {
    grid-template-columns: repeat(2, minmax(10.25em, 1fr));
    /* prettier-ignore-start */
    grid-template-areas: "oceania asia" "europe europe" "northamerica ...";
    /* prettier-ignore-end */
  }
}
@media screen and (min-width: 62em) {
  .country-selector .continents {
    grid-template-columns: repeat(2, 1fr) minmax(max-content, 50%);
    /* prettier-ignore-start */
    grid-template-areas: "oceania        asia europe" "northamerica   asia europe";
    /* prettier-ignore-end */
  }
}
.country-selector .continent-as {
  grid-area: asia;
}
.country-selector .continent--eu {
  grid-area: europe;
}
.country-selector .continent--eu .countries {
  columns: 10em;
  column-gap: 1.5em;
}
@media screen and (min-width: 62em) {
  .country-selector .continent--eu .countries {
    column-gap: 0;
  }
}
.country-selector .continent--eu .countries > li:first-child {
  margin-top: 0;
}
.country-selector .continent-na {
  grid-area: northamerica;
}
.country-selector .continent-oc {
  grid-area: oceania;
}
.country-selector .country .flag {
  margin-right: 0.375em;
}
.country-selector .investor-types {
  padding-left: 3em;
}

.country-selector--header {
  margin-bottom: 1.5em;
  padding-bottom: 1em;
  border-bottom: solid 0.0625em #c3c8cc;
}
.country-selector--header h2.heading--primary {
  position: relative;
}
.country-selector--header button {
  flex-shrink: 0;
}
.country-selector--header .select-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 48em) {
  .country-selector--header .select-location {
    margin-top: -0.5em;
    justify-content: flex-start;
  }
}
.country-selector--header .current-location {
  margin: 0 0.25em;
}
.country-selector--header .heading--sub,
.country-selector--header .current-location {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0;
}
.country-selector--header .flag {
  margin-right: 0.25em;
}

/* Country selector component (Modal variation)
***********************************************/
.country-selector--modal {
  padding: 0;
}
.country-selector--modal .country-selector--header {
  margin-bottom: 0.75em;
  padding-bottom: 0;
  border: 0;
}
.country-selector--modal .country-selector--header h2 {
  margin-right: 5em;
}
.country-selector--modal .country-selector--body {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 1.25em 0 rgba(0, 20, 53, 0.22);
  z-index: 1;
}
.country-selector--modal .country-selector--body .continents {
  max-height: 50vh;
  padding: 1.5em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Modal component manifest file
********************************/
/* Modal Mask component (default)
*********************************/
.modal-mask:not([hidden]) {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(15, 45, 82, 0.8);
  opacity: 0;
  z-index: 2999999998;
}
[data-modal-open] .modal-mask:not([hidden]) {
  opacity: 1;
}

.page-content-container[aria-hidden] {
  position: fixed;
  inset: 0;
  filter: blur(0.2em);
  opacity: 0.6;
  overflow: hidden;
}

body[data-modal-open] {
  height: 100vh;
}

/* Modal Overlay (default)
**************************/
.modal {
  position: relative;
  margin: auto;
  max-width: 80em;
  width: 98%;
  height: 98%;
  color: inherit;
  background-color: #fff;
  border: solid 0.0625em #0f2d52;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.1s ease, transform 0.2s ease;
}
@media screen and (min-width: 48em) {
  .modal {
    width: 90%;
    height: 90%;
  }
}
@media screen and (min-width: 62em) {
  .modal {
    width: 80%;
    height: 80%;
  }
}
.modal .modal-close {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .modal .modal-close {
    top: 2em;
    right: 2em;
  }
}
.modal .modal-close[hidden] {
  display: none;
}
.modal .modal-content {
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* prettier-ignore */
  grid-template-areas: "modal-header" "modal-body" "modal-footer";
  height: 100%;
}
.modal .modal-header {
  grid-area: modal-header;
  margin: 1em;
  border-bottom: solid 0.0625em #c3c8cc;
}
@media screen and (min-width: 48em) {
  .modal .modal-header {
    margin: 1.5em 2em 0;
  }
}
@media screen and (min-width: 62em) {
  .modal .modal-header > * {
    padding-top: 0.5em;
  }
}
.modal .modal-body {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  grid-area: modal-body;
}
.modal .modal-footer {
  grid-area: modal-footer;
  margin: 1em;
  border-top: solid 0.0625em #c3c8cc;
}
@media screen and (min-width: 48em) {
  .modal .modal-footer {
    margin: 0 2em 1em;
  }
}
.modal .loading-animation {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}
.modal .loading-animation > .loader {
  width: 6rem;
  height: 6rem;
  margin: auto;
  border: solid 0.6rem #e6ebf0;
  border-top-color: #3699c9;
  border-radius: 50%;
  animation: spinner 2.5s linear infinite;
}
.modal iframe {
  width: 100%;
  max-width: none;
  min-height: 100%;
  opacity: 0;
  overflow-y: hidden;
  transition: opacity 0.1s ease;
}
.modal iframe.loaded {
  opacity: 1;
}

[data-modal-open] .modal {
  opacity: 1;
  transform: scale(1);
}

/* Modal Overlay (Case Study variation)
***************************************/
.modal.modal--casestudy {
  max-width: none;
  width: 100%;
  height: 100%;
}
.modal.modal--casestudy .modal-content,
.modal.modal--casestudy .modal-body {
  height: 100%;
}
.modal.modal--casestudy .modal-content {
  background-color: #0f2d52;
}

/* Modal Overlay (Region/Country Selector variation)
****************************************************/
.modal.modal--region .modal-body {
  overflow-y: scroll;
}
.modal.modal--region .modal-body::after {
  display: block;
  position: sticky;
  height: 1.875em;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff 85%);
  content: "";
}

/* Modal Overlay (Video variation)
**********************************/
.modal.modal--video {
  max-width: 40em;
  height: auto;
  border-color: #cfdbe5;
}
.modal.modal--video iframe {
  opacity: 1;
}
.modal.modal--video .video-type {
  position: relative;
}
.modal.modal--video .btn.modal-close {
  top: 0;
  right: 0;
  padding: 0.25em;
  color: #fff;
  background: transparent;
  border-color: transparent;
}
@media screen and (min-height: 28.75em) {
  .modal.modal--video .btn.modal-close {
    top: -2.5em;
  }
}
.modal.modal--video .btn.modal-close:hover, .modal.modal--video .btn.modal-close:focus {
  color: #fff;
  background-color: #3699c9;
  border-color: #3699c9;
}
.modal.modal--video .btn.modal-close svg {
  margin: 0;
}
.modal.modal--video .modal-content {
  background-color: #0f2d52;
}

/* Site Disclaimer component manifest file
******************************************/
/* Site Disclaimer component (default)
**************************************/
.disclaimer-form {
  padding: 1em;
}
@media screen and (min-width: 48em) {
  .disclaimer-form {
    padding-left: 2em;
    padding-right: 2em;
  }
}
.disclaimer-form fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.disclaimer-form .disclaimer-terms {
  position: relative;
  margin-bottom: 1.5em;
  padding-right: 1em;
}
.disclaimer-form .investor-types {
  display: flex;
  flex-direction: column;
  margin: 0 -0.25em;
}
@media screen and (min-width: 62em) {
  .disclaimer-form .investor-types {
    flex-direction: row;
  }
}
.disclaimer-form .investor-type {
  position: relative;
  margin: 0.25em;
}
.disclaimer-form .investor-type input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(0.4);
}
.disclaimer-form .investor-type label {
  --btn_color_background: #fff;
  --btn_color_border: #c3c8cc;
  --btn_color_text: #222;
  display: block;
  padding: 0.75em 1.5em;
  color: var(--btn_color_text);
  font-weight: 500;
  text-align: center;
  background-color: var(--btn_color_background);
  border: solid 0.0625em var(--btn_color_border);
  border-radius: 0.25em;
  cursor: pointer;
  transition: all 0.2s ease;
}
.disclaimer-form .investor-type label:hover,
.disclaimer-form .investor-type input[type=radio]:focus + label {
  --btn_color_background: #3699c9;
  --btn_color_border: #3699c9;
  --btn_color_text: #fff;
}
.disclaimer-form .investor-type input[type=radio]:checked + label {
  --btn_color_background: #0f2d52;
  --btn_color_border: #0f2d52;
  --btn_color_text: #fff;
}

.disclaimer--header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5em;
  margin-bottom: 1.5em;
  padding-bottom: 1em;
  border-bottom: solid 0.0625em #c3c8cc;
}
.disclaimer--header .choose-investor-type {
  width: 100%;
  align-self: center;
  justify-self: right;
}
@media screen and (min-width: 62em) {
  .disclaimer--header .choose-investor-type {
    width: auto;
  }
}

.disclaimer--footer .cta {
  margin-top: 1em;
  margin-bottom: 0;
}
.disclaimer--footer button[type=submit] {
  order: 1;
}

/* Call-To-Action component manifest file
*****************************************/
/* Call-To-Action component (default)
*************************************/
.cta {
  display: flex;
  flex-flow: row wrap;
  margin: 0.5em -0.5em;
  align-items: center;
}
.cta a,
.cta button {
  margin: 0.5em;
}
.cta.alignment--right {
  justify-content: flex-end;
}
.cta.alignment--spaced {
  justify-content: space-between;
}
.cta.expand-all {
  margin-top: -1.25em;
}
@media screen and (min-width: 62em) {
  .cta.expand-all {
    margin: 0;
    float: right;
  }
}

/* Image component manifest file
********************************/
/* Image component (default)
****************************/
.image {
  break-inside: avoid;
  overflow: hidden;
}
.image figure,
.image img {
  width: 100%;
  margin: 0 auto;
}
.image figure {
  display: table;
}
.image figcaption {
  display: table-caption;
  caption-side: bottom;
}

/* List component manifest file
*******************************/
/* List component (default)
***************************/
.list {
  margin: 0;
  padding: 0 1em;
}
.list li {
  margin: 0.75em 0;
}
/* List component (Large variant)
*********************************/
.list--large {
  padding: 0;
  list-style: none;
}
.list--large a,
.list--large span {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.2;
}
.list--large svg {
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
}
.list--large li {
  margin: 0;
}
.list--large > li:not(:last-child) {
  border-bottom: dashed 0.0480769231em #c3c8cc;
}

/* List component (Footer variant)
**********************************/
.footer .list {
  margin: 0 0 2em;
  padding: 0;
  list-style: none;
  font-size: 0.9rem;
}
@media screen and (min-width: 62em) {
  .footer .list {
    margin: 0 0 2.5em;
  }
}
.footer .list li {
  margin: 0.5em 0;
}
.footer .list a {
  --color_link: #c3c8cc;
  font-weight: inherit;
}
.footer .list a:hover, .footer .list a:focus {
  --color_link: #fff;
}
.footer .columns .list li:first-child {
  margin: 0 0 -0.25em;
  font-size: 1.2rem;
}

/* List Icon component manifest file
************************************/
/* List Icon component (default)
********************************/
.list-icon {
  padding: 0;
  list-style: none;
}
.list-icon li.icon-item {
  display: flex;
  margin: 0.5em 0;
}
.list-icon .icon {
  margin-right: 0.5em;
  fill: #e22e2c;
}
.list-icon .icon.icon--small, .list-icon .icon.icon--regular {
  margin-top: 0.1875em;
}

/* Text component manifest file
*******************************/
/* Text component (default)
***************************/
.text a[href^="http://"]::after,
.text a[href^="https://"]::after,
.text a[target=_blank]::after {
  content: "";
}
.text a.download {
  display: flex;
  align-items: center;
}
.text a.download::before {
  margin-right: 0.25rem;
  flex-shrink: 0;
  content: "";
}
.text blockquote {
  max-width: 75ch;
  margin-left: 0;
  margin-right: 0;
  padding: 1em 0 1.5em;
  color: inherit;
  font-size: 1.3rem;
  text-align: center;
  border-top: solid 0.0480769231em #e6ebf0;
  border-bottom: solid 0.0480769231em #e6ebf0;
}
@media screen and (min-width: 48em) {
  .text blockquote {
    padding-left: 2.3em;
    padding-right: 2.3em;
  }
}
.text blockquote::before {
  margin-bottom: 0.5em;
  content: "";
}
.text hr {
  margin: 2.5em 0;
  color: #e6ebf0;
}
.text p:first-child {
  margin-top: 0;
}
.text sub,
.text sup {
  font-style: italic;
}
.text ul,
.text ol {
  padding-left: 1em;
}
@media screen and (min-width: 62em) {
  .text > ol,
.text > ul {
    padding-left: 2.5em;
  }
}
.text li {
  margin: 1em 0;
}
.text .paragraph--small {
  font-size: 0.8rem;
}

/* Text component (Read More variation)
***************************************/
.border-highlight .text blockquote,
.readmore .text blockquote {
  font-size: 1rem;
}
.border-highlight .text > ol,
.border-highlight .text > ul,
.readmore .text > ol,
.readmore .text > ul {
  padding-left: 1em;
}

/* Text component (Columns variation)
*************************************/
.text.column-item *:first-child {
  margin-top: 0;
}
.text.column-item *:last-child {
  margin-bottom: 0;
}

.text.column-item + .text.column-item {
  margin-top: -0.5em;
}
@media screen and (min-width: 62em) {
  .text.column-item + .text.column-item {
    margin-top: inherit;
  }
}

/* Text component (Sub-Navigation variation)
********************************************/
.subnav .text *:last-child {
  margin-bottom: 0;
}
.subnav .text a:only-child {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}
.subnav .text a:only-child::after {
  content: "";
}

/* Text component manifest file
*******************************/
/* Title component (default)
****************************/
.heading--linked a {
  display: block;
  font-weight: inherit;
}
.heading--linked svg {
  display: inline-block;
  top: -0.0625rem;
  width: 1.8rem;
  height: 1.8rem;
}

.title-lead p:first-child {
  margin-top: 0;
}

/* Title component (fade variation)
***********************************/
[class|=fade] > .title {
  --color_heading: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 9.875em;
  padding: 1.125em;
  background-image: linear-gradient(180deg, #a02b35, #144266);
}
@media screen and (min-width: 30em) {
  [class|=fade] > .title {
    min-height: 11.25em;
    padding: 2em;
  }
}
@media screen and (min-width: 62em) {
  [class|=fade] > .title {
    min-height: 16.25em;
    padding: 3em;
  }
}
[class|=fade] > .title .heading {
  margin: 0;
  font-size: 2rem;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  [class|=fade] > .title .heading {
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 62em) {
  [class|=fade] > .title .heading {
    font-size: 2.4rem;
  }
}

/* Title component (sub-navigation variation)
*********************************************/
.subnav .title .heading--primary {
  font-size: 1.6rem;
}
@media screen and (min-width: 30em) {
  .subnav .title .heading--primary {
    font-size: 1.9rem;
  }
}
.subnav .title .heading--secondary {
  color: rgba(71, 89, 102, 0.7);
}
@media screen and (min-width: 62em) {
  .subnav .title + .title {
    margin-top: -0.25em;
  }
}
@media screen and (min-width: 62em) {
  .subnav .group.column-item .title:first-child h3.heading--secondary {
    margin-top: 0.25em;
  }
}

/* Title component (Case Study Template)
****************************************/
@media screen and (min-width: 48em) {
  .tmpl-casestudy .header-logo + .title .heading--primary {
    font-size: 3rem;
  }
}
@media screen and (min-width: 62em) {
  .tmpl-casestudy .header-logo + .title .heading--primary {
    font-size: 3.4rem;
  }
}
.tmpl-casestudy .border-highlight .heading--primary,
.tmpl-casestudy .border-highlight .text h1,
.tmpl-casestudy .border-highlight .text h2 {
  font-size: 1.9rem;
}
@media screen and (min-width: 30em) {
  .tmpl-casestudy .border-highlight .heading--primary,
.tmpl-casestudy .border-highlight .text h1,
.tmpl-casestudy .border-highlight .text h2 {
    font-size: 2rem;
  }
}

/* Card component manifest file
*******************************/
/* Card component (default)
***************************/
.card {
  display: flex;
  flex-direction: column;
}
.card-inset {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}
@media screen and (min-width: 62em) {
  .card-inset.card--horizontal {
    flex-direction: row;
  }
}

.card-image {
  flex-shrink: 0;
  overflow: hidden;
}
.card-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5em;
}
@media screen and (min-width: 30em) {
  .card-text {
    padding: 2em;
  }
}
@media screen and (min-width: 62em) {
  .panel.panel--zero .card-text {
    padding: 3em;
  }
}

.card-cta {
  display: flex;
  margin-top: auto;
  margin-bottom: 0;
}

/* Card component (Contact details variation)
*********************************************/
.card--contact.card a {
  display: flex;
  align-items: center;
}
.card--contact address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 62em) {
  .card--contact .card-image {
    height: 9em;
  }
}
.card--contact .p-name {
  font-weight: 500;
}
.card--contact .icon {
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
}
.card--contact .postal-address p {
  margin: 0;
}
.card--contact .contact-details {
  margin-top: 0.75em;
}
.card--contact .contact-details p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.1875em 0;
}
.card--contact .contact-details .description {
  margin-left: 0.5em;
  font-size: 0.8rem;
}

/* Card component (Icon/Text variation)
****************************************/
.card--icontext .card-inset {
  flex-direction: column;
}
@media screen and (min-width: 30em) {
  .card--icontext .card-inset {
    flex-direction: row;
  }
}
@media screen and (min-width: 30em) {
  .card--icontext .card-inset.card--vertical {
    flex-direction: column;
  }
}
.card--icontext .card--fadein {
  animation: fadeIn 1s ease forwards;
}
@media screen and (min-width: 62em) {
  .card--icontext .card--fadein {
    animation-delay: var(--animation_delay, 0);
  }
}
.card--icontext .card-image {
  position: relative;
  display: flex;
  width: 3.75em;
  height: 3.75em;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 5px 5px 20px 0 rgba(71, 89, 102, 0.15);
}
@media screen and (min-width: 30em) {
  .card--icontext .card-image {
    width: 4.375em;
    height: 4.375em;
  }
}
.card--icontext .card-image > img {
  width: 1.5em;
  height: 1.5em;
  margin: auto;
}
.card--icontext .card-text {
  padding: 1em 0 0;
}
@media screen and (min-width: 30em) {
  .card--icontext .card-text {
    padding: 0 0 0 1.5em;
  }
}
@media screen and (min-width: 30em) {
  .card--icontext .card--vertical .card-text {
    padding: 1em 0 0;
  }
}
.card--icontext .card-description > *:last-child {
  margin-bottom: 0;
}

/* Card component (Profile variation)
*************************************/
aside.aside .card--profile .card-text, .card--profile .card-text {
  width: 100%;
  margin-top: 0.75em;
  padding: 0;
  text-align: center;
}

.card--profile .card-inset {
  align-items: center;
  padding: 1.5em;
  color: var(--color_heading);
}
@media screen and (min-width: 62em) {
  .card--profile .card-inset {
    padding: 2em;
  }
}
@media screen and (min-width: 30em) and (max-width: 61.9375em) {
  .card--profile .card-inset {
    flex-direction: row;
  }
}
.card--profile .card-image {
  position: relative;
  flex-shrink: 1;
  overflow: visible;
}
.card--profile .card-image img {
  max-width: 6.25em;
  border-radius: 50%;
}
.card--profile .card-image button {
  position: absolute;
  bottom: 0;
  right: 0;
}
.card--profile .card-image svg {
  position: relative;
  width: 1.25em;
  height: 1.25em;
  left: 0.0625em;
}
@media screen and (min-width: 30em) {
  .card--profile .card-text {
    padding-left: 0.75em;
  }
}
@media screen and (min-width: 48em) {
  .card--profile .card-text {
    padding-left: 1.5em;
  }
}
@media screen and (min-width: 30em) and (max-width: 61.9375em) {
  .card--profile .card-text {
    margin-top: 0;
    text-align: initial;
  }
}
@media screen and (min-width: 62em) {
  .card--profile .card-text {
    padding-left: initial;
  }
}
.card--profile .card-text h2 + p {
  margin: -0.75rem 0 0;
  font-weight: 500;
}
@media screen and (min-width: 30em) {
  .card--profile .heading--cardprofile {
    font-size: 1.6rem;
    font-weight: 300;
  }
}

aside.aside .card--profile .card-inset {
  flex-direction: column;
  padding: 2em;
}
/* Card component (Tags variation)
**********************************/
.card--tags ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.card--tags .tag {
  display: block;
  margin: 0.25em;
  padding: 0.5em 1em;
  font-size: 0.8rem;
  background-color: #fff;
  border: solid 0.0625rem #c3c8cc;
}
.card--tags .card-text {
  justify-content: center;
  text-align: center;
}

/* Card component (Text/Image variation)
****************************************/
.card--textimage .card--horizontal .card-image.image--after {
  order: 1;
}
.card--textimage .card--horizontal .card-text {
  margin: auto;
}
@media screen and (min-width: 62em) {
  .card--textimage .card--horizontal .card-image {
    width: 30%;
    height: auto;
  }
  .card--textimage .card--horizontal .card-image.image--fiftypc {
    width: 50%;
  }
}

/* Case Study Teaser component manifest file
********************************************/
/* Case Study Teaser component (default)
****************************************/
.casestudy {
  filter: contrast(100%) brightness(95%);
}
.casestudy:hover, .casestudy:focus-within {
  filter: contrast(110%) brightness(105%);
}

.casestudy-cta {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5em;
}

/* Infogram component manifest file
***********************************/
/* Infogram component (default)
*******************************/
.infogram {
  margin-left: 0;
  margin-right: 0;
  padding: 1.5em;
  color: var(--color_scheme_text, #fff);
  background-color: var(--color_scheme_background, #0f2d52);
}
@media screen and (min-width: 48em) {
  .infogram {
    padding: 2em;
  }
}
.infogram.infogram--nopadding {
  padding: 0;
}
.infogram figcaption {
  text-align: initial;
  font-size: 0.8rem;
}
.infogram .heading {
  margin-bottom: 1em;
}

/* Infogram component (Columns variation)
*****************************************/
.infogram-container.column-item {
  display: flex;
}
.infogram-container.column-item .infogram {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

/* Insight Teaser component manifest file
*****************************************/
/* Insight Teaser component (default)
*************************************/
.insight-image img {
  transition: transform 0.4s ease-in-out;
}
.insight:hover .insight-image img, .insight:focus-within .insight-image img {
  transform: scale(1.08);
}

.insight-text {
  padding: 1.5em;
}
@media screen and (min-width: 62em) {
  .insight-text {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.insight-details ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.5em;
  padding: 0;
  list-style: none;
  font-size: 0.8rem;
}
.insight-details li {
  display: flex;
  align-items: center;
  margin: 0.25em 0.5em;
}
.insight-details svg {
  position: relative;
  top: -0.0625rem;
  width: 1.1rem;
  margin-right: 0.25em;
}

/* Insight Teaser component (hub variation)
*******************************************/
@media screen and (min-width: 62em) {
  .insight-type.hub .insight-description {
    display: block;
  }
}
.insight-type.hub .insight-details {
  padding-top: 1.5em;
  color: #e6ebf0;
}

/* Insight Teaser component (simple variation)
**********************************************/
.insight-type.simple {
  background-color: #e6ebf0;
}
.insight-type.simple .insight-image {
  height: 12.25em;
}
.insight-type.simple .insight-text {
  justify-content: space-between;
}
.insight-type.simple .insight-heading {
  font-size: 1.2rem;
}
.insight-type.simple .insight-link {
  color: var(--color_link);
}
.insight-type.simple .insight-details {
  padding-top: 0.75em;
}
.insight-type.simple .insight-details svg {
  color: #abafb2;
}

/* Insight Teaser component (sub-navigation variation)
******************************************************/
.subnav .insight-type.hub {
  min-height: 0;
}
.subnav .insight-type.hub .insight-description {
  display: none;
}
.subnav .insight-type.simple {
  margin: 0.5em 0;
}
.subnav .insight-type.simple .insight-text {
  padding: 0;
}
.subnav .insight-type.simple .insight-details {
  padding-top: 0.5em;
}

/* Read More component manifest file
************************************/
/* Read More component (default)
********************************/
.readmore {
  position: relative;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}

.readmore-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
}
.readmore-image::after {
  background-color: rgba(0, 42, 75, 0.8);
}
@media screen and (min-width: 62em) {
  .readmore-image::after {
    background-color: transparent;
    background-image: linear-gradient(90deg, #002a4b 48%, rgba(0, 42, 75, 0) 90%);
  }
}
.readmore-image img {
  object-position: top right;
}

.readmore-text {
  position: relative;
  padding: 1.5em;
}
@media screen and (min-width: 30em) {
  .readmore-text {
    padding: 2em;
  }
}
@media screen and (min-width: 62em) {
  .readmore-text {
    max-width: 50%;
  }
}
.readmore-text .readmore-cta {
  margin-bottom: 0;
}

.readmore-preview {
  position: relative;
}
@media screen and (min-width: 62em) {
  .js .readmore-preview.fadeout::after {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 6.25em;
    background-image: linear-gradient(rgba(0, 42, 75, 0), #002a4b);
    content: "";
  }
}

/* Sitemap component manifest file
**********************************/
/* Sitemap component (default)
******************************/
.sitemap-group {
  margin: 0;
  padding: 0 1.5em;
}
.sitemap-group:first-child {
  display: grid;
  gap: 1.5em;
  margin: 0;
}
@media screen and (min-width: 48em) {
  .sitemap-group:first-child {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 80em) {
  .sitemap-group:first-child {
    grid-template-columns: repeat(3, 1fr);
  }
}
.sitemap-group:first-child .sitemap-level--0 {
  font-size: 1.3rem;
}
@media screen and (min-width: 30em) {
  .sitemap-group:first-child .sitemap-level--0 {
    font-size: 1.5rem;
  }
}
.sitemap-group:first-child .sitemap-level--1 {
  font-size: initial;
}

/* Social Share component manifest file
***************************************/
/* Social Share component (default)
***********************************/
.socialshare ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.socialshare li + li {
  border-top: dashed 0.0625em #c3c8cc;
}
.socialshare a {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
}
.socialshare .icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
.socialshare .icon.icon--linkedin {
  padding: 0.125em;
  fill: #fff;
  background-color: currentColor;
  border-radius: 0.125em;
}

/* Table component manifest file
********************************/
/* Table component (default)
****************************/
.table {
  color: #002a4b;
  background-color: #fff;
  overflow: hidden;
}
.table p {
  margin-top: 0;
}

table,
.row-group {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.row-group dl {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.row-group dt,
.row-group dd {
  flex: 0 0 50%;
  margin: 0;
}

.row-heading {
  margin-bottom: 0;
  padding: 0.5rem 0;
}

.row-group-data > * {
  padding: 0.5rem 0;
}

caption {
  text-align: initial;
}

th,
.row-heading,
.row-group dt {
  font-weight: 500;
}

th,
td {
  padding: 1rem 1.5rem;
  border: solid 0.0625rem #c3c8cc;
  text-align: initial;
  line-height: 1.5;
}
.table--nowrap th,
.table--nowrap td {
  white-space: nowrap;
}

th[scope=col],
.row-heading {
  border-bottom: solid 0.125rem #e22e2c;
}

.table-overflow {
  margin: 0;
}
.table-overflow[tabindex="0"] {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.table-overflow[tabindex="0"] caption {
  max-width: 98vw;
}
.table-overflow[tabindex="0"] .scroll-notification {
  margin: 0;
}

.table--bestfit .tabular-data {
  display: none;
}
@media screen and (min-width: 48em) {
  .table--bestfit .tabular-data {
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .table--bestfit .list-data {
    display: none;
  }
}

/* Table component (Regular variant)
************************************/
.table--regular tbody {
  background-color: #f2f5f7;
}
.table--regular th[scope=col],
.table--regular .row-heading {
  font-size: 1.2rem;
}

.table--fssa {
  margin-bottom: 15px;
  border-bottom: solid 3px #e22d2c;
}
.table--fssa th, .table--fssa td {
  padding: 5px 7px;
  border-bottom: solid 0.5px #000;
  border-left: none;
  border-right: none;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 400;
}
.table--fssa th {
  background-color: #0f2d52;
  color: white;
}
@media (min-width: 700px) and (max-width: 1000px) {
  .table--fssa table {
    width: 100%;
  }
}
@media (min-width: 0) and (max-width: 700px) {
  .table--fssa table {
    display: block;
    overflow-x: auto;
  }
}

/* Table component (Data variant)
*********************************/
.table--data {
  font-size: 0.9rem;
}
.table--data .row-heading {
  padding: 0.75rem 0;
  border: 0;
  border-bottom: solid 0.125rem #e22e2c;
}
.table--data .row-group-data > * {
  border-bottom: solid 0.0625rem #c3c8cc;
}
.table--data td,
.table--data th[scope=col]:not(:first-child) {
  text-align: center;
}
.table--data td,
.table--data th {
  border: 0;
  border-bottom: solid 0.0625rem #c3c8cc;
}
.table--data th[scope=col] {
  font-size: 0.75rem;
  font-weight: 600;
  border-bottom-color: #e22e2c;
  border-width: 0.125rem;
  vertical-align: bottom;
}
.table--data th,
.table--data td,
.table--data .row-group-data > * {
  padding: 0.75rem 0.375rem;
}
.table--data th[scope=row],
.table--data th.group-name,
.table--data thead th:first-child,
.table--data .row-group-data dt {
  padding-left: 0;
}
.table--data th.group-name {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.table--data td:last-child,
.table--data thead th:last-child,
.table--data .row-group-data dd {
  padding-right: 0;
}

.data-form caption .heading {
  font-size: 1rem;
  text-transform: uppercase;
}
.data-form .table--data + .table--data {
  margin-top: 1.5em;
}

/* Teaser component manifest file
*********************************/
/* Teaser component (default)
*****************************/
.teaser {
  color: #fff;
  background-color: #144266;
  filter: contrast(100%) brightness(95%);
}
.teaser:hover, .teaser:focus-within {
  filter: contrast(110%) brightness(105%);
}

.teaser-type {
  min-height: 16.25em;
  flex-grow: 1;
  justify-content: flex-end;
}
@media screen and (min-width: 30em) {
  .teaser-type {
    min-height: 26.25em;
  }
}

.teaser-image {
  position: absolute;
  width: 100%;
  height: 16.25em;
  flex-grow: 1;
}
@media screen and (min-width: 30em) {
  .teaser-image {
    position: relative;
    height: 20em;
  }
}

.teaser-text {
  position: static;
  display: grid;
  grid-template-columns: 1fr 2.625em;
  grid-template-rows: 1fr;
  /* prettier-ignore */
  grid-template-areas: "title       icon" "description icon";
  align-items: end;
  gap: 0 0.5em;
  flex-grow: 0;
}
@media screen and (min-width: 62em) {
  .teaser-text {
    margin-right: -1em;
    word-wrap: anywhere;
  }
}

.teaser-title {
  grid-area: title;
}

.teaser-description {
  grid-area: description;
}
@media screen and (min-width: 30em) {
  .teaser-description {
    display: block;
  }
}

.teaser-cta {
  position: relative;
  margin: 0;
  grid-area: icon;
  justify-self: right;
  background-color: transparent;
}
@media screen and (min-width: 62em) {
  .teaser:hover .teaser-cta, .teaser:focus-within .teaser-cta {
    transform: translateX(0.5em);
  }
}

/* Teaser component (block variant)
***********************************/
.teaser-type--block {
  min-height: 0;
  justify-content: center;
}
.teaser-type--block .teaser-image {
  position: absolute;
  height: auto;
  inset: 0;
}
.teaser-type--block .teaser-image::after {
  background-image: linear-gradient(45deg, #144266, rgba(20, 66, 102, 0));
}
.teaser-type--block .teaser-text {
  position: relative;
  margin-right: 0;
  padding: 1.5em 2em;
  flex-grow: 0;
}
.teaser-type--block .teaser-title {
  align-self: center;
}
.teaser-type--block .teaser-heading {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.02em;
}
.teaser-type--block .teaser-description {
  font-size: 0.9rem;
  line-height: 1.6;
}
.teaser-type--block .teaser-description p:first-child {
  margin-top: 0.28em;
}

/* Teaser component (sub-navigation variant)
********************************************/
.subnav .teaser-type,
.subnav .teaser-image {
  min-height: 0;
}
.subnav .teaser-heading.heading--secondary {
  font-size: 1.3rem;
}

.subnav .teaser-type--default .teaser-image {
  position: absolute;
  height: 100%;
}
.subnav .teaser-type--default .teaser-image::after {
  background-image: linear-gradient(90deg, #144266 25%, rgba(20, 66, 102, 0));
}
@media screen and (min-width: 62em) {
  .subnav .teaser-type--default .teaser-image::after {
    background-image: linear-gradient(rgba(20, 66, 102, 0), #144266);
  }
}
.subnav .teaser-type--default .teaser-text {
  margin: 0;
  padding: 1em;
}
@media screen and (min-width: 62em) {
  .subnav .teaser-type--default .teaser-text {
    min-height: 18.75em;
    padding: 2em 1em;
  }
}
@media screen and (min-width: 80em) {
  .subnav .teaser-type--default .teaser-text {
    padding: 2em;
  }
}
.subnav .teaser-type--default .teaser-title {
  align-self: center;
}
@media screen and (min-width: 62em) {
  .subnav .teaser-type--default .teaser-title {
    align-self: end;
    margin-bottom: 0.5em;
  }
}
.subnav .teaser-type--default .teaser-description {
  display: none;
}

.subnav .teaser-type--block {
  display: none;
}
@media screen and (min-width: 30em) {
  .subnav .teaser-type--block {
    display: flex;
  }
}

/* Video component manifest file
********************************/
/* Video component (default)
****************************/
.video-container {
  display: flex;
}

.video {
  --video_bg: #f2f5f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  color: #002a4b;
  background-color: var(--video_bg);
}
.video .video-type {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  z-index: 0;
}
.panel .panel .video {
  box-shadow: 1px 1px 50px 0 rgba(71, 89, 102, 0.29);
}
.scheme--light-gray .video {
  --video_bg: #fff;
}

.js .video-responsive > .ratio-16x9 {
  padding-top: 56.25%;
}
.js .video-responsive > .ratio-4x3 {
  padding-top: 75%;
}
.video-responsive iframe {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* search-results component (default)
*************************************/
.search-results-container > *:not(.searched-term),
.search-results-container .searched-term .constrain-width {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
.search-results-container .no-results {
  padding: 2em;
  color: #77787b;
  font-size: 1.375rem;
  text-align: center;
}

.searched-term {
  padding: 1.25em 0;
  background-color: #f2f5f7;
}
.searched-term h2 {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 1.375rem;
  font-weight: 600;
  border: none;
}
.searched-term p {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 300;
  padding-left: 20px;
}

.bse-card__content {
  display: grid;
  align-items: center;
  padding: 20px;
}

/* Form (common) manifest file
******************************/
/* Form common/partial styles (default)
***************************************/
form [aria-live].error > p {
  margin: 0.25em 0;
  padding: 0.25em 1em;
  color: #fff;
  background-color: #e22e2c;
  font-size: 0.9rem;
  font-weight: 500;
}

.styled-select {
  position: relative;
  display: inline-block;
}
.styled-select select {
  appearance: none;
}
.styled-select select:focus {
  background-color: #e6ebf0;
}
.styled-select svg {
  position: absolute;
  top: calc(50% - 0.75em);
  right: 1em;
  transform: rotate(90deg);
}

/* Form multi-select partial styles (default)
*********************************************/
.multi-selector, .multi-selector-date-filter {
  display: grid;
  grid-template-rows: auto;
  gap: 0.75em;
}
@media screen and (min-width: 48em) {
  .multi-selector, .multi-selector-date-filter {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
  }
}
.multi-selector legend, .multi-selector-date-filter legend {
  width: 100%;
  grid-column: -1/1;
}
.multi-selector p, .multi-selector-date-filter p {
  margin: 0;
}
.multi-selector .styled-select, .multi-selector-date-filter .styled-select {
  display: block;
}

@media screen and (min-width: 30em) {
  .panel.panel--multiselector {
    padding: 2em;
  }
}
.panel.panel--multiselector.panel--marginbottom {
  margin-bottom: 2em;
}
@media screen and (min-width: 62em) {
  .panel.panel--multiselector.panel--marginbottom {
    margin-bottom: 3em;
  }
}

.date-filters {
  display: grid;
  margin-bottom: 1em;
}
.date-filters .multi-selector, .date-filters .multi-selector-date-filter {
  gap: 0.75em;
  margin: 0.75em 0;
}
.date-filters .multi-selector .legend, .date-filters .multi-selector-date-filter .legend {
  grid-column: auto;
  align-self: center;
  margin-bottom: 0;
  float: left;
}
@media screen and (min-width: 62em) {
  .date-filters p.cta {
    align-self: end;
    margin-left: 0;
    margin-bottom: 0.375em;
  }
}

/* Dividends Form component manifest file
*****************************************/
/* Dividends Form component (default)
*************************************/
.dividends-form .panel-inset {
  margin: -2em -1.5em;
}
@media screen and (min-width: 30em) {
  .dividends-form .panel-inset {
    margin-left: -2em;
    margin-right: -2em;
  }
}
@media screen and (min-width: 62em) {
  .dividends-form .panel-inset {
    margin: 0;
  }
}
.dividends-form .table--data {
  padding: 1.5em;
}
.dividends-form .table--data th[scope=col],
.dividends-form .table--data .row-group dt {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.dividends-form .table--data th[scope=col],
.dividends-form .table--data td {
  text-align: initial;
}
.dividends-form .table--data .row-heading {
  padding-top: 0;
  padding-bottom: 0.75em;
  font-size: 1rem;
}
.dividends-form .table--sparse th[scope=row] {
  width: 33%;
  white-space: normal;
}
.dividends-form .table--dense th[scope=col] {
  min-width: 12.5em;
  white-space: normal;
}
.dividends-form .table--dense th[scope=row] {
  min-width: 18em;
  white-space: normal;
}

.date-filters.dividends {
  margin-top: 1.2em;
}
@media screen and (min-width: 62em) {
  .date-filters.dividends {
    grid-template-columns: minmax(auto, 2fr) 1fr;
    gap: 1.5em;
  }
}
.date-filters.dividends legend.heading--tertiary {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .date-filters.dividends legend.heading--tertiary {
    grid-column: -1/1;
  }
}
.date-filters.dividends legend.heading--small {
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (min-width: 48em) {
  .date-filters.dividends > fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .date-filters.dividends .multi-selector {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 62em) {
  .date-filters.dividends .multi-selector {
    grid-template-columns: minmax(auto, max-content) 1fr;
  }
}

/* Documents/Factsheets Form component manifest file
****************************************************/
/* Documents/Factsheets Form component (default)
************************************************/
.documentsfactsheets-form .filter-spacing {
  margin-bottom: 1.5em;
}
@media screen and (min-width: 30em) {
  .documentsfactsheets-form .filter-spacing {
    margin-bottom: 2em;
  }
}
.documentsfactsheets-form .show-filter .multi-selector {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.75em;
}
.documentsfactsheets-form .show-filter .multi-selector legend {
  width: auto;
  margin-bottom: 0;
  float: left;
}
.documentsfactsheets-form .show-filter .multi-selector p {
  flex-grow: 1;
}
@media screen and (min-width: 48em) {
  .documentsfactsheets-form .show-filter .multi-selector p {
    flex-grow: 0;
  }
}
.documentsfactsheets-form .accordion-content {
  padding-bottom: 1.5em;
}
.documentsfactsheets-form .documents-list {
  border-top: solid 0.0625em #c3c8cc;
}
@media screen and (min-width: 30em) {
  .documentsfactsheets-form .documents-list {
    padding: 1em 1.5em;
    background-color: #f2f5f7;
    border: 0;
  }
}
.documentsfactsheets-form .documents-list a {
  display: flex;
  font-weight: 400;
}
.documentsfactsheets-form .documents-list svg.icon {
  position: relative;
  top: 0.125em;
  margin-right: 0.25em;
}
.documentsfactsheets-form .documents-list .document-item {
  margin: 0;
  padding: 0.375em 0;
  border-top: solid 0.0625rem #c3c8cc;
}
.documentsfactsheets-form .documents-list .document-item:not([hidden]) + .document-item:not([hidden]) {
  border-top: solid 0.0625rem #c3c8cc;
}
.documentsfactsheets-form .documents-list .document-item--heading {
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
  color: #002a4b;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.documentsfactsheets-form .documents-list .document-item--heading:not(:first-child) {
  margin-top: 1rem;
}
.documentsfactsheets-form .documents-list table {
  display: flex;
  border: none;
}
.documentsfactsheets-form .documents-list td {
  border: none;
  padding: 0 0 0 5px;
}
.documentsfactsheets-form .documents-list a.nameRed {
  padding: 0 0 0 8px;
}

/* Price/Performance Form component manifest file
*************************************************/
/* Price/Performance Form component (default)
*********************************************/
.priceperformance-form .table--data th[scope=row] {
  width: 25%;
  min-width: 12.5em;
  white-space: normal;
}

@media screen and (min-width: 62em) {
  .date-filters.price {
    grid-template-columns: 26.25em 1fr;
  }
}
.date-filters.price select,
.date-filters.price button.btn {
  font-size: 0.9rem;
}
@media screen and (min-width: 48em) {
  .date-filters.price .multi-selector, .date-filters.price .multi-selector-date-filter {
    grid-template-columns: 3.125em repeat(auto-fit, minmax(6.25em, max-content));
  }
}
.date-filters.price .multi-selector + .multi-selector, .date-filters.price .multi-selector .multi-selector-date-filter, .date-filters.price .multi-selector-date-filter + .multi-selector, .date-filters.price .multi-selector-date-filter .multi-selector-date-filter {
  margin-top: 1.5em;
}
@media screen and (min-width: 48em) {
  .date-filters.price .multi-selector + .multi-selector, .date-filters.price .multi-selector .multi-selector-date-filter, .date-filters.price .multi-selector-date-filter + .multi-selector, .date-filters.price .multi-selector-date-filter .multi-selector-date-filter {
    margin-top: 0;
  }
}
@media screen and (min-width: 48em) {
  .date-filters.price p.cta {
    margin-top: -0.5em;
    margin-left: 3.4375em;
  }
}

/* Strategy Filter component manifest file
******************************************/
/* Strategy Filter component (default)
**************************************/
.no-article-found-message {
  color: #e22e2c;
  border: 0.1em solid #e22e2c;
  padding: 1em 1em;
  text-align: center;
  font-size: 1.125rem;
}

.strategy-form .filter-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em 2em;
  align-items: center;
}
.strategy-form .filter-row > * {
  grid-column: -1/1;
}
@media screen and (min-width: 62em) {
  .strategy-form .filter-row > * {
    grid-column: initial;
  }
}
.strategy-form .filter-row p {
  display: flex;
  align-items: center;
  margin: 0;
}
.strategy-form .filter-row .heading {
  margin: 0;
}
.strategy-form label.heading,
.strategy-form button.btn-submit {
  flex-shrink: 0;
}
.strategy-form button.btn-submit,
.strategy-form .styled-select {
  margin-left: 0.5em;
  flex-grow: 1;
}
.strategy-form .results-message {
  text-align: center;
  font-weight: 400;
}

/* Strategy Filter component (pagination)
*****************************************/
nav.pagination ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
nav.pagination li {
  margin: 0.125em;
  font-weight: 600;
}
nav.pagination a:hover,
nav.pagination a:focus {
  --color_link: #3699c9;
}
nav.pagination span.page {
  opacity: 0.3;
}
nav.pagination .icon.icon--chevron {
  top: 0;
}
nav.pagination .icon.previous {
  transform: rotate(180deg);
}

/* Accordion component manifest file
************************************/
/* Accordion API (default)
**************************/
button[data-accordion-trigger] {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
button[data-accordion-trigger] svg {
  width: 1em;
  height: 1em;
  order: 1;
}
button[data-accordion-trigger] svg.icon {
  width: 1.75rem;
  height: 1.75rem;
  transform-box: fill-box;
  transform-origin: center;
  transition: transform 0.4s ease;
}
button[data-accordion-trigger][aria-expanded=false] .icon--chevron {
  transform: rotate(90deg);
}
button[data-accordion-trigger][aria-expanded=true] .icon--chevron {
  transform: rotate(270deg);
}

[data-accordion-target] {
  transition: all 0.3s ease-in-out;
}

/* Accordion (component-specific styles)
****************************************/
.accordion-panel {
  margin-bottom: 0.75em;
  padding: 0 1em;
  border: solid 0.0625em #e6ebf0;
  color: var(--color_scheme_text, #475966);
  background-color: var(--color_scheme_background, #f2f5f7);
  transition: background-color 0.3s ease;
}
@media screen and (min-width: 30em) {
  .accordion-panel {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
.accordion-panel:last-child {
  margin-bottom: 0;
}
.accordion-panel[data-switch][data-panel=open] {
  --color_scheme_background: #fff;
  --color_scheme_text: #475966;
}

.accordion-heading {
  margin: 0;
  padding: 0.75em 0;
}
@media screen and (min-width: 30em) {
  .accordion-heading {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
.accordion-heading button:hover, .accordion-heading button:focus {
  text-decoration: underline;
}
.accordion-heading button[data-accordion-trigger] svg {
  fill: #0f2d52;
}

.accordion-content {
  padding-bottom: 1em;
}

/* Aside component manifest file
********************************/
/* Aside component (default)
****************************/
/* Background component manifest file
*************************************/
/* Background component (default)
*********************************/
.background--fullpage {
  position: fixed;
  inset: 0;
}
.background--fullpage .background-item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  filter: blur(0.08em);
}
.background--fullpage video {
  object-fit: cover;
}
.background--fullpage .background-image {
  background: var(--background_image) 50% 50% no-repeat;
  background-size: cover;
}

/* Border component manifest file
*********************************/
/* Border component (default)
*****************************/
.border-highlight {
  margin: 1em 0;
  padding-left: 2em;
  border: 0;
  border-left: 0.25em solid;
  border-image: linear-gradient(#e22e2c, #0f2d52) 10;
}
@media screen and (min-width: 62em) {
  .border-highlight .text h1, .text .border-highlight h1,
.border-highlight .text h2,
.text .border-highlight h2,
.border-highlight .heading--primary {
    font-size: 2.7rem;
  }
}

.panel > .border-highlight:first-child {
  margin-top: 0;
}

/* Fade component manifest file
*******************************/
/* Fade component (default)
***************************/
.fade-container {
  --step-depth: 5em;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 62em) {
  .columns-container.vertical-flow .fade-container {
    margin-top: -1.5em;
    margin-bottom: -1.5em;
  }
}

.fade {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5em;
}
@media screen and (min-width: 62em) {
  .fade::before, .fade::after {
    display: block;
    width: 100%;
    height: var(--step-depth);
    content: "";
  }
}
.fade::before {
  background-image: linear-gradient(0deg, rgba(0, 42, 75, 0.3), rgba(0, 42, 75, 0) 77%);
}
.fade::after {
  flex-grow: 1;
  background-image: linear-gradient(180deg, rgba(0, 42, 75, 0.3), rgba(0, 42, 75, 0) 77%);
}

.columns > .fade-container:nth-child(odd) .fade:not([style])::before {
  --step-depth: 10em;
}

/* Group component manifest file
********************************/
/* Group component (default)
****************************/
section.group {
  overflow: hidden;
}

@media screen and (min-width: 62em) {
  .group .title + .vertical-flow--medium {
    margin-top: 1em;
  }
}

/* Group component (Columns variation)
**************************************/
.columns.padding--counter-horizontal .group {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
@media screen and (min-width: 30em) {
  .columns.padding--counter-horizontal .group {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media screen and (min-width: 62em) {
  .columns.padding--counter-horizontal .group {
    padding-left: 3em;
    padding-right: 3em;
  }
}
.columns.padding--counter-vertical .group {
  padding-top: 2em;
}
@media screen and (min-width: 62em) {
  .columns.padding--counter-vertical .group {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

/* Jump Links Navigation component manifest file
************************************************/
/* Jump Links Navigation component (default)
********************************************/
.jumplinks-container {
  position: sticky;
  top: 0;
  z-index: 50;
}

.jumplinks {
  position: relative;
  display: grid;
  grid-template-columns: 1fr minmax(2.25em, auto);
  gap: 1em;
  align-items: center;
  padding: 1em 2em;
  background-color: #f2f5f7;
  border-bottom: solid 0.0625em #e6ebf0;
}
@media screen and (min-width: 30em) {
  .jumplinks {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media screen and (min-width: 62em) {
  .jumplinks {
    padding-left: 3em;
    padding-right: 3em;
  }
}
.jumplinks h2,
.jumplinks p.back-to-top {
  margin: 0;
}
.jumplinks h2 {
  margin-right: 0.5em;
  color: #002a4b;
  font-size: 1rem;
  font-weight: 300;
}
.jumplinks h2::after {
  content: ":";
}
.jumplinks p.back-to-top {
  margin-left: auto;
}

.jumplinks-list {
  display: flex;
  align-items: center;
}
.jumplinks-list ol {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
}
.jumplinks-list li {
  margin: 0.25em;
  flex-shrink: 0;
}
.jumplinks-list a.btn {
  padding: 0.555em 1.111em;
  font-size: 0.9rem;
  border-color: #e6ebf0;
  transition: opacity 0.3s ease;
}
.jumplinks-list a.btn svg.icon {
  width: 1.2em;
  transform: rotate(90deg);
}
.jumplinks-list .capture-overflow {
  flex-grow: 1;
  overflow: hidden;
}

/* Footer component manifest file
*********************************/
/* Footer component (default)
*****************************/
.footer svg,
.footer a svg {
  fill: #fff;
}

.footer-inset {
  padding: 2em;
  color: #c3c8cc;
  background-color: #0f2d52;
}

.footer-layout .list-container.vertical-flow {
  margin-top: 0;
  margin-bottom: 0;
}

/* Footer/copyright component manifest file
*******************************************/
/* Footer/copyright component (default)
***************************************/
.footer-copyright {
  display: flex;
  flex-flow: column wrap;
  margin-top: 0.75em;
  font-size: 0.8rem;
  font-weight: 500;
}
@media screen and (min-width: 62em) {
  .footer-copyright {
    flex-direction: row;
    padding-top: 1em;
    border-top: solid 0.0625em rgba(195, 200, 204, 0.2);
  }
}
.footer-copyright p,
.footer-copyright li {
  margin: 0.5em 0;
}
.footer-copyright .copyright {
  width: 100%;
}
@media screen and (min-width: 62em) {
  .footer-copyright .copyright {
    width: auto;
    margin-right: 2em;
  }
}

.footer-copyright-list.list {
  display: flex;
  flex-flow: column wrap;
  margin: 0;
  font-size: inherit;
}
@media screen and (min-width: 48em) {
  .footer-copyright-list.list {
    flex-direction: row;
  }
}
@media screen and (min-width: 48em) {
  .footer-copyright-list.list li + li {
    margin-left: 1em;
    padding-left: 1em;
    border-left: solid 0.0625em rgba(195, 200, 204, 0.2);
  }
}

/* Footer/logos component manifest file
****************************************/
/* Footer/logos component (default)
***********************************/
.footer-logos p {
  margin-top: 3rem;
}
.footer-logos .logo--fssa {
  width: 10.75em;
  height: 6.5em;
}
.footer-logos .logo--pri {
  width: 10.75em;
  height: 4.625em;
}
.footer-logos .logo--manan {
  width: 10.75em;
  height: 6.5em;
  margin-left: -34px;
  margin-bottom: 3rem;
}
.footer-logos .logo--bcorp {
  height: 9.375em;
  width: 5.6875em;
  margin-left: -12px;
}

.footer-logo-manan .logo-subscript {
  display: block;
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: normal;
  margin-bottom: 1.125rem;
  margin-top: 3rem;
}
html[lang^=zh] .footer-logo-manan .logo-subscript {
  font-size: 1.125rem;
}

.footer-logo-pri {
  margin-block-end: 0em;
}

.footer-logo-fssa {
  margin-top: 0 !important;
}
.footer-logo-fssa .logo-lines {
  display: block;
  height: 3.875em;
  overflow: hidden;
}
.footer-logo-fssa .logo-lines--two {
  height: 6.5em;
}
.footer-logo-fssa .logo-subscript {
  display: block;
  margin: 1.125rem 0 3rem;
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: normal;
}
html[lang^=zh] .footer-logo-fssa .logo-subscript {
  font-size: 1.125rem;
  letter-spacing: 0.22em;
}

/* Footer/social-media component manifest file
**********************************************/
/* Footer/social-media component (default)
******************************************/
.footer-social {
  display: grid;
  gap: 1em;
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: solid 0.0625em rgba(195, 200, 204, 0.2);
}
@media screen and (min-width: 62em) {
  .footer-social {
    gap: 2em;
    grid-template-columns: 1fr minmax(min-content, 16.25em);
    margin-bottom: 3em;
  }
}
.footer-social p {
  margin: 0;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.3;
}
@media screen and (min-width: 48em) {
  .footer-social p {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}
.footer-social ul {
  display: flex;
  margin: auto -0.25em;
  padding: 0;
  list-style: none;
}
@media screen and (min-width: 62em) {
  .footer-social ul {
    justify-content: flex-end;
  }
}
.footer-social ul li {
  margin: 0 0.25em;
}
.footer-social .social-lead a {
  --color_link: #3699c9;
  font-weight: 400;
}
.footer-social .social-lead a:hover, .footer-social .social-lead a:focus {
  --color_link: #fff;
}
.footer-social .social-link {
  --color_link: #fff;
  display: flex;
  width: 2.75em;
  height: 2.75em;
  align-items: center;
  justify-content: center;
  background-color: #144266;
  border: solid 0.0625em #19527f;
  border-radius: 50%;
  transition: background-color 200ms ease;
}
.footer-social .social-link:hover, .footer-social .social-link:focus {
  background-color: #2373b2;
}
.footer-social .social-link svg.icon--linkedin {
  position: relative;
  top: -0.125em;
}

/* Footer postscript component manifest file
********************************************/
/* Footer postscript component (default)
****************************************/
/* Scroll-Sliding Panel component manifest file
***********************************************/
/* Scroll-Sliding Panel component (default)
*******************************************/
.scrollslides {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: count;
  scroll-snap-type: y proximity;
}
.scrollslides li + li {
  margin-top: 0.062em;
}
@media screen and (min-width: 62em) {
  .scrollslides li + li {
    margin-top: 0;
    border-top: solid 0.0625em #133968;
  }
}
.scrollslides .heading--secondary {
  font-size: 1.5rem;
  font-weight: 400;
}
@media screen and (min-width: 62em) {
  .scrollslides .heading--secondary {
    font-size: 2.4rem;
    font-weight: 300;
  }
}

.scrollslide {
  position: relative;
  display: flex;
  flex-direction: column;
  scroll-snap-align: top;
}
@media screen and (min-width: 62em) and (prefers-reduced-motion: no-preference) {
  .scrollslide {
    position: sticky;
    top: 0;
    min-height: 100vh;
    overflow: hidden;
  }
}
.scrollslide[tabindex]:focus {
  z-index: 1;
}
.scrollslide .slide {
  padding: 1.5em;
  flex-grow: 1;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}
@media screen and (min-width: 62em) {
  .scrollslide .slide {
    padding: 3em;
  }
}
.scrollslide .slide-inset {
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 33em;
  margin: 0 auto;
  counter-increment: count;
}
@media screen and (min-width: 62em) {
  .scrollslide .slide-inset {
    flex-direction: column;
  }
}
.scrollslide .slide-inset::before {
  display: inline-block;
  margin: 0 0.6667em 0.5em 0;
  content: counter(count, decimal-leading-zero);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  border-bottom: solid 0.0625rem #e22e2c;
}
@media screen and (min-width: 62em) {
  .scrollslide .slide-inset::before {
    font-size: 2.7rem;
    font-weight: 200;
  }
}
.scrollslide .slide-image,
.scrollslide .slide-image .image {
  position: absolute;
  inset: 0;
}
.scrollslide .slide-image::after {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 45, 82, 0.74);
  content: "";
}
@media screen and (min-width: 62em) {
  .scrollslide .slide-image .image {
    background: center center no-repeat;
    background-image: var(--slide_background_image);
    background-size: cover;
    filter: grayscale(100%);
  }
}

/* Sub-Navigation component manifest file
*****************************************/
/* Sub-Navigation component (default)
*************************************/
/* Sub-Navigation component manifest file
*****************************************/
/* Sub-Navigation Panel component (default)
*******************************************/
.subnav {
  padding: 1.5em 1em;
  background-color: #e6ebf0;
}
@media screen and (min-width: 30em) {
  .subnav {
    padding: 1.5em;
  }
}
@media screen and (min-width: 62em) {
  .subnav {
    padding: 2em;
  }
}

.mobile .subnav {
  border-color: #e6ebf0;
}

@media screen and (min-width: 62em) {
  .desktop .subnav {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
  }
}
@media screen and (min-width: 62em) {
  .desktop .subnav.open {
    z-index: 101;
  }
}

/* Tabs component manifest file
*******************************/
/* Tabs component (default)
***************************/
[role=tablist] {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  top: 0.0625em;
  margin: 0;
  padding: 0;
  list-style: none;
}
[role=tablist] > [role=presentation] {
  flex-grow: 1;
}
@media screen and (min-width: 67.5em) {
  [role=tablist] > [role=presentation] {
    flex-grow: 0;
  }
}
[role=tablist] a {
  position: relative;
  display: block;
  background-color: #fff;
  border: solid 0.0625em #e6ebf0;
  font-size: 0.9rem;
}
@media screen and (min-width: 48em) {
  [role=tablist] a {
    font-size: initial;
  }
}
[role=tablist] a span {
  display: inline-block;
  padding: 0.75rem 1.5em;
}
@media screen and (min-width: 48em) {
  [role=tablist] a span {
    padding: 1em 2em;
  }
}
[role=tablist] a::after {
  display: block;
  background-color: #fff;
  height: 0.125rem;
  content: "";
}
[role=tablist] a[aria-selected] {
  position: relative;
  background-color: #f2f5f7;
  border-bottom-color: #f2f5f7;
}
[role=tablist] a[aria-selected]::after {
  background-color: #e22e2c;
}

[role=tabpanel] {
  padding: 1.5em;
  background-color: #f2f5f7;
  border: solid 0.0625em #e6ebf0;
}
.panel .tabs [role=tabpanel] {
  margin-left: -1.5em;
  margin-right: -1.5em;
}
@media screen and (min-width: 30em) {
  .panel .tabs [role=tabpanel] {
    margin-left: 0;
    margin-right: 0;
  }
}

[data-tab-panel]:not([role=tabpanel]) + [data-tab-panel]:not([role=tabpanel]) {
  margin-top: 1.5em;
}

/* Timeline component manifest file
***********************************/
/* Timeline Component (default)
*******************************/
@media screen and (min-width: 48em) {
  .timeline-container {
    margin-left: -2em;
    margin-right: -2em;
  }
}

.timeline {
  padding: 3em 2em;
  color: var(--color_scheme_text);
  background-color: var(--color_scheme_background);
}
@media screen and (min-width: 48em) {
  .timeline {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}
@media screen and (min-width: 62em) {
  .timeline {
    padding-top: 10em;
    padding-bottom: 10em;
  }
}
.timeline .restrict-width {
  max-width: 71.25em;
  margin: 0 auto;
}

.timeline-introduction {
  margin-bottom: 3em;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .timeline-introduction {
    margin-top: 1.875em;
    margin-bottom: 5.625em;
  }
}
.timeline-introduction.animation {
  animation: fadeIn 1s ease-out forwards, slideInUp 1s cubic-bezier(0.39, 0.58, 0.57, 1) forwards;
}

.timeline-slides {
  position: relative;
  max-width: 56.25em;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.timeline-slides::before {
  position: absolute;
  top: 0;
  left: 2.5rem;
  display: block;
  width: 0.125rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.22);
  content: "";
}
@media screen and (min-width: 48em) {
  .timeline-slides::before {
    left: 50%;
  }
}

.timeline-slide {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "year slide_description";
}
@media screen and (min-width: 48em) {
  .timeline-slide {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "slide_image year slide_description";
    align-items: center;
  }
}
.timeline-slide[tabindex]:focus {
  outline: dotted 0.125em #3699c9;
  outline-offset: 0.5em;
}
.timeline-slide.animation {
  transform-style: preserve-3d;
  animation: unfoldHorizontal 0.75s cubic-bezier(0.39, 0.58, 0.57, 1) forwards;
  animation-delay: 0.5s;
}
.timeline-slide .slide-image {
  display: none;
  grid-area: slide_image;
}
@media screen and (min-width: 48em) {
  .timeline-slide .slide-image {
    display: block;
    padding: 1em;
  }
}
@media screen and (min-width: 62em) {
  .timeline-slide .slide-image {
    padding: 1.875em;
  }
}
.timeline-slide .slide-image::before {
  display: block;
  width: 14.5em;
  height: 14.5em;
  background: center center no-repeat;
  background-image: var(--slide_background_image);
  background-size: cover;
  border-radius: 50%;
  content: "";
}
@media screen and (min-width: 62em) {
  .timeline-slide .slide-image::before {
    width: 18.75em;
    height: 18.75em;
  }
}
.timeline-slide .year-roundal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: year;
  align-self: start;
  width: 5rem;
  height: 5rem;
  margin: 0;
  padding: 0.625rem;
  font-size: 1.3rem;
  font-weight: 600;
  background: #e22e2c;
  border-radius: 50%;
}
.timeline-slide .slide-description {
  margin: 0 0 2.25em;
  padding: 0 1em;
  grid-area: slide_description;
}
@media screen and (min-width: 48em) {
  .timeline-slide .slide-description {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .timeline-slide:nth-child(odd) {
    grid-template-areas: "slide_description year slide_image";
  }
}

@media screen and (min-width: 48em) {
  .panel-container + .timeline-container > .timeline,
.timeline-container + .panel-container > .panel {
    margin-top: -4.5em;
  }
}
@media screen and (min-width: 62em) {
  .panel-container + .timeline-container > .timeline,
.timeline-container + .panel-container > .panel {
    margin-top: -6em;
  }
}

/* Important note manifest file
************************************/
/* Important Note (default)
**************************/
.fsi-important-note__wrapper {
  background-color: #ecedef;
  padding: 0 2.5em 0em 2.5em;
}
.fsi-important-note__accordion-block {
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
}
.fsi-important-note__vertical-icon {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.fsi-important-note__title-1 {
  margin-left: 26px;
  color: #59667c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.fsi-important-note__title-2 {
  margin-left: 26px;
  color: #59667c;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.fsi-important-note__subtitle {
  font-weight: 300;
}
.fsi-important-note__button-expand {
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: transparent;
}
.fsi-important-note__button-expand.fsi-important-note__blue {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  background-color: #fff;
  opacity: 0.36;
  border-color: #022856;
}
.fsi-important-note__accordion-wrapper {
  overflow: hidden;
  max-height: 250px;
  transition: max-height 500ms ease-in-out;
}
.fsi-important-note__margin {
  height: 1px;
  margin-bottom: 20px;
  background-color: #d6d9df;
}
.fsi-important-note__white-fade {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  height: 48px;
  margin-right: 24px;
  margin-bottom: 1px;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #ecedef 85%);
}
.fsi-important-note__scrolling-text {
  position: relative;
  overflow: auto;
  height: 192px;
  margin-bottom: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #d6d9df;
}
.fsi-important-note__disclaimer p {
  font-size: 15px;
  line-height: 1.3em;
}
.fsi-important-note__disclaimer li {
  color: #59667c;
  font-family: Suisseintl, sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
}

.important-note-vertical-icon {
  background-color: #022856;
  position: absolute;
  height: 100%;
  width: 2px;
  transition: all 0.5s ease;
}

.accordion__price-performance-vertical-icon-clicked {
  transform: rotate(90deg);
}

.important-note-horizontal-icon {
  background-color: #022856;
  width: 100%;
  height: 2px;
}

.important-note-hide {
  display: none !important;
}

.important-note-show {
  display: block !important;
}

.important-note-collapsed {
  max-height: 0px !important;
  display: block !important;
}

.important-note-expanded {
  max-height: 600px !important;
}

.important-note__content {
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
}

.important-note--icon-block {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
}
.important-note--icon-block.important-note-block {
  height: 12px;
  width: 12px;
}

/* Strategy Filter component manifest file
*******************************************/
/* Strategy Filter component (default)
***************************************/
.dropdown-selector-insights {
  max-width: 432px;
  margin-right: 0px;
  margin-left: 12px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: inline-block;
  position: relative;
  text-align: left;
  z-index: 900;
  display: block;
  width: 100%;
  padding: 12px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #c3c8cc;
  background-color: #fff;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.dropdown-selector-insights__header {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.02rem;
}
.dropdown-selector-insights__wrapper {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Strategy Page P&P component manifest file
*******************************************/
/* Strategy Page P&P component (default)
***************************************/
.custom-select {
  height: 45px !important;
  color: #59667c !important;
  font-weight: 500 !important;
}

.performance-documents__overview {
  background-color: #f2f5f7 !important;
}

.dynamic-table table {
  border-right: 1px solid #ddd !important;
}
.dynamic-table th,
.dynamic-table td {
  padding: 6px;
  vertical-align: top;
}
@media screen and (max-width: 1410px) {
  .dynamic-table__wrapper-inner {
    overflow-x: scroll !important;
    word-wrap: nowrap !important;
  }
}

.bse-text__single-fund {
  -webkit-appearance: none;
  -moz-appearance: none;
  animation: none;
  background-color: #fff;
  color: #525051;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 10px 5px 10px 10px;
  width: 73%;
  max-width: 80%;
  cursor: initial;
  text-align: center;
  border-style: groove;
  word-wrap: break-word;
  white-space: nowrap;
}

.bse-tab__button[aria-pressed=true] {
  background: #f2f5f7 !important;
}
.bse-tab__body {
  background: #f2f5f7 !important;
}

/* OneTrust Consent Banner component manifest file
***********************************/
/* OneTrust Consent Banner Component (default)
*******************************/
#onetrust-banner-sdk.otCenterRounded {
  display: none !important;
  width: 80vw !important;
  max-width: max-content !important;
  left: 0em !important;
}
@media only screen and (max-width: 425px) {
  #onetrust-banner-sdk.otCenterRounded {
    width: 95vw !important;
  }
}

#onetrust-banner-sdk.ot-bnr-w-logo .ot-bnr-logo {
  height: 40px !important;
  width: auto !important;
}

#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc {
  color: #475966 !important;
  font-size: 16px !important;
  line-height: 25.6px !important;
}

#onetrust-banner-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title {
  font-size: 24px !important;
}

#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-pc-sdk .ot-pc-refuse-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
  color: #002a4b !important;
  background-color: #ffffff !important;
  border-color: #002a4b !important;
  font-weight: 500 !important;
  border-radius: 2.5em !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler,
#onetrust-pc-sdk #accept-recommended-btn-handler {
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 2.5em !important;
}

#onetrust-banner-sdk #onetrust-reject-all-handler:hover,
#onetrust-banner-sdk #onetrust-pc-btn-handler:hover,
#onetrust-pc-sdk .ot-pc-refuse-all-handler:hover {
  color: #ffffff !important;
  background-color: #3699c9 !important;
  border-color: #3699c9 !important;
}

#onetrust-pc-sdk .save-preference-btn-handler {
  border-radius: 2.5em !important;
}

@media only screen and (max-width: 768px) {
  #onetrust-banner-sdk .banner-actions-container {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  #onetrust-banner-sdk #onetrust-reject-all-handler {
    order: 2 !important;
  }
}
#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
  color: #002a4b !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
  background-color: #002a4b !important;
  border-color: #002a4b !important;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  border: 1px solid #002a4b !important;
}

#onetrust-pc-sdk .ot-pc-header {
  padding: 10px 0 10px 10px !important;
}

#onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
}

#onetrust-banner-sdk a:hover,
#onetrust-pc-sdk a:hover {
  color: #cc181f !important;
  text-decoration: unset !important;
}

.onetrust-banner-sdk-open {
  overflow: hidden;
}

a.ot-opt-out-notice-link {
  color: #002a4b !important;
}

button#ot-sdk-btn.ot-sdk-show-settings {
  background-color: unset !important;
  color: #c3c8cc !important;
  font-size: 12.8px !important;
  font-weight: 500 !important;
  line-height: 1.6em !important;
  letter-spacing: 0.32px !important;
  border: none !important;
  padding: 0 !important;
}

button#ot-sdk-btn.ot-sdk-show-settings:hover {
  color: #ffffff !important;
  text-decoration: underline;
}

#onetrust-pc-sdk .ot-pc-footer-logo,
.hide-one-trust-banner #onetrust-banner-sdk.otCenterRounded {
  display: none !important;
}

.hide-one-trust-banner .onetrust-pc-dark-filter {
  position: unset !important;
}