/* Modal Overlay (Video variation)
**********************************/
// Brightcove video loaded from an element in the page.

.modal.modal--video {
    max-width: 40em;
    height: auto;
    border-color: #cfdbe5;

    iframe {
        opacity: 1;
    }

    .video-type {
        position: relative;
    }

    .btn.modal-close {
        top: 0;
        right: 0;
        padding: 0.25em;

        color: $white;
        background: transparent;
        border-color: transparent;

        @media screen and (min-height: 28.75em) {
            top: -2.5em;
        }

        &:hover,
        &:focus {
            color: $white;
            background-color: $fssa-airforce;
            border-color: $fssa-airforce;
        }

        svg {
            margin: 0;
        }
    }

    .modal-content {
        background-color: $fssa-blue;
    }
}
