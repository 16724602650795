/* Accordion (component-specific styles)
****************************************/

// .accordion-container {}
// .accordion {}

.accordion-panel {
    margin-bottom: em(12px, 16px);
    padding: 0 1em;
    border: solid em(1px, 16px) $fssa-bgcolor;

    // Including default color (second parameter) in case no `.scheme--COLOR` is set
    color: var(--color_scheme_text, $fssa-textcolor);
    background-color: var(--color_scheme_background, $fssa-light-gray);

    transition: background-color 0.3s ease;

    @media screen and (min-width: $viewport-narrow) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &[data-switch][data-panel="open"] {
        --color_scheme_background: #{$white};
        --color_scheme_text: #{$fssa-textcolor};
    }
}

.accordion-heading {
    margin: 0;
    padding: em(12px, 16px) 0;

    @media screen and (min-width: $viewport-narrow) {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    button {
        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &[data-accordion-trigger] {
            svg {
                fill: $fssa-blue;
            }
        }
    }
}

.accordion-content {
    padding-bottom: 1em;
}
