/* search-results component (default)
*************************************/
$results-padding: 1.25em;
$grid-teaser-width: 14.375em;
$grid-teaser-gap: $results-padding;

.search-results-container {
    > *:not(.searched-term),
    .searched-term .constrain-width {
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        }
    
    .no-results {
        padding: 2em;
        color: $fssa-gray;
        font-size: 1.375rem;
        text-align: center;
        }
    }

.searched-term {
    padding: $results-padding 0;
    background-color: $fssa-light-gray;

    h2 {
        margin: 0 0 0.5em;
        padding: 0;
        font-size: 1.375rem;
        font-weight: 600;
        border: none;
    }

    p {
        margin: 0;
        font-size: 1.125rem;
        font-weight: 300;
        padding-left: 20px;
    }

}

.bse-card__content{
        display: grid;
        align-items: center;
        padding: 20px;
}
