/* List component (default)
***************************/

.list {
    margin: 0;
    padding: 0 1em;

    li {
        margin: 0.75em 0;
    }

    a {
        @extend %link-plain;
        @extend %link-plain--red;
    }
}
