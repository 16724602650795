/* Price/Performance Form component (default)
*********************************************/

.priceperformance-form {
    .table--data {
        th[scope="row"] {
            width: 25%;
            min-width: 12.5em;
            white-space: normal;
        }
    }
}

// Tab-Content DATE multi-selector
// e.g. on Price Tab of Price/Performance page
.date-filters.price {
    @media screen and (min-width: $viewport-wide) {
        grid-template-columns: 26.25em 1fr;
    }

    select,
    button.btn {
        font-size: 0.9rem;
    }

    .multi-selector, .multi-selector-date-filter {
        @media screen and (min-width: $viewport-medium) {
            grid-template-columns: 3.125em repeat(auto-fit, minmax(6.25em, max-content));
        }

        & + .multi-selector, .multi-selector-date-filter {
            margin-top: 1.5em;

            @media screen and (min-width: $viewport-medium) {
                margin-top: 0;
            }
        }
    }

    p.cta {
        @media screen and (min-width: $viewport-medium) {
            margin-top: -0.5em;
            margin-left: 3.4375em;
        }
    }
}
