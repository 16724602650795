/* Dividends Form component (default)
*************************************/

.dividends-form {
    // Non-tabbed UI
    .panel-inset {
        margin: -2em -1.5em;

        @media screen and (min-width: $viewport-narrow) {
            margin-left: -2em;
            margin-right: -2em;
        }

        @media screen and (min-width: $viewport-wide) {
            margin: 0;
        }
    }

    .table--data {
        padding: 1.5em;

        th[scope="col"],
        .row-group dt {
            font-size: 0.75rem;
            font-weight: 500;
            text-transform: uppercase;
        }

        th[scope="col"],
        td {
            text-align: initial;
        }

        .row-heading {
            padding-top: 0;
            padding-bottom: 0.75em;
            font-size: 1rem;
        }
    }

    .table--sparse {
        th[scope="row"] {
            width: 33%;
            white-space: normal;
        }
    }

    .table--dense {
        th[scope="col"] {
            min-width: 12.5em;
            white-space: normal;
        }

        th[scope="row"] {
            min-width: 18em;
            white-space: normal;
        }
    }
}

// DIVIDENDS DATE multi-selector
// e.g. on Dividends/Fund Distributions page
.date-filters.dividends {
    margin-top: 1.2em;

    @media screen and (min-width: $viewport-wide) {
        grid-template-columns: minmax(auto, 2fr) 1fr;
        gap: 1.5em;
    }

    legend.heading--tertiary {
        margin-bottom: 0;

        @media screen and (min-width: $viewport-medium) {
            grid-column: -1 / 1;
        }
    }

    legend.heading--small {
        font-size: 1rem;
        font-weight: 500;
    }

    > fieldset {
        @media screen and (min-width: $viewport-medium) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5em;
        }
    }

    .multi-selector {
        @media screen and (min-width: $viewport-medium) {
            grid-template-columns: 1fr;
        }

        @media screen and (min-width: $viewport-wide) {
            grid-template-columns: minmax(auto, max-content) 1fr;
        }
    }
}
