/* List component (Large variant)
*********************************/

.list--large {
    padding: 0;
    list-style: none;

    &.list {
        a {
            @extend %link-plain--blue;
        }
    }

    a,
    span {
        display: flex;
        align-items: center;
        padding: 0.75rem 0;
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 1.2;
    }

    svg {
        width: 1.125rem;
        height: 1.125rem;
        flex-shrink: 0;
    }

    li {
        margin: 0;
    }

    > li:not(:last-child) {
        border-bottom: dashed em(1px, 20.8px) $fssa-textcolor--footer;
    }
}
