/* Main component (default)
***************************/

.main {
    position: relative;

    &[tabindex] {
        outline: 0;
    }
}

.tmpl-casestudy {
    .main.landmark {
        min-height: 100vh; // @important: Turn this rule off in author `editmode.css`
        padding: 0; // Padding handled by the article component
    }
}
