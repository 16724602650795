/* Sub-Navigation Panel component (default)
*******************************************/

.subnav {
    @extend %slidingPanel;
    padding: 1.5em 1em;
    background-color: $fssa-bgcolor;

    @media screen and (min-width: $viewport-narrow) {
        padding: 1.5em;
    }

    @media screen and (min-width: $viewport-wide) {
        padding: 2em;
    }
}

// Mobile navigation: sub-nav
.mobile {
    .subnav {
        border-color: $fssa-bgcolor;
    }
}

// Desktop navigation: sub-nav
.desktop {
    .subnav {
        @media screen and (min-width: $navigation-switch) {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 100;
        }
    }

    .subnav.open {
        @media screen and (min-width: $navigation-switch) {
            z-index: 101;
        }
    }
}
