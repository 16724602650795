/* Header/logos component (default)
***********************************/

.header-logo {
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: em(60px, 16px);
    flex-shrink: 0;

    fill: $fssa-blue;

    @media screen and (min-width: $navigation-switch) {
        position: static;
        align-self: center;
    }

    //  English-only strap line
    .logo-lines {
        display: block;
        max-height: em(38px, 16px);
        margin: 0;
        font-size: inherit;
        overflow: hidden;

        @media screen and (min-width: $viewport-narrow) {
            max-height: em(56px, 16px);
        }
    }

    //  English & Chinese strap lines
    .logo-lines.logo-lines--two {
        max-height: none;
    }

    a {
        display: block;
    }

    .symbol-highlight {
        fill: $fssa-red;
    }
}

.header-logo,
.header-logo svg,
.match-logo-height {
    width: em(100px, 16px);
    height: em(60px, 16px);

    @media screen and (min-width: $viewport-narrow) {
        width: em(140px, 16px);
        height: em(86px, 16px);
    }

    @media screen and (min-width: $viewport-medium) {
        width: em(156px, 16px);
        height: em(90px, 16px);
    }
}
