/* Country selector component (Modal variation)
***********************************************/

.country-selector--modal {
    padding: 0;

    .country-selector--header {
        margin-bottom: 0.75em;
        padding-bottom: 0;
        border: 0;

        h2 {
            margin-right: 5em;
        }
    }

    .country-selector--body {
        position: absolute;
        left: 0;
        right: 0;
        background-color: $white;
        box-shadow: 0 0 1.25em 0 rgba(0, 20, 53, 0.22);
        z-index: 1;

        .continents {
            max-height: 50vh;
            padding: 1.5em;
            // IMPORTANT: Make panel scrollable, as necessary
            // This deviated from the design on purpose, as access/accessibility was
            // not considered in the design prototype
            overflow-y: auto; // Allow country list to scroll if/when it overflows container height
            -webkit-overflow-scrolling: touch;
        }
    }
}
