/* Navigation component (default)
*********************************/

.navigation-container {
    display: grid;
    width: 100%;
    grid-template-columns: auto;
    grid-template-rows: auto;

    /* prettier-ignore */
    grid-template-areas:
        "navigation-menu"
        "navigation-main"
        "navigation-extra"
        "search";

    @media screen and (min-width: $navigation-switch) {
        width: auto;
        grid-template-columns: minmax(auto, max-content);
        /* prettier-ignore */
        grid-template-areas:
            "navigation-menu"
            "navigation-extra"
            "navigation-main"
            "search";
        justify-self: end;
    }

    .match-logo-height {
        display: flex;
        width: auto;
        justify-self: right;
        grid-area: navigation-menu;
    }

    .btn-menu {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 3.125em;
        height: 3.125em;
        margin: auto;
        color: $white;
        background-color: $fssa-red;

        &[aria-expanded="true"] {
            background-color: $fssa-blue;
        }

        @media screen and (min-width: $viewport-narrow) {
            width: 3.75em;
            height: 3.75em;
        }
    }

    // Hide from desktop view:
    @media screen and (min-width: $navigation-switch) {
        .match-logo-height,
        .btn-menu {
            display: none;
        }
    }
}

.navigation-row {
    margin: 0 -1em;

    @media screen and (min-width: $viewport-narrow) {
        margin-left: -1.5em;
        margin-right: -1.5em;
    }

    @media screen and (min-width: $navigation-switch) {
        margin: 0;
    }

    > ul {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $navigation-switch) {
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.navigation {
    grid-area: navigation-main;
    padding-top: 1em;

    @media screen and (min-width: $navigation-switch) {
        min-height: 3.5em;
        margin-right: -1em;
        padding-top: 0;
    }

    &.mobile {
        @extend %slidingPanel;
    }

    &::before {
        @media screen and (min-width: $navigation-switch) {
            position: relative;
            right: -1em;
            display: block;
            width: calc(100% - 2em);
            height: em(1px, 16px);
            background-color: $fssa-bgcolor;
            content: "";
        }
    }

    > ul {
        > li {
            flex-grow: 0;
        }
    }
}

.nav-item,
.navxtra-item {
    > a,
    > button {
        @extend %link-plain;
        @extend %link-plain--blue;
        display: block;
        padding-top: 1em;
        padding-bottom: 1em;

        @media screen and (min-width: $navigation-switch) {
            padding-left: 1em;
            padding-right: 1em;
        }

        &:focus-visible {
            outline-offset: rem(-3px, 16px);
        }
    }
}

.nav-item {
    padding: 0 1em;
    background-color: rgba($fssa-bgcolor, 0.5);
    border-bottom: solid em(1px, 16px) $fssa-bgcolor;

    @media screen and (min-width: $viewport-narrow) {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    // Non-JS version: saves having to add to desktop rules
    @media screen and (min-width: $navigation-switch) {
        padding: 0;
        background-color: inherit;
        border: 0;
    }

    &:first-child {
        border-top: solid em(1px, 16px) $fssa-bgcolor;

        // Non-JS version: saves having to add to desktop rules
        @media screen and (min-width: $navigation-switch) {
            border: 0;
        }
    }

    .subnav[data-accordion-target] {
        margin: 0 -1em;

        @media screen and (min-width: $viewport-narrow) {
            margin-left: -1.5em;
            margin-right: -1.5em;
        }
    }
}
