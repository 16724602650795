/* Strategy Filter component (default)
***************************************/

.dropdown-selector-insights {
    max-width: 432px;
    margin-right: 0px;
    margin-left: 12px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: inline-block;
    position: relative;
    text-align: left;
    z-index: 900;
    display: block;
    width: 100%;
    padding: 12px 16px;
    border-style: solid;
    border-width: 1px;
    border-color: #c3c8cc;
    background-color: #fff;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;

    &__header {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 1.2rem;
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: -0.02rem;
    }

    &__wrapper {
        background-color: #fff;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

