/* Icon styles (default)
************************/

.icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
    flex-shrink: 0;
    pointer-events: none;

    &.icon--small {
        width: 1.25rem;
        height: 1.25rem;
    }

    &.icon--large {
        width: 1.75rem;
        height: 1.75rem;
    }

    &.icon--xlarge {
        width: 2rem;
        height: 2rem;
    }
}

.icon--chevron {
    position: relative;
    top: 0.125rem;
}

.icon--clock {
    fill: none;
    stroke-linecap: round;
    stroke-width: 2;
    stroke: currentColor;
}

// @note: plus/minus icon is not part of SVG sprite
.icon--plus {
    .vertical {
        transform-box: fill-box;
        transform-origin: center;
        transition: transform 0.4s ease;

        [aria-expanded="true"] & {
            transform: rotate(90deg);
        }
    }
}

// BACKGROUND ICONS
// ================
// These icons aren't part of the SVG sprite, as they are required directly by CSS,
// e.g. for pseudo-elements `::before`  & `::after` &/or in areas where we can't add
// markup or CSS class names

%icon--chevron {
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    background-color: currentColor;
    mask-image: url("../img/svg/chevron.svg");
    // ^^ No need to add -webkit prefix version of mask-image before this rule;
    // Autoprefixer does that for us
}

%icon--download {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: currentColor;
    mask-image: url("../img/svg/download.svg");
    // ^^ No need to add -webkit prefix version of mask-image before this rule;
    // Autoprefixer does that for us
}

%icon--new-tab {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: currentColor;
    mask-image: url("../img/svg/arrow.svg");
    // ^^ No need to add -webkit prefix version of mask-image before this rule;
    // Autoprefixer does that for us
    transform: rotate(-45deg);
}

%icon--quote {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto;
    background-color: currentColor;
    mask-image: url("../img/svg/quote.svg");
    // ^^ No need to add -webkit prefix version of mask-image before this rule;
    // Autoprefixer does that for us
}
