/* Promo Panel styles (default)
*******************************/
// Includes common styles shared by:
// 1). Case Study Teasers
// 2). Insights Teasers
// 3). Teasers

.promo {
    display: flex;
    break-inside: avoid;
    overflow: hidden;
    transition: all 0.2s ease;

    &:focus-within {
        // Accessibility: make keyboard focus access very bold
        // This will show when mouse-user clicks through - consider this a feature, not a bug!
        @extend %button-focus-visible;

        .subnav & {
            outline-color: $fssa-red;
        }
    }
}

.promo-type {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.promo-image {
    overflow: hidden;

    img {
        @extend %image-objectfit;
    }
}

.promo-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5em;
    z-index: 1;

    @media screen and (min-width: $viewport-narrow) {
        padding: 2em;
    }

    @media screen and (min-width: $viewport-wide) {
        padding: 3em;
    }
}

.promo-heading {
    margin-bottom: 0;
    color: inherit;
}

.promo-link {
    @extend %link-cover-container;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    word-wrap: break-word;

    .promo:focus-within &:focus {
        text-decoration: underline;
        outline: none; // Underline being used as alternative indicator, so we can turn off outline
    }
}

.promo-description {
    display: none;
    overflow: hidden;

    a {
        @extend %link-plain;
        @extend %link-plain--inherit;
        @extend %link-raise-access;
        // ^^ If there are any links within the description,
        // raise them up so they are clickable
    }

    > p {
        max-width: 70ch; // Restrict text line length, nicer reading experience

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.promo-cta {
    display: flex;
    width: 2.625em;
    height: 2.625em;
    padding: 0.5em;

    background-color: $fssa-blue;
    border: solid em(1px, 16px) rgba($white, 0.15);
    border-radius: 50%;

    transition: all 0.2s ease;

    // The CTA icon hover/focus & focus-within states
    .promo:hover &,
    .promo:focus-within & {
        background-color: $fssa-airforce;
    }

    .icon {
        margin: auto;
    }
}

// Promo-TYPE HUB; used on case study & insights teasers only
.promo-type.hub {
    min-height: em(295px, 16px);
    color: $white;
    background-color: $fssa-bgcolor--teaser;

    @media screen and (min-width: $viewport-narrow) {
        min-height: em(324px, 16px);
    }

    @media screen and (min-width: $viewport-wide) {
        min-height: em(412px, 16px);
    }

    .promo-image {
        @extend %image-gradient-overlay;
        position: absolute;
        width: 100%;
        height: 100%;

        &::after {
            background-image: linear-gradient(rgba($fssa-textcolor--blue, 0), rgba($fssa-textcolor--blue, 0.8) 90%);
        }
    }

    .promo-text {
        position: relative;
        justify-content: flex-end;
    }

    .promo-title {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
        margin: 0 0 auto;
    }
}
