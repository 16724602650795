/* Text component (Sub-Navigation variation)
********************************************/

.subnav {
    .text {
        *:last-child {
            margin-bottom: 0;
        }

        a {
            @extend %link-plain--blue;
        }

        a:only-child {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.1rem;

            &::after {
                @extend %icon--chevron;
                content: "";
            }
        }
    }
}
