/* Documents/Factsheets Form component (default)
************************************************/

.documentsfactsheets-form {
    .filter-spacing {
        margin-bottom: 1.5em;

        @media screen and (min-width: $viewport-narrow) {
            margin-bottom: 2em;
        }
    }

    .show-filter {
        .multi-selector {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            gap: 0.75em;

            legend {
                width: auto;
                margin-bottom: 0;
                float: left;
            }

            p {
                flex-grow: 1;

                @media screen and (min-width: $viewport-medium) {
                    flex-grow: 0;
                }
            }
        }
    }

    .accordion-content {
        padding-bottom: 1.5em;
    }

    .documents-list {
        border-top: solid 0.0625em $fssa-form-bordercolor;

        @media screen and (min-width: $viewport-narrow) {
            padding: 1em 1.5em;
            background-color: $fssa-light-gray;
            border: 0;
        }

        a {
            display: flex;
            font-weight: 400;
            // Dropping font-weight here, as use of icons might be enough to avoid
            // A11y failure: interaction indication by colour alone.
        }

        svg.icon {
            position: relative;
            top: 0.125em;
            margin-right: 0.25em;
        }

        .document-item {
            margin: 0;
            padding: 0.375em 0;
            border-top: solid 0.0625rem $fssa-form-bordercolor;
            
        }

        .document-item:not([hidden]) + .document-item:not([hidden]) {
            border-top: solid 0.0625rem $fssa-form-bordercolor;
        }

        .document-item--heading {
            margin-bottom: 0.25em;
            padding-bottom: 0.25em;
            color: $fssa-textcolor--blue;
            font-size: 0.75rem;
            font-weight: 500;
            text-transform: uppercase;

            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
        table {
		    display: flex;
		    border: none;
		}
		
		td {
		    border: none;
			padding: 0 0 0 5px;
		}
		
		a.nameRed {
			padding: 0 0 0 8px;
		}
		
    }
}
