/* Tabs component (default)
***************************/

[role="tablist"] {
    position: relative;
    display: flex;
    flex-flow: row wrap;

    top: em(1px, 16px);
    margin: 0;
    padding: 0;
    list-style: none;

    > [role="presentation"] {
        flex-grow: 1;

        // Deliberate deviation from design:
        // Leaving tabs to grow up to custom desktop width (1080px equivalent)
        // to allow a better look when number/width of tabs needs to wrap onto
        // different lines. Design only caters for neat & small number of tabs
        // i.e. two - not sufficient for CMS use where editors and language
        // variation could mean less neat wrapping.
        @media screen and (min-width: 67.5em) {
            flex-grow: 0;
        }
    }

    a {
        position: relative;
        display: block;

        background-color: $white;
        border: solid em(1px, 16px) $fssa-bgcolor;
        font-size: 0.9rem;

        @media screen and (min-width: $viewport-medium) {
            font-size: initial;
        }

        span {
            display: inline-block;
            padding: 0.75rem 1.5em;

            @media screen and (min-width: $viewport-medium) {
                padding: 1em 2em;
            }
        }

        &::after {
            display: block;
            background-color: $white;
            height: 0.125rem;
            content: "";
        }

        &[aria-selected] {
            position: relative;
            background-color: $fssa-light-gray;
            border-bottom-color: $fssa-light-gray;

            &::after {
                background-color: $fssa-red;
            }
        }
    }
}

[role="tabpanel"] {
    padding: 1.5em;
    background-color: $fssa-light-gray;
    border: solid em(1px, 16px) $fssa-bgcolor;

    .panel .tabs & {
        margin-left: -1.5em;
        margin-right: -1.5em;

        @media screen and (min-width: $viewport-narrow) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

// Non-JavaScript spacing
[data-tab-panel]:not([role="tabpanel"]) {
    & + & {
        margin-top: 1.5em;
    }
}
