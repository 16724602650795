/* SCSS placeholder templates
*****************************/

%button-nostyle {
    margin: 0;
    padding: 0;
    appearance: none;
    color: inherit;
    background-color: transparent;
    border: none;
    font: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    cursor: pointer;
}

%button-focus-visible {
    outline: solid 0.125em $fssa-airforce;
    outline-offset: 0.125em;
}

%card-stacked-image-heights {
    height: 10.265em; // 170px

    @media screen and (min-width: $viewport-narrow) {
        height: 12.5em; // 200px
    }
}

%heading,
.heading {
    margin: 0 0 1rem;
    color: var(--color_heading);
    line-height: 1.2;
}

%heading--none,
.heading--none {
    margin: 0 0 1em;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.02rem;
}

%heading--primary,
.heading--primary {
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: -0.05rem;

    @media screen and (min-width: $viewport-medium) {
        font-size: 2.4rem;
    }
}

%heading--secondary,
.heading--secondary {
    margin-bottom: 0.75rem;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: -0.03rem;

    @media screen and (min-width: $viewport-narrow) {
        font-size: 1.9rem;
        font-weight: 300;
    }
}

%heading--tertiary,
.heading--tertiary {
    margin-bottom: 0.75rem;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.02em;

    @media screen and (min-width: $viewport-narrow) {
        font-size: 1.5rem;
    }
}

%heading--quaternary,
.heading--quaternary {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.02em;
}

%heading--regular,
.heading--regular {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.6;
}

%heading--small,
.heading--small {
    margin-bottom: 0.5em;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.6;

    @media screen and (min-width: $viewport-medium) {
        font-size: 1rem;
    }
}

%image-gradient-overlay {
    &::after {
        position: absolute;
        inset: 0;
        display: block;
        background-image: linear-gradient(rgba($fssa-bgcolor--teaser, 0), $fssa-bgcolor--teaser);
        content: "";
    }
}

%image-objectfit {
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
}

%link-cover-container {
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: "";
    }
}

%link-raise-access {
    position: relative;
    z-index: 1;
}

%link-plain {
    color: var(--color_link);
    // @note: Designs sometimes use 400 weight text for links.
    // This breaks accessibility guideline: WCAG 1.4.1, hence keeping higher font-weight,
    // esp. for red links, where this fails colour contrast rules.
    font-weight: 500;
    text-decoration: none;
    overflow-wrap: anywhere;

    &:hover,
    &:focus {
        // @note: Designs only use color change to indicate link hover/focus.
        // This breaks accessibility guideline: WCAG 1.4.1, hence additional underline
        text-decoration: underline;
    }
}

%link-plain--blue,
.link--blue {
    --color_link: #{$fssa-textcolor--link};

    &:hover,
    &:focus {
        --color_link: #{$fssa-textcolor--link-hover};
    }
}

// Usually where white text on dark background
// Underline uses opposite behaviour to stand out from bold text formatting
%link-plain--inherit {
    --color_link: initial;
    text-decoration: underline;

    &:hover,
    &:focus {
        --color_link: initial;
        text-decoration: none;
    }
}

%link-plain--red,
.link--red {
    --color_link: #{$fssa-textcolor--link-hover};

    &:hover,
    &:focus {
        --color_link: #{$fssa-textcolor--link};
    }
}

%list-reset,
.list-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

%narrow-indent {
    padding-left: 24px;
    padding-right: 24px;

    @media screen and (min-width: $viewport-narrow) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media screen and (min-width: $viewport-medium) {
        padding-left: 0;
        padding-right: 0;
    }
}

%post-hero-margin {
    @media screen and (min-width: $viewport-medium) {
        margin-top: -6em;
    }
}

%panel-padding,
.panel-padding {
    padding: 2em 1.5em;

    @media screen and (min-width: $viewport-narrow) {
        padding-left: 2em;
        padding-right: 2em;
    }

    @media screen and (min-width: $viewport-wide) {
        padding: 3em;
    }
}

%site-maxwidth {
    width: 100%;
    max-width: $site-maxwidth;
    margin-left: auto;
    margin-right: auto;
}

%text--lead {
    font-size: 1.2rem;
    line-height: 1.3;

    @media screen and (min-width: $viewport-narrow) {
        font-size: 1.3rem;
    }
}

// Screen reader text
// Hides text visually, but exposes it to assistive technology
%visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    // ^^ Clip is deprecated CSS property that older browsers support
    clip-path: inset(100%);
    white-space: nowrap;
    overflow: hidden;
}

%white-panel {
    padding: 1em 2em;
    background-color: $white;
}
