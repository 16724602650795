/* Background component (default)
*********************************/

.background--fullpage {
    position: fixed;
    inset: 0;

    .background-item {
        position: absolute;
        // ^^ Including absolute positioning here:
        // 1). Helps with presenting image/video in author editmode
        // 2). Is essential for video item
        // 3). Does no harm to the image variant
        width: 100%;
        height: 100%;

        opacity: 0.3;
        filter: blur(0.08em);
        // Adding filter blur to help knock-back any background image/video
        // Ensure that image/video isn't competing with the (more important) content on top
        // Also obscures use of lower quality images, which should be the editor choice here
    }

    video {
        object-fit: cover;
    }

    .background-image {
        background: var(--background_image) 50% 50% no-repeat;
        background-size: cover;
    }
}
