/* Article component (Case Study Template)
******************************************/

.tmpl-casestudy {
    .main {
        > .article.landmark {
            padding: 1.5em;
            background-color: initial;

            @media screen and (min-width: $viewport-narrow) {
                padding: 1em 2em;
            }
        }
    }
}
