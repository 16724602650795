/* Footer/social-media component (default)
******************************************/

.footer-social {
    display: grid;
    gap: em(16px, 16px);

    margin-bottom: em(32px, 16px);
    padding-bottom: 2em;
    border-bottom: solid em(1px, 16px) rgba($fssa-textcolor--footer, 0.2);

    @media screen and (min-width: $viewport-wide) {
        gap: em(32px, 16px);
        grid-template-columns: 1fr minmax(min-content, em(260px, 16px));
        margin-bottom: em(48px, 16px);
    }

    p {
        margin: 0;
        color: $white;
        font-size: 1.4rem;
        line-height: 1.3;

        @media screen and (min-width: $viewport-medium) {
            font-size: 1.6rem;
            line-height: 1.4;
        }
    }

    ul {
        display: flex;
        margin: auto em(-4px, 16px);
        padding: 0;
        list-style: none;

        @media screen and (min-width: $viewport-wide) {
            justify-content: flex-end;
        }

        li {
            margin: 0 em(4px, 16px);
        }
    }

    .social-lead {
        a {
            --color_link: #{$fssa-airforce};
            font-weight: 400;

            &:hover,
            &:focus {
                --color_link: #{$white};
            }
        }
    }

    .social-link {
        --color_link: #{$white};
        display: flex;
        width: em(44px, 16px);
        height: em(44px, 16px);
        align-items: center;
        justify-content: center;

        background-color: #144266;
        border: solid em(1px, 16px) #19527f;
        border-radius: 50%;

        transition: background-color 200ms ease;

        &:hover,
        &:focus {
            background-color: #2373b2;
        }

        // ELEMENT FOCUS RULES
        // For Accessibility reasons make sure there are styles for
        // `:focus-visible` on any keyboard focusable element.
        // -- `:focus-visible` will be supported by Safari from version 15.3
        // -- Use something big & bold, so those with poor vision or in bright environments
        //    can see it
        // -- It's a feature, not a bug!
        &:focus-visible {
            @extend %button-focus-visible;
        }

        svg {
            // Adjust LinkedIn icon slightly
            &.icon--linkedin {
                position: relative;
                top: em(-2px, 16px);
            }
        }
    }
}
