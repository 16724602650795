/* Teaser component (sub-navigation variant)
********************************************/

// Sub-Navigation ALL Teasers (default & block variants)
.subnav {
    .teaser-type,
    .teaser-image {
        min-height: 0;
    }

    // Normalize title font-size for headings
    // @note:
    //      This breaks with the design prototype, but gives us a bit more space,
    //      esp. for narrow desktops where the design prototype looks bad
    .teaser-heading.heading--secondary {
        font-size: 1.3rem;
    }
}

// Sub-Navigation Default Teasers ONLY
.subnav {
    .teaser-type--default {
        .teaser-image {
            position: absolute;
            height: 100%;

            &::after {
                background-image: linear-gradient(90deg, $fssa-bgcolor--teaser 25%, rgba($fssa-bgcolor--teaser, 0));

                @media screen and (min-width: $viewport-wide) {
                    background-image: linear-gradient(rgba($fssa-bgcolor--teaser, 0), $fssa-bgcolor--teaser);
                }
            }
        }

        .teaser-text {
            margin: 0;
            padding: 1em;

            @media screen and (min-width: $viewport-wide) {
                min-height: em(300px, 16px);
                padding: 2em 1em;
            }

            @media screen and (min-width: $viewport-wider) {
                padding: 2em;
            }
        }

        .teaser-title {
            align-self: center;

            @media screen and (min-width: $viewport-wide) {
                align-self: end;
                margin-bottom: 0.5em;
            }
        }

        .teaser-description {
            display: none;
        }
    }
}

// Sub-Navigation Block Teasers ONLY
.subnav {
    .teaser-type--block {
        display: none;

        @media screen and (min-width: $viewport-narrow) {
            display: flex;
        }
    }
}
