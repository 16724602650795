/* Table component (default)
****************************/

.table {
    color: $fssa-textcolor--blue;
    background-color: $white;
    overflow: hidden;

    p {
        margin-top: 0;
    }
}

table,
.row-group {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.row-group {
    dl {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }

    dt,
    dd {
        flex: 0 0 50%;
        margin: 0;
    }
}

.row-heading {
    margin-bottom: 0;
    padding: 0.5rem 0;
}

.row-group-data > * {
    padding: 0.5rem 0;
}

caption {
    text-align: initial;
}

tbody,
.row-heading {
    a {
        @extend %link-plain;
        @extend %link-plain--red;
    }
}

th,
.row-heading,
.row-group dt {
    font-weight: 500;
}

th,
td {
    padding: 1rem 1.5rem;
    border: solid 0.0625rem $fssa-textcolor--footer;
    text-align: initial;
    line-height: 1.5;

    .table--nowrap & {
        white-space: nowrap;
    }
}

th[scope="col"],
.row-heading {
    border-bottom: solid 0.125rem $fssa-red;
}

// Overflowing table made horizontally scrollable
// Detected & controlled via client-side JavaScript
.table-overflow {
    margin: 0;

    &[tabindex="0"] {
        overflow-x: scroll; // For iOS, smooth scrolling has to be `scroll`, not `auto`.
        -webkit-overflow-scrolling: touch;

        caption {
            max-width: 98vw;
        }

        .scroll-notification {
            margin: 0;
        }
    }
}

// Switch between description list & table output
.table--bestfit {
    .tabular-data {
        display: none;

        @media screen and (min-width: $viewport-medium) {
            display: block;
        }
    }

    .list-data {
        @media screen and (min-width: $viewport-medium) {
            display: none;
        }
    }
}
