/* Button styles (default)
**************************/

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    @extend %button-nostyle;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}

.btn {
    --btn_color_background: #{$white};
    --btn_color_border: #{$fssa-textcolor--blue};
    --btn_color_text: #{$fssa-textcolor--blue};

    &.btn--small {
        padding: 0.5em 1.5em;
        font-size: 0.9rem;
    }

    display: flex;
    padding: 0.5em 2em;
    color: var(--btn_color_text);
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;

    background-color: var(--btn_color_background);
    border: solid em(1px, 16px) var(--btn_color_border);
    border-radius: em(40px, 16px);

    transition: all 0.2s ease;
    overflow-wrap: anywhere;

    cursor: pointer;

    @media screen and (min-width: $viewport-narrow) {
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        font-size: 1rem;
    }

    &:hover,
    &:focus {
        --btn_color_background: #{$fssa-airforce};
        --btn_color_border: #{$fssa-airforce};
        --btn_color_text: #{$white};
    }

    // ELEMENT FOCUS RULES
    // For Accessibility reasons make sure there are styles for
    // `:focus-visible` on any keyboard focusable element.
    // -- `:focus-visible` will be supported by Safari from version 15.3
    // -- Use something big & bold, so those with poor vision or in bright environments
    //    can see it
    // -- It's a feature, not a bug!
    &:not(:disabled):focus-visible {
        @extend %button-focus-visible;
    }

    // Mark icon on left (only if span-tag used to surround button text)
    > :first-child:not(span) {
        margin-left: -0.5em;
        margin-right: 0.25em;
    }

    // Mark icon on right (only if span-tag used to surround button text)
    > :last-child:not(span) {
        margin-left: 0.25em;
        margin-right: -0.5em;
    }

    svg.icon {
        @media screen and (max-width: $viewport-narrow-max) {
            width: 1rem;
        }
    }
}

// Hero unique button-style
.btn-hero {
    --btn_color_background: #{rgba(#001d33, 0.5)};
    --btn_color_border: #{rgba($white, 0.5)};
    --btn_color_text: inherit;
}

.btn-page {
    --btn_color_background: transparent;
    --btn_color_border: transparent;
    --btn_color_text: inherit;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 2.125em;
    padding: 0.25em 0.75em;
    font-size: 1rem;

    &:hover,
    &:focus {
        --btn_color_background: #{$fssa-light-gray};
        --btn_color_border: #{$fssa-bgcolor};
        --btn_color_text: #{$fssa-airforce};
    }
    
    &.active {
        --btn_color_background: #{$fssa-airforce};
        --btn_color_border: #{$fssa-bgcolor};
        --btn_color_text: #{$white};
    }

    &[aria-current] {
        --btn_color_background: #{$fssa-airforce};
        --btn_color_border: #{$fssa-airforce};
        --btn_color_text: #{$white};
    }
}

// Icon-only play triangle
.btn-play {
    --btn_color_border: #{$fssa-textcolor--footer};
    --btn_color_text: #{$fssa-textcolor--footer};

    padding: 0.25em;

    svg.icon {
        margin: auto;
    }
}

.btn-reset {
    --btn_color_background: transparent;
    --btn_color_border: #{$fssa-form-bordercolor};
    padding: 0.5em 1.5em;
    font-weight: 400;

    svg.icon {
        width: 1em;
    }
}

// Icon-only back-to-top arrow
.btn.btn-top {
    width: 2.5em;
    height: 2.5em;
    padding: 0.25em;
    font-size: 1rem;

    svg.icon {
        width: 1.5rem;
        margin: auto;
        transform: rotate(-90deg);
    }
}

// Button 'link'-style
.link {
    display: flex;
}
