/* Strategy Filter component (pagination)
*****************************************/

nav.pagination {
    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    li {
        margin: 0.125em;
        font-weight: 600;
    }

    a:hover,
    a:focus {
        --color_link: #{$fssa-airforce};
    }

    span.page {
        opacity: 0.3;
    }

    .icon.icon--chevron {
        top: 0;
    }

    .icon.previous {
        transform: rotate(180deg);
    }
}
