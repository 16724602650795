/* Text component (Read More variation)
***************************************/

.border-highlight,
.readmore {
    .text {
        blockquote {
            font-size: 1rem;
        }

        > ol,
        > ul {
            padding-left: 1em;
        }
    }
}
