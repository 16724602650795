/* Form common/partial styles (default)
***************************************/

form {
    [aria-live].error > p {
        margin: 0.25em 0;
        padding: 0.25em 1em;
        color: $white;
        background-color: $fssa-red;
        font-size: 0.9rem;
        font-weight: 500;
    }
}

.styled-select {
    position: relative;
    display: inline-block;

    select {
        appearance: none;

        &:focus {
            background-color: $fssa-bgcolor;
        }
    }

    svg {
        position: absolute;
        top: calc(50% - 0.75em);
        right: 1em;
        transform: rotate(90deg);
    }
}
