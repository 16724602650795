/* Global Color Schemes
***********************/
// Used by cards & panels, etc.

.scheme--blue {
    --color_scheme_background: #{$fssa-blue};
    --color_scheme_text: #{$white};
}

.scheme--light-gray {
    --color_scheme_background: #{$fssa-light-gray};
    --color_scheme_text: #{$fssa-textcolor};
}

.scheme--red {
    --color_scheme_background: #{$fssa-red};
    --color_scheme_text: #{$white};

    .btn {
        --btn_color_background: #{$white};
        --btn_color_border: #{$fssa-red};
        --btn_color_text: #{$fssa-red};

        &:hover,
        &:focus {
            --btn_color_background: #{$fssa-airforce};
            --btn_color_border: #{$fssa-airforce};
            --btn_color_text: #{$white};
        }
    }
}

.scheme--translucent {
    // This is more opaque than webqem's prototype - clearer content on busy backgrounds
    --color_scheme_background: rgba(1, 19, 35, 0.68);
    --color_scheme_text: #{$white};

    figcaption {
        --color_scheme_background: transparent;
    }
}

.scheme--white {
    --color_scheme_background: #{$white};
    --color_scheme_text: #{$fssa-textcolor};
}

// Light schemes/dark text
.scheme--light-gray,
.scheme--white {
    --color_heading: #{$fssa-textcolor--blue};

    figcaption {
        --color_scheme_background: #{$fssa-light-gray};
        --color_scheme_text: #{$fssa-textcolor--blue};
    }
}

// Dark schemes/white text
.tmpl-casestudy,
.scheme--blue,
.scheme--red,
.scheme--translucent {
    --color_heading: #{$white};

    .text a {
        @extend %link-plain--inherit;
    }

    .list-icon .icon {
        fill: currentColor;
    }
}
