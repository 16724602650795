/* Header/logos component (Case Study Template)
***********************************************/

.tmpl-casestudy {
    .header-logo {
        position: static;
        margin-bottom: 1em;

        a {
            --color_link: #{$white};
        }
    }

    .header-logo,
    .header-logo .symbol-highlight {
        fill: currentColor;
    }
}
