/* Hero component (Feature insight variation)
*********************************************/

.hero.hero--feature {
    .hero-text {
        .restrict-width {
            @media screen and (min-width: $viewport-wide) {
                max-width: 50%;
            }
        }
    }

    .hero-title {
        h1 {
            @media screen and (min-width: $viewport-medium) {
                font-size: 2.7rem;
            }
        }
    }
}
