/* Image component (default)
****************************/

.image {
    break-inside: avoid;
    overflow: hidden;

    figure,
    img {
        width: 100%;
        margin: 0 auto;
    }

    figure {
        display: table;
    }

    figcaption {
        display: table-caption;
        caption-side: bottom;
    }
}
