/* Call-To-Action component (default)
*************************************/

// .cta-container {}

.cta {
    display: flex;
    flex-flow: row wrap;
    margin: 0.5em -0.5em;
    align-items: center;

    a,
    button {
        margin: 0.5em;
    }

    &.alignment--right {
        justify-content: flex-end;
    }

    &.alignment--spaced {
        justify-content: space-between;
    }

    // Price & Performance component extra CTA/button container
    &.expand-all {
        margin-top: -1.25em;

        @media screen and (min-width: $viewport-wide) {
            margin: 0;
            float: right;
        }
    }
}
