/* Fade component (default)
***************************/

.fade-container {
    --step-depth: #{em(80px, 16px)};

    display: flex;
    flex-direction: column;

    // Remove vertical-flow effect from columns component
    .columns-container.vertical-flow & {
        @media screen and (min-width: $viewport-wide) {
            margin-top: -1.5em;
            margin-bottom: -1.5em;
        }
    }
}

.fade {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1.5em;

    // Ensures pseudo-elements only display at desktop viewport
    @media screen and (min-width: $viewport-wide) {
        &::before,
        &::after {
            display: block;
            width: 100%;
            height: var(--step-depth);
            content: "";
        }
    }

    &::before {
        background-image: linear-gradient(0deg, rgba($fssa-textcolor--blue, 0.3), rgba($fssa-textcolor--blue, 0) 77%);
    }

    &::after {
        flex-grow: 1;
        background-image: linear-gradient(180deg, rgba($fssa-textcolor--blue, 0.3), rgba($fssa-textcolor--blue, 0) 77%);
    }
}

// Set-up step defaults
// Overruled by any component settings
.columns {
    > .fade-container {
        &:nth-child(odd) {
            .fade:not([style])::before {
                --step-depth: #{em(160px, 16px)};
            }
        }
    }
}
