/* Case Study Teaser component (default)
****************************************/
// @note: Case Study Teaser gets most of its styling from the shared
// teaser style class `.promo`, `.promo-ITEM_NAME` rules

.casestudy {
    filter: contrast(100%) brightness(95%);

    &:hover,
    &:focus-within {
        filter: contrast(110%) brightness(105%);
    }
}

// .casestudy-type {}
// .casestudy-image {}
// .casestudy-text {}
// .casestudy-heading {}
// .casestudy-link {}

.casestudy-cta {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.5em;
}
