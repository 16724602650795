/* Columns component (default)
******************************/

.columns {
    --columns_number: 2;
    --columns_gap: 1.5em;

    display: grid;
    gap: 1.5em;

    @media screen and (min-width: $viewport-wide) {
        gap: var(--columns_gap);
        grid-template-columns: repeat(var(--columns_number), 1fr);
    }

    // Adding dividers moves the gap setting to child item padding
    &.dividers {
        gap: 0;
        outline: solid 0.0625em $fssa-bgcolor;
        outline-offset: -0.0625em;
        overflow: hidden;

        > *.column-item {
            margin: 0.03125em;
            padding: 1.5em;
            outline: solid 0.03125em $fssa-bgcolor;

            @media screen and (min-width: $viewport-narrow) {
                padding: 2em;
            }

            @media screen and (min-width: $viewport-wide) {
                padding: var(--columns_gap);
            }
        }
    }
}

// Two-column ratio settings
.columns--2 {
    &.ratio--three-one {
        @media screen and (min-width: $viewport-wide) {
            grid-template-columns: 3fr 1fr;
        }
    }

    &.ratio--one-three {
        @media screen and (min-width: $viewport-wide) {
            grid-template-columns: 1fr 3fr;
        }
    }
}

// Counter Panel padding settings
.panel:not(.panel--zero) {
    .columns {
        &.padding--counter-horizontal {
            margin-left: -1.5em;
            margin-right: -1.5em;

            @media screen and (min-width: $viewport-narrow) {
                margin-left: -2em;
                margin-right: -2em;
            }

            @media screen and (min-width: $viewport-wide) {
                margin-left: -3em;
                margin-right: -3em;
            }
        }

        &.padding--counter-vertical {
            margin-top: -2em;
            margin-bottom: -2em;

            @media screen and (min-width: $viewport-wide) {
                margin-top: -3em;
                margin-bottom: -3em;
            }
        }
    }
}
