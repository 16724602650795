// SUISSE INT'L
// ============
// FSSA/FSI brand font for European/US language sites
// Traditional Chinese & Japanese based sites will use Noto Sans (CKJ?) TC typeface

// TYPEFACE SCSS VARIABLE
$font-family: "suisse-intl", Helvetica, Arial, sans-serif;

@font-face {
    font-family: "suisse-intl";
    /* prettier-ignore */
    src:
        url(../fonts/SuisseIntl/SuisseIntl-Light-WebS.woff2) format('woff2'),
        url(../fonts/SuisseIntl/SuisseIntl-Light-WebS.woff) format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "suisse-intl";
    /* prettier-ignore */
    src:
        url(../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff2) format('woff2'),
        url(../fonts/SuisseIntl/SuisseIntl-Regular-WebS.woff) format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "suisse-intl";
    /* prettier-ignore */
    src:
        url(../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff2) format('woff2'),
        url(../fonts/SuisseIntl/SuisseIntl-Medium-WebS.woff) format('woff');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}
