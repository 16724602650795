/* Title component (fade variation)
***********************************/

[class|="fade"] {
    // @note: Attribute selector used so Title style variant works in publish & author mode
    // Selects direct descendent of both `<div class="fade"/>` & `<div class="fade-editmode"/>`
    // - https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors

    > .title {
        --color_heading: #{$white};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 9.875em; // 158px

        padding: 1.125em;
        background-image: linear-gradient(180deg, #a02b35, #144266);

        @media screen and (min-width: $viewport-narrow) {
            min-height: 11.25em; // 180px
            padding: 2em;
        }

        @media screen and (min-width: $viewport-wide) {
            min-height: 16.25em; // 260px
            padding: 3em;
        }

        .heading {
            margin: 0;

            font-size: 2rem;
            line-height: 1.1;

            @media screen and (min-width: $viewport-medium) {
                font-size: 2.7rem;
            }

            @media screen and (min-width: $viewport-wide) {
                font-size: 2.4rem;
                // Match primary heading size; more consistent than original design
            }
        }
    }
}
