/* Navigation Search sub-component (desktop view)
*************************************************/

.navigation-search.desktop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    margin-bottom: 0;
    padding: 2em 1em;

    background-color: $white;
    background-image: linear-gradient(180deg, $fssa-bgcolor, $white 17%);
    box-shadow: $fssa-box-shadow;

    z-index: 1;

    input[type="search"] {
        padding-right: 6em;
        font-size: 1rem;
        background-color: $white;
        border-radius: 1.375rem;

        &:focus-visible {
            outline: none; // Not a good idea, but business unhappy with any marker here
        }
    }

    .btn-search {
        height: 100%;
        right: 0;
        padding: 0.4em 2em 0.4em 1.5em;
        color: $white;
        background-color: $fssa-airforce;
        border-top-right-radius: 1.375rem;
        border-bottom-right-radius: 1.375rem;
        transition: all 0.2s ease;

        &:hover,
        &:focus {
            background-color: $fssa-blue;
            text-decoration: underline;
        }

        // ELEMENT FOCUS RULES
        // For Accessibility reasons make sure there are styles for
        // `:focus-visible` on any keyboard focusable element.
        // -- `:focus-visible` will be supported by Safari from version 15.3
        // -- Use something big & bold, so those with poor vision or in bright environments
        //    can see it
        // -- It's a feature, not a bug!
        &:focus-visible {
            @extend %button-focus-visible;
        }
    }

    .icon {
        display: none;
    }
}
