/* List component (Footer variant)
**********************************/

.footer {
    .list {
        margin: 0 0 2em;
        padding: 0;
        list-style: none;
        font-size: 0.9rem;

        @media screen and (min-width: $viewport-wide) {
            margin: 0 0 em(40px, 16px);
        }

        li {
            margin: 0.5em 0;
        }

        a {
            --color_link: #{$fssa-textcolor--footer};
            font-weight: inherit;

            &:hover,
            &:focus {
                --color_link: #{$white};
            }
        }
    }

    .columns {
        .list {
            li:first-child {
                margin: 0 0 -0.25em;
                font-size: 1.2rem;
            }
        }
    }
}
