/* Navigation Search sub-component (mobile view)
************************************************/

.navigation-search {
    @extend %slidingPanel;
    grid-area: search;
    margin-bottom: -1em;
    padding: 1em 0;

    input[type="search"] {
        padding-right: 3em;
        font-size: 0.8rem;
        background-color: $fssa-light-gray;
        border-radius: 0.1875rem;
    }

    .btn-search {
        position: absolute;
        right: 1em;
        font-weight: 500;
    }

    .icon {
        width: 1em;
        height: 1em;
        color: $fssa-red;
    }

    .quicksearch-layout {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;
    }
}
