/* Scroll-Sliding Panel component (default)
*******************************************/

// Ordered-list container
.scrollslides {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: count;
    scroll-snap-type: y proximity;

    li + li {
        margin-top: 0.062em;

        @media screen and (min-width: $viewport-wide) {
            margin-top: 0;
            border-top: solid 0.0625em lighten($fssa-blue, 5%);
        }
    }

    .heading--secondary {
        font-size: 1.5rem;
        font-weight: 400;

        @media screen and (min-width: $viewport-wide) {
            font-size: 2.4rem;
            font-weight: 300;
        }
    }
}

// Individual slide components
.scrollslide {
    position: relative;
    display: flex;
    flex-direction: column;
    scroll-snap-align: top;

    // Sticky only occurs on:
    // 1). Desktop viewports, AND;
    // 2). Browser detects no reduced motion request
    // Fallback is a static vertically-stacked presentation - much like mobile/tablet
    @media screen and (min-width: $viewport-wide) and (prefers-reduced-motion: no-preference) {
        position: sticky;
        top: 0;
        min-height: 100vh; // Be sure to turn this off in Author edit mode styling
        overflow: hidden;
    }

    &[tabindex]:focus {
        z-index: 1;
    }

    .slide {
        padding: 1.5em;
        flex-grow: 1;

        color: var(--color_scheme_text);
        background-color: var(--color_scheme_background);

        @media screen and (min-width: $viewport-wide) {
            padding: 3em;
        }
    }

    .slide-inset {
        position: relative;
        display: flex;
        align-items: flex-start;

        max-width: 33em;
        margin: 0 auto;
        counter-increment: count;

        @media screen and (min-width: $viewport-wide) {
            flex-direction: column;
        }

        &::before {
            display: inline-block;
            margin: 0 0.6667em 0.5em 0;
            content: counter(count, decimal-leading-zero);
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.2;
            border-bottom: solid 0.0625rem $fssa-red;
            // ^^ REM unit is deliberate, as font-size changes

            @media screen and (min-width: $viewport-wide) {
                font-size: 2.7rem;
                font-weight: 200;
            }
        }
    }

    .slide-image,
    .slide-image .image {
        position: absolute;
        inset: 0;
    }

    .slide-image {
        &::after {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba($fssa-blue, 0.74);
            // ^^ Less translucent than designs to ensure better legibility of text on top
            content: "";
        }

        .image {
            // Background-image only displays on desktop viewports
            // Image loading done via CSS custom property to ensure image not downloaded
            // unless at desktop viewport width
            @media screen and (min-width: $viewport-wide) {
                background: center center no-repeat;
                background-image: var(--slide_background_image);
                background-size: cover;
                filter: grayscale(100%);
            }
        }
    }
}
