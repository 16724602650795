/* Infogram component (default)
*******************************/

.infogram {
    margin-left: 0;
    margin-right: 0;

    padding: 1.5em;

    // Including default color (second parameter) in case no `.scheme--COLOR` is set
    color: var(--color_scheme_text, $white);
    background-color: var(--color_scheme_background, $fssa-blue);

    @media screen and (min-width: $viewport-medium) {
        padding: 2em;
    }

    &.infogram--nopadding {
        padding: 0;
    }

    figcaption {
        text-align: initial;
        font-size: 0.8rem;
    }

    .heading {
        margin-bottom: 1em;
    }
}
