/* Jump Links Navigation component (default)
********************************************/

.jumplinks-container {
    position: sticky;
    top: 0;
    z-index: 50;
}

.jumplinks {
    position: relative;
    display: grid;
    grid-template-columns: 1fr minmax(2.25em, auto);
    gap: 1em;
    align-items: center;

    padding: 1em 2em;

    background-color: $fssa-light-gray;
    border-bottom: solid em(1px, 16px) $fssa-bgcolor;

    @media screen and (min-width: $viewport-narrow) {
        padding-left: 2em;
        padding-right: 2em;
    }

    @media screen and (min-width: $viewport-wide) {
        padding-left: 3em;
        padding-right: 3em;
    }

    h2,
    p.back-to-top {
        margin: 0;
    }

    h2 {
        margin-right: 0.5em;
        color: $fssa-textcolor--blue;
        font-size: 1rem;
        font-weight: 300;

        &::after {
            content: ":";
        }
    }

    p.back-to-top {
        margin-left: auto;
    }
}

// Desktop list of links
.jumplinks-list {
    display: flex;
    align-items: center;

    ol {
        display: flex;
        overflow-x: auto;
        scrollbar-width: thin; // Official scrollbar property: coming to browsers soon
    }

    li {
        margin: 0.25em;
        flex-shrink: 0;
    }

    a.btn {
        padding: 0.555em 1.111em;
        font-size: 0.9rem;
        border-color: $fssa-bgcolor;
        transition: opacity 0.3s ease;

        svg.icon {
            width: 1.2em;
            transform: rotate(90deg);
        }
    }

    .capture-overflow {
        flex-grow: 1;
        overflow: hidden;
    }
}

// First sibling directly after hero component
.hero-container + .jumplinks-container {
    > .jumplinks {
        @extend %post-hero-margin;
    }
}
