/* Teaser component (block variant)
***********************************/
// Teaser with smaller text, no min-heights, image vertical image gradient overlay
// Design prototype uses them in some sub-navigation panels

.teaser-type--block {
    min-height: 0;
    justify-content: center;

    .teaser-image {
        position: absolute;
        height: auto;
        inset: 0;

        &::after {
            background-image: linear-gradient(45deg, $fssa-bgcolor--teaser, rgba($fssa-bgcolor--teaser, 0));
        }
    }

    .teaser-text {
        position: relative;
        margin-right: 0;
        padding: em(24px, 16px) em(32px, 16px);
        flex-grow: 0;
    }

    .teaser-title {
        align-self: center;
    }

    .teaser-heading {
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.02em;
    }

    .teaser-description {
        font-size: 0.9rem;
        line-height: 1.6;

        p:first-child {
            margin-top: 0.28em;
        }
    }
}
