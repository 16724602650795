/* Footer/logos component (default)
***********************************/

.footer-logos {
    p {
        margin-top: 3rem;
    }

    .logo--fssa {
        width: em(172px, 16px);
        height: em(104px, 16px); // Equivalent to the maximum two-line height
    }

    .logo--pri {
        width: em(172px, 16px);
        height: em(74px, 16px);
    }

    .logo--manan {
        width: em(172px, 16px);
        height: em(104px, 16px);
        margin-left: -34px;
        margin-bottom: 3rem;
    }

    .logo--bcorp {
        height: em(150px, 16px);
        width: em(91px, 16px);
        margin-left: -12px;
    }
}

.footer-logo-manan {
    .logo-subscript {
        display: block;
        color: $white;
        font-size: 0.8125rem;
        letter-spacing: normal;
        margin-bottom: 1.125rem;
        margin-top: 3rem;

        html[lang^="zh"] & {
            font-size: 1.125rem;
        }
    }
}

.footer-logo-pri {
    margin-block-end: 0em;
}

.footer-logo-fssa {
    margin-top: 0 !important;

    .logo-lines {
        display: block;
        height: em(62px, 16px);
        overflow: hidden;
    }

    .logo-lines--two {
        height: em(104px, 16px);
    }

    .logo-subscript {
        display: block;
        margin: 1.125rem 0 3rem;
        color: $white;
        font-size: 0.8125rem;
        letter-spacing: normal;

        // html[lang^="ja"] &, // @todo: reinstate when Japan sites are in Japanese language
        html[lang^="zh"] & {
            font-size: 1.125rem;
            letter-spacing: 0.22em;
        }
    }
}
