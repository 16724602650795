/* Video component (default)
****************************/

.video-container {
    display: flex;
}

.video {
    --video_bg: #{$fssa-light-gray};

    display: flex;
    flex-direction: column;

    width: 100%;
    margin: auto;
    color: $fssa-textcolor--blue;
    background-color: var(--video_bg);

    .video-type {
        position: relative;
        max-width: 100%;
        overflow: hidden;
        z-index: 0;
    }

    // Video in nested panel gets a box-shadow
    .panel .panel & {
        box-shadow: 1px 1px 50px 0 rgba(71, 89, 102, 0.29);
    }

    .scheme--light-gray & {
        --video_bg: #{$white};
    }
}

.video-responsive {
    // Only allow the video box to take space on pages when JavaScript is available
    // Brightcove renders a black box when no JS is available, so 'hide' it
    .js & {
        > .ratio-16x9 {
            padding-top: 56.25%;
        }

        > .ratio-4x3 {
            padding-top: 75%;
        }
    }

    iframe {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }
}
